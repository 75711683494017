import React from "react"
import { FontFormat } from "../../util"
import { CardItem, FlexContainer, TextBoxContainer } from "./styled"
import Skeleton from "@mui/material/Skeleton"

export const SkeletonCard = () => {
  return (
    <FlexContainer $justifyContent={"center"} $isWidthPercen={true}>
      <CardItem>
        <Skeleton
          variant="circular"
          width={"112px"}
          height={"112px"}
          animation="wave"
          sx={{ marginBottom: "12px" }}
        />
        <FlexContainer
          $flexDirection={"column"}
          $alignItems={"center"}
          $gap={"8px"}
        >
          <Skeleton
            variant="text"
            animation="wave"
            sx={{ fontSize: FontFormat.Title_XL.fontSize, width: "180px" }}
          />
          <Skeleton
            variant="text"
            animation="wave"
            sx={{ fontSize: FontFormat.Body_SM.fontSize, width: "180px" }}
          />
          <TextBoxContainer>
            <Skeleton variant="rounded" width={"148px"} height={"37px"} />
            <Skeleton variant="rounded" width={"148px"} height={"37px"} />
          </TextBoxContainer>
        </FlexContainer>
      </CardItem>
    </FlexContainer>
  )
}
