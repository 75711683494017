import React, { useRef, useState, useEffect } from "react"
import { Color, FontFormat, Enum, WindowSize, Validator } from "../../util"
import { Button, Input, Popup, Select } from "../../components"
import {
  PlusIcon,
  XMarkIcon,
  CheckIcon,
  CheckCircleIcon,
  ArrowLeftIcon,
  ChevronDownIcon,
  CloudArrowDownIcon,
  ChevronUpIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  DocumentTextIcon,
} from "../../util/icons/Outline"
import { PlusCircleIcon, SolidXCircleIcon, SolidPencilIcon } from "../../util/icons/Solid"
import { FlexContainer, PopupContainer, H2, P, Span } from "../../style/main"
import {
  Container,
  DetailContainer,
  MenuContainer,
  HeadContainer,
  BodyContainer,
  DateContainer,
  StatusBox,
  ClientImage,
  ReferCaseText,
  Description,
  SeeMoreText,
  PopupButton,
  SupervisorsBox,
  MessageContainer,
  MessageCard,
  MessageImageUser,
  MessageNameUser,
  MessageText,
  LineContainer,
  Line,
  DropFile,
  ApproveCaseContainer,
  TextEditorContainer,
  PreviewImageFile,
  PreviewFilesBox,
  AddButton,
  BackButton,
  PreviewImageInMassage,
  FilesContainer,
  MessageFilesContainer,
  FilesBox,
  PopupShowImageContainer,
  SliderBluezebraContainer,
  SliderContainer,
  PreviewImage,
  OverlayContainer,
} from "./styled"
import {
  getTicketById,
  updateStatusTicket,
  updateStatusTicketAndAddMain,
  getBluezebraUserForSupporters,
  createUserTicket,
  getUserTicketByTicketId,
  getOptionUserTicketByTicketId,
  getBluezebraUser,
  getTicketMessageById,
  createTicketMessage,
  deleteTicketById,
} from "../../api"
import { userInfo } from "../../auth"
import { useParams, useNavigate } from "react-router-dom"
import Avatar from "@mui/material/Avatar"
import Skeleton from "@mui/material/Skeleton"
import CircularProgress from "@mui/material/CircularProgress"
import dayjs from "dayjs"
import ReactQuill from "react-quill"
import Carousel from "react-multi-carousel"
import "react-quill/dist/quill.snow.css"
import "react-multi-carousel/lib/styles.css"
import "./styled.css"

export const TicketsDetail = () => {
  const { ticketId } = useParams()
  const [ticket, setTicket] = useState([])
  const [supervisorsListForAddSupporters, setSupervisorsListForAddSupporters] =
    useState([])
  const [supervisorsListForAddMain, setSupervisorsListForAddMain] = useState([])
  const [supervisorsListForManageMain, setSupervisorsListForManageMain] = useState([])
  const [supervisorMainLabel, setSupervisorMainLabel] = useState([])
  const [supporterId, setSupporterId] = useState([])
  const [supporters, setSupporters] = useState([])
  const [labelSupporters, setLabelSupporters] = useState([])
  const [labelMain, setLabelMain] = useState([])
  const [fetchedUser, setFetchedUser] = useState([])
  const [ticketMessage, setTicketMessage] = useState([])
  const [ticketRole, setTicketRole] = useState(null)
  const [expanded, setExpanded] = useState(false)
  const [currentStep, setCurrentStep] = useState(1)
  const [date, setDate] = useState({
    create: null,
    startDate: null,
    endDate: null,
  })
  const [formatDate, setFormatDate] = useState({
    textEditor: "",
    uploadFiles: [],
  })
  const [previewFiles, setPreviewFiles] = useState([
    {
      name: "",
      type: "",
      url: "",
    },
  ])
  const [isMain, setIsMain] = useState(false)
  const [isShowPopupAddSupervisors, setIsShowPopupAddSupervisors] =
    useState(false)
  const [isShowPopupManageSupervisors, setIsShowPopupManageSupervisors] =
    useState(false)
  const [
    isShowPopupResolvedAndReopenCase,
    setIsShowPopupResolvedAndReopenCase,
  ] = useState(false)
  const [isSave, setIsSave] = useState(false)
  const [isValid, setIsValid] = useState(true)
  const [isHovered, setIsHovered] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingTicket, setLoadingTicket] = useState(false)
  const [loadingTicketMessage, setLoadingTicketMessage] = useState(false)
  const [isUpload, setIsUpload] = useState(false)
  const [isSendLoading, setIsSendLoading] = useState(false)
  const [isShowDetail, setIsShowDetail] = useState(true)
  const [selectedImage, setSelectedImage] = useState(null)
  const [selectedImageIndex, setSelectedImageIndex] = useState(null)
  const [isShowImage, setIsShowImage] = useState(false)
  const navigate = useNavigate()
  const windowSize = WindowSize()
  const fileInputRef = useRef()
  const messagerRef = useRef(null)

  useEffect(() => {
    const fetchTicket = async () => {
      if (isSave) {
        setIsSave(false);
      }

      setLoadingTicket(true);

      try {
        const [ticket, user] = await Promise.all([
          getTicketById(ticketId),
          userInfo()
        ]);

        const [
          supervisorsListForAddSupporters,
          supervisorsListForAddMain,
          supporters,
          fetchSupportersOption,
          ticketMessage
        ] = await Promise.all([
          getBluezebraUserForSupporters(),
          getBluezebraUser(),
          getUserTicketByTicketId(ticketId),
          getOptionUserTicketByTicketId(ticketId),
          getTicketMessageById(ticketId)
        ])

        setTicket(ticket)
        setFetchedUser(user)

        const newSupervisorsListForAddMain = supervisorsListForAddMain.map(item => ({
          value: item.id,
          label: item.name
        }))

        setSupervisorsListForAddSupporters(supervisorsListForAddSupporters)
        setSupervisorsListForAddMain(newSupervisorsListForAddMain)
        setSupervisorsListForManageMain(newSupervisorsListForAddMain)
        setSupporters(supporters);

        const findTicketRoleBybluezebraUserId = supporters.find(
          supporter => supporter.bluezebra_user_id === user.id
        )

        if (findTicketRoleBybluezebraUserId) {
          const { ticket_role: role } = findTicketRoleBybluezebraUserId;
          if (role && role.name === 'Main') {
            setLabelMain({
              value: findTicketRoleBybluezebraUserId.bluezebra_user.id,
              label: findTicketRoleBybluezebraUserId.bluezebra_user.name
            })
            setIsMain(true)
          }
          setTicketRole(role?.name || '')
        }

        if (fetchSupportersOption.main?.length > 0) {
          setLabelMain({
            value: fetchSupportersOption.main[0].bluezebra_user_id,
            label: fetchSupportersOption.main[0].bluezebra_user.name
          })
        }

        const supportersOption = fetchSupportersOption.support.map(item => ({
          id: item.bluezebra_user_id,
          name: item.bluezebra_user.name
        }))
        const supportIds = fetchSupportersOption.support.map((item) => item.bluezebra_user_id)
        const supportObjects = supportIds.map(id => ({ id }));
        setSupporterId(supportObjects)
        setLabelSupporters(supportersOption)
        setTicketMessage(ticketMessage)
      } catch (e) {
        console.error(e)
      } finally {
        setLoadingTicket(false)
        setLoadingTicketMessage(false)
      }
    };

    fetchTicket();
  }, [ticketId, isSave])
  useEffect(() => {
    if (ticket && ticket.created_at && ticket.updated_at) {
      const Created = dayjs(ticket.created_at).format("D MMM YYYY, h.mm A")
      let StartDate = null
      const EndDate = dayjs(ticket.updated_at).format("D MMM YYYY, h.mm A")

      if (Array.isArray(ticket.user_ticket)) {
        const mainUserRole = ticket.user_ticket.find(
          (user) => user.ticket_role && user.ticket_role.name === "Main"
        )
        if (mainUserRole && mainUserRole.created_at) {
          const createdAt = mainUserRole.created_at
          StartDate = dayjs(createdAt).format("D MMM YYYY, h.mm A")
        }
      }

      setDate({
        create: Created,
        startDate: StartDate,
        endDate: EndDate,
      })
    }
  }, [ticket])
  useEffect(() => {
    if (messagerRef.current) {
      messagerRef.current.scrollTop = messagerRef.current.scrollHeight
    }
  }, [ticketMessage])
  useEffect(() => {
    if (isShowImage) {
      document.body.style.overflow = "hidden"
      document.documentElement.style.height = "100vh"
    } else {
      document.body.style.overflow = ""
      document.documentElement.style.height = ""
    }

    return () => {
      document.body.style.overflow = ""
      document.documentElement.style.height = ""
    }
  }, [isShowImage])
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1401 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1400, min: 997 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 996, min: 769 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
    },
  }
  const responsiveBluezebra = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1501 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 1500, min: 1301 },
      items: 2,
    },
    laptop: {
      breakpoint: { max: 1300, min: 1025 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 769 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
    },
  }
  const textColor = (item) => {
    switch (item) {
      case "Pending":
        return "#F4C900"
      case "In Progress":
        return "#007D64"
      case "Supporter":
      case "Main":
        return Color.Primary
      case "Closed":
        return Color.Error
      default:
        return Color.Primary
    }
  }
  const background = (item) => {
    switch (item) {
      case "Pending":
        return "#FEFBE4"
      case "In Progress":
        return "#E0F3F0"
      case "Supporter":
        return Color.Secondary
      case "Main":
        return Color.Baby_Blue
      case "Closed":
        return "#F9E5EB"
      default:
        return Color.Secondary
    }
  }
  const toolbarOptions = {
    toolbar: [
      [
        "bold",
        "italic",
        "underline",
        { align: [] },
        { list: "ordered" },
        { list: "bullet" },
        { list: "check" },
        { color: [] },
        { background: [] },
      ],
    ],
    clipboard: {
      matchVisual: false,
    },
  }
  const onTextEditorChange = (value) => {
    setFormatDate((prev) => {
      return {
        ...prev,
        textEditor: value,
      }
    })
  }
  const toggleExpanded = () => {
    setExpanded(!expanded)
  }
  const updateStatus = (status) => {
    const body = {
      status: status,
      bluezebraId: fetchedUser.id
    }
    try {
      updateStatusTicket(ticketId, body)
        .then(() => setIsSave(true))
        .catch((e) => console.log(e))
    } catch (e) {
      console.log(e)
    }
  }
  const updateAddMain = () => {
    let validate = true

    if (supervisorMainLabel.length === 0) {
      validate = false
    }
    setIsValid(validate)
    if (validate) {
      setCurrentStep((prevStep) => Math.min(prevStep + 1, 3))
    }
  }
  const updateStatusAndAddMain = (status, userId) => {
    const body = {
      status: status,
      userId: userId,
      fetchedUserId: fetchedUser.id,
      role: Enum.SELECT_ROLE_TICKET.MAIN,
    }
    try {
      updateStatusTicketAndAddMain(ticketId, body)
        .then(() => setIsSave(true))
        .catch((e) => console.log(e))
    } catch (e) {
      console.log(e)
    }
  }
  const rejectTicket = (ticketId) => {
    try {
      deleteTicketById(ticketId)
      .then(() => {
        setIsSave(true)
        navigate('/')
      })
      .catch((e) => console.log(e))
    } catch (e) {
      console.log(e)
    }
  }
  const onSelcteSupervisorsSupportersChange = (id) => {
    setSupporterId(id)
  }
  const onSelectSupervisorsMainChange = (value) => {
    const status = supervisorsListForAddMain.find(
      (option) => option.value === value
    )
    setSupervisorMainLabel(status)
  }
  const onSelectSupervisorsManageMainChange = (value) => {
    const status = supervisorsListForAddMain.find(
      (option) => option.value === value
    )
    setLabelMain(status)
  }
  const onClearForm = () => {
    setIsValid(true)
    setIsSendLoading(false)
    setFormatDate({
      textEditor: "",
      uploadFiles: [],
    })
    setPreviewFiles([
      {
        name: "",
        type: "",
        url: "",
      },
    ])
  }
  const onSaveSupporters = async () => {
    let validate = true
    if (supporterId.length === 0 || !labelMain.value) {
      validate = false
    }

    if (!validate) {
      return
    }
    const data = {
      ticketId: ticketId,
      mainId: labelMain.value,
      supporterId: supporterId.map((item) => item.id),
    }

    try {
      if (validate) {
        setLoading(true)
        await createUserTicket(data)
        setIsSave(true)
      }
    } catch (e) {
      console.error(e)
      setLoading(false)
    }
    finally {
      setLoading(false)
      setIsShowPopupAddSupervisors(false)
      setIsShowPopupManageSupervisors(false)
    }
  }
  const onSendMessage = () => {
    let validate = true
    const plainText = formatDate.textEditor.replace(/<[^>]+>/g, "").trim()

    if (plainText === "" && formatDate.uploadFiles.length === 0) {
      validate = false
    }
    setIsValid(validate)
    const data = {
      ticketId: ticketId,
      userId: fetchedUser.id,
      message: formatDate.textEditor,
      uploadFile: formatDate.uploadFiles,
      uploadFileName: formatDate.uploadFiles.map((file) => file.name),
      subject: ticket.title,
      status: ticket.status,
      caseId: ticket.ticket_number,
    }
    if (validate) {
      setIsSendLoading(true)
      createTicketMessage(data)
        .catch((e) => {
          console.log(e)
          setIsSendLoading(false)
        })
        .then(() => {
          setIsSendLoading(false)
          setIsSave(true)
          onClearForm()
        })
    }
  }
  const PopupApproveCaseForHeadOfEngineer = () => {
    return (
      <FlexContainer $justifyContent={"center"}>
        {currentStep === 1 ? (
          <ApproveCaseContainer>
            <FlexContainer $alignItems={"center"} $gap={"14px"}>
              <P $FontFormat={FontFormat.Title_MD}>Status:</P>
              <StatusBox
                $textColor={textColor(ticket && ticket.status)}
                $background={background(ticket && ticket.status)}
              >
                {ticket && ticket.status}
              </StatusBox>
            </FlexContainer>
            <FlexContainer
              $flexDirection={windowSize.width <= 1200 && "column"}
              $alignItems={"center"}
              $gap={windowSize.width <= 1200 ? "5px" : "32px"}
            >
              <P $FontFormat={FontFormat.Title_MD} $textAlign={"center"}>
                Assign case to main supervisor:
              </P>
              <div
                style={{ width: windowSize.width <= 1200 ? "100%" : "400px" }}
              >
                <Select
                  onSelectChange={onSelectSupervisorsMainChange}
                  optionsData={supervisorsListForAddMain}
                  value={supervisorMainLabel.label}
                  height={"auto !important"}
                  width={windowSize.width <= 1200 ? "100%" : "400px"}
                  label={"Bluezebra"}
                  isValid={isValid}
                />
                {!isValid && supervisorMainLabel.length === 0 ? (
                  <p style={{ ...Validator.errorStyle, top: 0 }}>
                    Please complete this field.
                  </p>
                ) : null}
              </div>
            </FlexContainer>
            <FlexContainer $gap={"24px"}>
              <Button
                color={Color.Primary}
                background={Color.Secondary}
                isBorder={true}
                borderColor={Color.Primary}
                label={
                  <>
                    <XMarkIcon width={20} height={20} strokeWidth={2} />
                    Reject
                  </>
                }
                width={"auto"}
                onClick={() => rejectTicket(ticketId)}
              />
              <Button
                color={Color.Secondary}
                background={Color.Primary}
                isBorder={true}
                borderColor={Color.Primary}
                label={
                  <>
                    <CheckIcon width={20} height={20} strokeWidth={2} />
                    Confirm
                  </>
                }
                width={"auto"}
                onClick={() => updateAddMain()}
              />
            </FlexContainer>
          </ApproveCaseContainer>
        ) : currentStep === 2 ? (
          <ApproveCaseContainer>
            <CheckCircleIcon
              width={"64px"}
              height={"64px"}
              color={Color.Success}
            />
            <FlexContainer
              $flexDirection={"column"}
              $alignItems={"center"}
              $gap={"32px"}
            >
              <FlexContainer $alignItems={"center"} $gap={"14px"}>
                <P $FontFormat={FontFormat.Title_MD}>Status is changed to</P>
                <StatusBox
                  $textColor={textColor(Enum.SELECT_TICKET_STATUS.IN_PROGRESS)}
                  $background={background(
                    Enum.SELECT_TICKET_STATUS.IN_PROGRESS
                  )}
                >
                  {Enum.SELECT_TICKET_STATUS.IN_PROGRESS}
                </StatusBox>
              </FlexContainer>
              <div>
                <P $FontFormat={FontFormat.Title_MD} $textAlign={"center"}>
                  Now{" "}
                  <Span
                    $FontFormat={FontFormat.Title_MD}
                    $padding={"4px 12px"}
                    style={{
                      backgroundColor: "#FFF",
                      border: "1px solid #0069AE",
                      borderRadius: "8px",
                    }}
                  >
                    {supervisorMainLabel && supervisorMainLabel.label}
                  </Span>{" "}
                  can chat with your client
                </P>
              </div>
              <Button
                color={Color.Secondary}
                background={Color.Primary}
                isBorder={true}
                borderColor={Color.Primary}
                label={"Close"}
                onClick={() => {
                  updateStatusAndAddMain(
                    Enum.SELECT_TICKET_STATUS.IN_PROGRESS,
                    supervisorMainLabel.value
                  )
                  setCurrentStep((prevStep) => Math.min(prevStep + 1, 3))
                }}
              />
            </FlexContainer>
          </ApproveCaseContainer>
        ) : null}
      </FlexContainer>
    )
  }
  const PopupApproveCaseForEngineer = () => {
    return (
      <>
        {currentStep === 1 ? (
          <ApproveCaseContainer>
            <FlexContainer $alignItems={"center"} $gap={"14px"}>
              <P $FontFormat={FontFormat.Title_MD}>Status:</P>
              <StatusBox
                $textColor={textColor(ticket && ticket.status)}
                $background={background(ticket && ticket.status)}
              >
                {ticket && ticket.status}
              </StatusBox>
            </FlexContainer>
            <div>
              <P $FontFormat={FontFormat.Title_MD} $textAlign={"center"}>
                Do you want to approve this case?
                <br />
                *If you confirmed the case, the chat will be appeared on this
                screen
              </P>
            </div>
            <FlexContainer $gap={"24px"}>
              <Button
                color={Color.Secondary}
                background={Color.Primary}
                isBorder={true}
                borderColor={Color.Primary}
                label={
                  <>
                    <CheckIcon width={20} height={20} strokeWidth={2} />
                    Confirm
                  </>
                }
                onClick={() =>
                  setCurrentStep((prevStep) => Math.min(prevStep + 1, 3))
                }
              />
            </FlexContainer>
          </ApproveCaseContainer>
        ) : currentStep === 2 ? (
          <ApproveCaseContainer>
            <CheckCircleIcon
              width={"64px"}
              height={"64px"}
              color={Color.Success}
            />
            <FlexContainer
              $flexDirection={"column"}
              $alignItems={"center"}
              $gap={"32px"}
            >
              <FlexContainer $alignItems={"center"} $gap={"14px"}>
                <P $FontFormat={FontFormat.Title_MD}>Status is changed to</P>
                <StatusBox
                  $textColor={textColor(Enum.SELECT_TICKET_STATUS.IN_PROGRESS)}
                  $background={background(
                    Enum.SELECT_TICKET_STATUS.IN_PROGRESS
                  )}
                >
                  {Enum.SELECT_TICKET_STATUS.IN_PROGRESS}
                </StatusBox>
              </FlexContainer>
              <div>
                <P $FontFormat={FontFormat.Title_MD} $textAlign={"center"}>
                  Now you can chat with your client
                </P>
              </div>
              <Button
                color={Color.Secondary}
                background={Color.Primary}
                isBorder={true}
                borderColor={Color.Primary}
                label={"Close"}
                onClick={() => {
                  updateStatusAndAddMain(
                    Enum.SELECT_TICKET_STATUS.IN_PROGRESS,
                    fetchedUser.id
                  )
                  setCurrentStep((prevStep) => Math.min(prevStep + 1, 3))
                }}
              />
            </FlexContainer>
          </ApproveCaseContainer>
        ) : null}
      </>
    )
  }
  const PopupAddSupervisors = () => {
    return (
      <Popup
        title={"Manage Bluezebra’s support"}
        isButton={true}
        isLoading={loading}
        saveLabel={"Submit"}
        onClose={() => {
          setIsShowPopupAddSupervisors(false)
          setIsValid(true)
        }}
        onSave={onSaveSupporters}
      >
        <FlexContainer $flexDirection={"column"} $gap={"100px"}>
          <FlexContainer $flexDirection={"column"} $gap={"20px"}>
            <Input
              type="text"
              label={"Main supervisor"}
              value={labelMain.label}
              disabled={true}
            />
            <Select
              onSelectChange={onSelcteSupervisorsSupportersChange}
              optionsData={supervisorsListForAddSupporters}
              value={labelSupporters}
              width={"100%"}
              height={"auto !important"}
              label={"Supporters"}
              isTag={true}
              multiple={true}
              isValid={isValid}
            />
            {!isValid ? (
              <p style={{ ...Validator.errorStyle, top: 0 }}>
                Please complete this field.
              </p>
            ) : null}
          </FlexContainer>
        </FlexContainer>
      </Popup>
    )
  }
  const PopupManageSupervisors = () => {
    return (
      <Popup
        title={"Manage Bluezebra’s support"}
        isButton={true}
        isLoading={loading}
        saveLabel={"Submit"}
        onClose={() => {
          setIsShowPopupManageSupervisors(false)
          setIsValid(true)
        }}
        onSave={onSaveSupporters}
      >
        <FlexContainer $flexDirection={"column"} $gap={"100px"}>
          <FlexContainer $flexDirection={"column"} $gap={"20px"}>
            <Select
              onSelectChange={onSelectSupervisorsManageMainChange}
              optionsData={supervisorsListForManageMain}
              value={labelMain.label}
              width={"100%"}
              height={"auto !important"}
              label={"Main supervisor"}
              isValid={isValid}
            />
            <Select
              onSelectChange={onSelcteSupervisorsSupportersChange}
              optionsData={supervisorsListForAddSupporters}
              value={labelSupporters}
              width={"100%"}
              height={"auto !important"}
              label={"Supporters"}
              isTag={true}
              multiple={true}
              isValid={isValid}
            />
            {!isValid ? (
              <p style={{ ...Validator.errorStyle, top: 0 }}>
                Please complete this field.
              </p>
            ) : null}
          </FlexContainer>
        </FlexContainer>
      </Popup>
    )
  }
  const PopupResolvedAndReopenCase = () => {
    let newStatus
    if (ticket) {
      if (ticket.status === Enum.SELECT_TICKET_STATUS.IN_PROGRESS) {
        newStatus = Enum.SELECT_TICKET_STATUS.CLOSED
      } else if (
        ticket.status === Enum.SELECT_TICKET_STATUS.CLOSED &&
        ticket.user_ticket.length === 0
      ) {
        newStatus = Enum.SELECT_TICKET_STATUS.PENDING
      } else if (ticket.status === Enum.SELECT_TICKET_STATUS.CLOSED) {
        newStatus = Enum.SELECT_TICKET_STATUS.IN_PROGRESS
      } else {
        newStatus = null
      }
    }
    return (
      <>
        <OverlayContainer $top={0} $left={0} $isBlur={true} />
        <PopupContainer>
          <H2
            $FontFormat={FontFormat.Title_SM}
            $padding={"48px 40px 40px 40px"}
          >
            Do you want to “
            {ticket && ticket.status === Enum.SELECT_TICKET_STATUS.IN_PROGRESS
              ? "Resolved"
              : ticket && ticket.status === Enum.SELECT_TICKET_STATUS.CLOSED
                ? "Reopen"
                : null}
            ” the case?
          </H2>
          <FlexContainer $justifyContent={"center"}>
            <PopupButton
              $lableColor={Color.Primary}
              $borderRadius={"0px 0px 0px 10px"}
              $backgroundColor={Color.Secondary_Hover}
              $backgroundColorHover={Color.Secondary}
              onClick={() => setIsShowPopupResolvedAndReopenCase(false)}
            >
              Cancel
            </PopupButton>
            <PopupButton
              $lableColor={Color.Secondary}
              $borderRadius={"0px 0px 10px 0px"}
              $backgroundColor={Color.Primary}
              $backgroundColorHover={Color.Primary_Hover}
              onClick={() => {
                updateStatus(newStatus)
                setIsShowPopupResolvedAndReopenCase(false)
              }}
            >
              Confirm
            </PopupButton>
          </FlexContainer>
        </PopupContainer>
      </>
    )
  }
  const PopupShowImage = (images, index) => {
    const isFirstImage = index === 0
    const isLastImage = index === images.length - 1
    return (
      <>
        <OverlayContainer />
        <PopupShowImageContainer>
          <FlexContainer
            $justifyContent={"flex-end"}
            $gap={"10px"}
            $padding={"16px"}
          >
            <a href={images[index].file} download={images[index].file_name}>
              <CloudArrowDownIcon
                width={"40px"}
                height={"40px"}
                color={Color.Secondary}
                style={{ cursor: "pointer" }}
              />
            </a>
            <SolidXCircleIcon
              width={"40px"}
              height={"40px"}
              onClick={() => setIsShowImage(false)}
              color={Color.Secondary}
              style={{ cursor: "pointer" }}
            />
          </FlexContainer>
          <FlexContainer
            $flexDirection={"column"}
            $justifyContent={"center"}
            $alignItems={"center"}
          >
            <FlexContainer $justifyContent={"center"} $alignItems={"center"}>
              {!isFirstImage && (
                <ChevronLeftIcon
                  width={windowSize.width <= 600 ? "16px" : "36px"}
                  height={windowSize.width <= 600 ? "16px" : "36px"}
                  strokeWidth={"2"}
                  onClick={handlePrevious}
                  color={Color.Secondary}
                  style={{ cursor: "pointer" }}
                />
              )}
              <PreviewImage
                key={index}
                src={images[index].file}
                alt={images[index].file_name}
                href={images[index].file}
                download={images[index].file_name}
              />
              {!isLastImage && (
                <ChevronRightIcon
                  width={windowSize.width <= 600 ? "16px" : "36px"}
                  height={windowSize.width <= 600 ? "16px" : "36px"}
                  strokeWidth={"2"}
                  onClick={handleNext}
                  color={Color.Secondary}
                  style={{ cursor: "pointer" }}
                />
              )}
            </FlexContainer>
            <P
              $FontFormat={FontFormat.Title_MD}
              $textColor={Color.Secondary}
              $lineHeight={"3"}
              style={{ letterSpacing: "3px" }}
            >
              {index + 1}/{images.length}
            </P>
          </FlexContainer>
        </PopupShowImageContainer>
      </>
    )
  }
  const onClickUploadFile = () => {
    fileInputRef.current.click()
  }
  const handleUploadFile = (e) => {
    const files = e.target.files
    let selectedFiles = []
    let imageFiles = []
    let readers = []

    try {
      setIsUpload(true)
      for (let i = 0; i < files.length && i < 10; i++) {
        const file = files[i]
        formatDate.uploadFiles.push(file)
        selectedFiles.push(file)
        const reader = new FileReader()
        imageFiles.push(reader)
        readers.push(
          new Promise((resolve, reject) => {
            reader.onload = () => {
              resolve(reader.result)
            }
            reader.onerror = () => {
              reader.abort()
              reject(new DOMException("Problem parsing input file."))
            }
          })
        )
        reader.readAsDataURL(file)
      }

      Promise.all(readers).then((results) => {
        setPreviewFiles((prevFiles) => {
          const filteredPrevFiles = prevFiles.filter(
            (file) => file.name !== "" || file.url !== ""
          )
          const newFiles = selectedFiles.map((file, index) => ({
            name: file.name,
            type: file.type,
            url: results[index] ? results[index] : "",
          }))
          return [...filteredPrevFiles, ...newFiles]
        })
        setIsUpload(false)
      })
    } catch (error) {
      console.error("Error handling file upload:", error)
    }
  }
  const handleDrop = (e) => {
    e.preventDefault()
    const files = e.dataTransfer.files
    handleUploadFile({ target: { files } })
  }
  const handleDragOver = (e) => {
    e.preventDefault()
  }
  const handleDeleteFile = (index) => {
    setFormatDate((prevState) => ({
      ...prevState,
      uploadFiles: prevState.uploadFiles?.filter((file, i) => i !== index),
    }))
    setPreviewFiles((prevFiles) => prevFiles.filter((file, i) => i !== index))
  }
  const handleShowImageClick = (item, index) => {
    setSelectedImage(item)
    setSelectedImageIndex(index)
    setIsShowImage(true)
  }
  const handleNext = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex + 1) % selectedImage.length)
  }
  const handlePrevious = () => {
    setSelectedImageIndex(
      (prevIndex) =>
        (prevIndex - 1 + selectedImage.length) % selectedImage.length
    )
  }
  const renderHeadContainerForDesktop = () => {
    return (
      <>
        <DetailContainer>
          <FlexContainer
            $alignItems={"center"}
            $gap={"16px"}
            $width={"100%"}
            style={{ paddingBottom: "8px" }}
          >
            <H2
              $FontFormat={FontFormat.Title_XL}
              $whiteSpace={"nowrap"}
              $overFlow={"hidden"}
              $textOverflow={"ellipsis"}
              $width={"auto"}
            >
              Subject: {ticket && ticket.title}
            </H2>
            <StatusBox
              $textColor={textColor(ticket && ticket.status)}
              $background={background(ticket && ticket.status)}
              $width={
                ticket &&
                ticket.status === Enum.SELECT_TICKET_STATUS.IN_PROGRESS &&
                "108px"
              }
            >
              {ticket && ticket.status}
            </StatusBox>
          </FlexContainer>
          <FlexContainer
            $gap={"5px"}
            $width={"100%"}
            style={{ paddingBottom: "8px" }}
          >
            <div style={{ width: "100%" }}>
              <Description $maxHeight={expanded ? "none" : "3em"}>
                <Span $FontFormat={FontFormat.Title_MD}>Description:</Span>{" "}
                {ticket && ticket.detail}
              </Description>
              {ticket?.detail?.length > 300 && (
                <SeeMoreText onClick={toggleExpanded}>
                  {expanded ? "See Less" : "See More"}
                </SeeMoreText>
              )}
            </div>
          </FlexContainer>
          <FlexContainer
            $alignItems={"center"}
            $gap={"16px"}
            style={{ paddingBottom: "8px" }}
          >
            <P $FontFormat={FontFormat.Title_MD}>
              Product:{" "}
              <Span $FontFormat={FontFormat.Body_MD}>
                {ticket && ticket.product?.name}
              </Span>
            </P>
            <P $FontFormat={FontFormat.Body_MD}> | </P>
            <FlexContainer $alignItems={"center"} $gap={"8px"}>
              <P $FontFormat={FontFormat.Title_MD}>Client: </P>
              {!ticket.client_user?.logo_image ? (
                <Avatar
                  src={ticket && ticket.client_user?.logo_image}
                  alt={ticket && ticket.client_user?.name}
                  style={{ width: "36px", height: "36px" }}
                />
              ) : (
                <ClientImage
                  src={ticket && ticket.client_user?.logo_image}
                  alt={ticket && ticket.client_user?.name}
                />
              )}
              <Span $FontFormat={FontFormat.Body_MD}>
                {ticket && ticket.client_user?.name}
              </Span>
            </FlexContainer>
          </FlexContainer>
          <FlexContainer $alignItems={"center"} $gap={"8px"}>
            <P
              $FontFormat={FontFormat.Title_MD}
              style={{ paddingBottom: "8px" }}
            >
              Refer case:{" "}
              {(!ticket && !ticket.ticket_reference) ||
                (ticket.ticket_reference?.length === 0) ? (
                <Span $FontFormat={FontFormat.Body_MD}>-</Span>
              ) : (
                <ReferCaseText
                  to={`/tickets/detail/${ticket &&
                    ticket.ticket_reference &&
                    ticket.ticket_reference.id
                    }`}
                >
                  {ticket && ticket.ticket_reference?.title}
                </ReferCaseText>
              )}
            </P>
          </FlexContainer>
          <FlexContainer
            $alignItems={"center"}
            $gap={"16px"}
            style={{ paddingBottom: "8px" }}
          >
            <P $FontFormat={FontFormat.Title_MD}>Bluezebra:</P>
            {ticket && ticket.user_ticket && ticket.user_ticket.length !== 0 ? (
              <>
                <SliderBluezebraContainer
                  $userTicket={ticket.user_ticket.length}
                >
                  <Carousel
                    className="supporter ticket-message-file"
                    arrows={true}
                    responsive={responsiveBluezebra}
                  >
                    {supporters &&
                      supporters.length > 0 &&
                      supporters.map((item, index) => (
                        <SupervisorsBox key={index}>
                          <ClientImage
                            key={index}
                            src={item.bluezebra_user.profile_image}
                            alt={item.bluezebra_user.name}
                          />
                          <Span
                            $FontFormat={FontFormat.Body_MD}
                            $whiteSpace={"nowrap"}
                            $overFlow={"hidden"}
                            $textOverflow={"ellipsis"}
                            $maxWidth={"100px"}
                          >
                            {item.bluezebra_user.name}
                          </Span>
                          <StatusBox
                            $textColor={textColor(item.ticket_role.name)}
                            $background={background(item.ticket_role.name)}
                          >
                            {item.ticket_role.name}
                          </StatusBox>
                        </SupervisorsBox>
                      ))}
                  </Carousel>
                </SliderBluezebraContainer>
                {fetchedUser.role === Enum.SELECT_ROLE.HEADOF_ENGINEER &&
                  ticket.status !== Enum.SELECT_TICKET_STATUS.CLOSED && (
                    <Button
                      background={"unset"}
                      padding={"unset"}
                      label={
                        <>
                          <SolidPencilIcon width={24} height={24} />
                          <Span $FontFormat={FontFormat.Body_SM}>Manage</Span>
                        </>
                      }
                      onClick={() => setIsShowPopupManageSupervisors(true)}
                    />
                  )}
                {isMain &&
                  fetchedUser.role === Enum.SELECT_ROLE.ENGINEER &&
                  ticket.status !== Enum.SELECT_TICKET_STATUS.CLOSED && (
                    <Button
                      background={"unset"}
                      padding={"unset"}
                      label={
                        <>
                          <PlusCircleIcon width={24} height={24} />
                          <Span $FontFormat={FontFormat.Body_SM}>Add</Span>
                        </>
                      }
                      onClick={() => setIsShowPopupAddSupervisors(true)}
                    />
                  )}
              </>
            ) : (
              <Span $FontFormat={FontFormat.Body_MD}>-</Span>
            )}
          </FlexContainer>
          <FlexContainer
            $alignItems={"center"}
            $gap={"16px"}
            style={{ paddingBottom: "8px" }}
          >
            <P $FontFormat={FontFormat.Title_MD} $whiteSpace={"nowrap"}>
              Attached files:
            </P>
            {ticket && ticket.ticket_file && ticket.ticket_file.length > 0 ? (
              <SliderContainer>
                <Carousel
                  className="ticket-message-file"
                  responsive={responsive}
                >
                  {ticket &&
                    ticket.ticket_file.length > 0 &&
                    ticket.ticket_file.map((item, index) => (
                      <FilesContainer key={index}>
                        <FilesBox href={item.file} download={item.file_name}>
                          {item.file_name}
                        </FilesBox>
                      </FilesContainer>
                    ))}
                </Carousel>
              </SliderContainer>
            ) : (
              <Span $FontFormat={FontFormat.Body_MD}>-</Span>
            )}
          </FlexContainer>
        </DetailContainer>
        <DateContainer $backgroundColor={background(ticket && ticket.status)}>
          <P $FontFormat={FontFormat.Body_MD} $whiteSpace={"nowrap"}>
            Created: {date.create}
          </P>
          <P $FontFormat={FontFormat.Body_MD} $whiteSpace={"nowrap"}>
            Start date:{" "}
            {(ticket &&
              ticket.status === Enum.SELECT_TICKET_STATUS.IN_PROGRESS) ||
              (ticket && ticket.status === Enum.SELECT_TICKET_STATUS.CLOSED)
              ? date.startDate
              : "-"}
          </P>
          <P $FontFormat={FontFormat.Body_MD} $whiteSpace={"nowrap"}>
            End date:{" "}
            {ticket && ticket.status === Enum.SELECT_TICKET_STATUS.CLOSED
              ? date.endDate
              : "-"}
          </P>
          {ticket && ticket.status === Enum.SELECT_TICKET_STATUS.IN_PROGRESS ? (
            <Button
              color={Color.Secondary}
              background={Color.Primary}
              label={"Resolved"}
              onClick={() => setIsShowPopupResolvedAndReopenCase(true)}
              style={{
                margin: windowSize.width <= 1024 && "auto",
                marginTop: "auto",
              }}
            />
          ) : ticket && ticket.status === Enum.SELECT_TICKET_STATUS.CLOSED ? (
            <Button
              color={Color.Secondary}
              background={Color.Primary}
              label={"Reopen"}
              onClick={() => setIsShowPopupResolvedAndReopenCase(true)}
              style={{
                margin: windowSize.width <= 1024 && "auto",
                marginTop: "auto",
              }}
            />
          ) : null}
        </DateContainer>
      </>
    )
  }
  const renderHeadContainerForMobile = () => {
    return (
      <>
        <div style={{ width: "100%" }}>
          <FlexContainer $gap={"4px"}>
            <StatusBox
              $textColor={textColor(ticket && ticket.status)}
              $background={background(ticket && ticket.status)}
            >
              {ticket && ticket.status}
            </StatusBox>
            {ticketRole && (
              <StatusBox
                $textColor={textColor(ticketRole)}
                $background={background(ticketRole)}
              >
                {ticketRole}
              </StatusBox>
            )}
          </FlexContainer>
          <FlexContainer
            $flexDirection={"column"}
            $gap={"16px"}
            $width={"100%"}
            style={{ paddingBottom: "4px" }}
          >
            <H2
              $FontFormat={FontFormat.Title_XL}
              $whiteSpace={"nowrap"}
              $overFlow={"hidden"}
              $textOverflow={"ellipsis"}
              $width={"auto"}
            >
              Subject: {ticket && ticket.title}
            </H2>
            <div style={{ width: "100%" }}>
              <Description $maxHeight={expanded ? "none" : "3em"}>
                <Span $FontFormat={FontFormat.Title_MD}>Description:</Span>{" "}
                {ticket && ticket.detail}
              </Description>
              {ticket?.detail?.length > 300 && (
                <SeeMoreText onClick={toggleExpanded}>
                  {expanded ? "See Less" : "See More"}
                </SeeMoreText>
              )}
            </div>
          </FlexContainer>
          {!isShowDetail && (
            <>
              <P
                $FontFormat={FontFormat.Title_MD}
                style={{ paddingBottom: "4px" }}
              >
                Product:{" "}
                <Span $FontFormat={FontFormat.Body_MD}>
                  {ticket && ticket.product?.name}
                </Span>
              </P>
              <FlexContainer
                $alignItems={"center"}
                $gap={"8px"}
                style={{ paddingBottom: "4px" }}
              >
                <P $FontFormat={FontFormat.Title_MD}>Client: </P>
                {!ticket.client_user?.logo_image ? (
                  <Avatar
                    src={ticket && ticket.client_user?.logo_image}
                    alt={ticket && ticket.client_user?.name}
                    style={{ width: "36px", height: "36px" }}
                  />
                ) : (
                  <ClientImage
                    src={ticket && ticket.client_user?.logo_image}
                    alt={ticket && ticket.client_user?.name}
                  />
                )}
                <Span $FontFormat={FontFormat.Body_MD}>
                  {ticket && ticket.client_user?.name}
                </Span>
              </FlexContainer>
              <P
                $FontFormat={FontFormat.Title_MD}
                style={{ paddingBottom: "8px" }}
              >
                Refer case:{" "}
                {(!ticket && !ticket.ticket_reference) ||
                  (ticket.ticket_reference?.length === 0) ? (
                  <Span $FontFormat={FontFormat.Body_MD}>-</Span>
                ) : (
                  <ReferCaseText
                    to={`/tickets/detail/${ticket &&
                      ticket.ticket_reference &&
                      ticket.ticket_reference.id
                      }`}
                  >
                    {ticket && ticket.ticket_reference?.title}
                  </ReferCaseText>
                )}
              </P>
              <FlexContainer
                $alignItems={"center"}
                $gap={"16px"}
                $width={"100%"}
                style={{ paddingBottom: "4px" }}
              >
                <P $FontFormat={FontFormat.Title_MD}>Bluezebra:</P>
                {ticket &&
                  ticket.user_ticket &&
                  ticket.user_ticket.length !== 0 ? (
                  <SliderBluezebraContainer>
                    <Carousel
                      className="supporter ticket-message-file"
                      responsive={responsiveBluezebra}
                    >
                      {supporters &&
                        supporters.length > 0 &&
                        supporters.map((item, index) => (
                          <SupervisorsBox key={index}>
                            <ClientImage
                              key={index}
                              src={item.bluezebra_user.profile_image}
                              alt={item.bluezebra_user.name}
                            />
                            <Span
                              $FontFormat={FontFormat.Body_MD}
                              $whiteSpace={"nowrap"}
                              $overFlow={"hidden"}
                              $textOverflow={"ellipsis"}
                              $maxWidth={"100px"}
                            >
                              {item.bluezebra_user.name}
                            </Span>
                            <StatusBox
                              $textColor={textColor(item.ticket_role.name)}
                              $background={background(item.ticket_role.name)}
                            >
                              {item.ticket_role.name}
                            </StatusBox>
                          </SupervisorsBox>
                        ))}
                    </Carousel>
                  </SliderBluezebraContainer>
                ) : (
                  <Span $FontFormat={FontFormat.Body_MD}>-</Span>
                )}
              </FlexContainer>
              <FlexContainer
                $alignItems={"center"}
                $gap={"16px"}
                style={{ paddingBottom: "8px" }}
              >
                <P $FontFormat={FontFormat.Title_MD} $whiteSpace={"nowrap"}>
                  Attached files:
                </P>
                {ticket &&
                  ticket.ticket_file &&
                  ticket.ticket_file.length === 0 && (
                    <Span $FontFormat={FontFormat.Body_MD}>-</Span>
                  )}
                <SliderContainer>
                  <Carousel
                    className="ticket-message-file"
                    responsive={responsive}
                  >
                    {ticket &&
                      ticket.ticket_file &&
                      ticket.ticket_file.map((item, index) => (
                        <FilesContainer key={index}>
                          <FilesBox href={item.file} download={item.file_name}>
                            {item && item.file_name}
                          </FilesBox>
                        </FilesContainer>
                      ))}
                  </Carousel>
                </SliderContainer>
              </FlexContainer>
              <DateContainer
                $backgroundColor={background(ticket && ticket.status)}
              >
                <P $FontFormat={FontFormat.Body_SM}>Created: {date.create}</P>
                <P $FontFormat={FontFormat.Body_SM}>
                  Start date:
                  {(ticket &&
                    ticket.status === Enum.SELECT_TICKET_STATUS.IN_PROGRESS) ||
                    (ticket && ticket.status === Enum.SELECT_TICKET_STATUS.CLOSED)
                    ? date.startDate
                    : "-"}
                </P>
                <P $FontFormat={FontFormat.Body_SM}>
                  End date:{" "}
                  {ticket && ticket.status === Enum.SELECT_TICKET_STATUS.CLOSED
                    ? date.endDate
                    : "-"}
                </P>
                {ticket &&
                  ticket.status === Enum.SELECT_TICKET_STATUS.IN_PROGRESS ? (
                  <Button
                    color={Color.Secondary}
                    background={Color.Primary}
                    label={"Resolved"}
                    onClick={() => setIsShowPopupResolvedAndReopenCase(true)}
                    style={{ margin: "auto", marginTop: "auto" }}
                  />
                ) : ticket &&
                  ticket.status === Enum.SELECT_TICKET_STATUS.CLOSED ? (
                  <Button
                    color={Color.Secondary}
                    background={Color.Primary}
                    label={"Reopen"}
                    onClick={() => setIsShowPopupResolvedAndReopenCase(true)}
                    style={{ margin: "auto", marginTop: "auto" }}
                  />
                ) : null}
              </DateContainer>
            </>
          )}
          <FlexContainer>
            {isShowDetail ? (
              <ChevronDownIcon
                width={24}
                height={24}
                style={{ cursor: "pointer", margin: "auto" }}
                onClick={() => setIsShowDetail(!isShowDetail)}
              />
            ) : (
              <ChevronUpIcon
                width={24}
                height={24}
                style={{ cursor: "pointer", margin: "auto" }}
                onClick={() => setIsShowDetail(!isShowDetail)}
              />
            )}
          </FlexContainer>
        </div>
      </>
    )
  }
  const SkeletonHeadContainerForDesktop = () => {
    return (
      <>
        <DetailContainer>
          <FlexContainer $alignItems={"center"} $gap={"16px"} $width={"100%"}>
            <Skeleton
              variant="text"
              animation="wave"
              sx={{
                fontSize: FontFormat.Title_XL.fontSize,
                width: "250px",
              }}
            />
            <Skeleton
              variant="rounded"
              width={"108px"}
              height={"37px"}
              sx={{ borderRadius: "8px" }}
            />
          </FlexContainer>
          <FlexContainer $flexDirection={"column"} $width={"100%"}>
            <FlexContainer $gap={"5px"} $width={"100%"}>
              <Skeleton
                variant="text"
                animation="wave"
                sx={{
                  fontSize: FontFormat.Title_MD.fontSize,
                  width: "20%",
                }}
              />
              <Skeleton
                variant="text"
                animation="wave"
                sx={{
                  fontSize: FontFormat.Title_MD.fontSize,
                  width: "80%",
                }}
              />
            </FlexContainer>
            {Array.from({ length: 3 }).map((_, index) => {
              return (
                <Skeleton
                  key={index}
                  variant="text"
                  animation="wave"
                  sx={{
                    fontSize: FontFormat.Title_MD.fontSize,
                    width: "100%",
                  }}
                />
              )
            })}
          </FlexContainer>
          <FlexContainer $alignItems={"center"} $gap={"16px"}>
            <Skeleton
              variant="text"
              animation="wave"
              sx={{
                fontSize: FontFormat.Title_MD.fontSize,
                width: "150px",
              }}
            />
            <FlexContainer $alignItems={"center"} $gap={"8px"}>
              <Skeleton
                variant="text"
                animation="wave"
                sx={{
                  fontSize: FontFormat.Title_MD.fontSize,
                  width: "80px",
                }}
              />
              <Skeleton
                variant="circular"
                width={"36px"}
                height={"36px"}
                animation="wave"
              />
              <Skeleton
                variant="text"
                animation="wave"
                sx={{
                  fontSize: FontFormat.Body_MD.fontSize,
                  width: "150px",
                }}
              />
            </FlexContainer>
          </FlexContainer>
          <Skeleton
            variant="text"
            animation="wave"
            sx={{
              fontSize: FontFormat.Title_MD.fontSize,
              width: "150px",
            }}
          />
          <FlexContainer $alignItems={"center"} $gap={"16px"}>
            <Skeleton
              variant="text"
              animation="wave"
              sx={{
                fontSize: FontFormat.Title_MD.fontSize,
                width: "120px",
              }}
            />
            <Skeleton
              variant="circular"
              width={"38px"}
              height={"38px"}
              animation="wave"
            />
            <Skeleton
              variant="text"
              animation="wave"
              sx={{
                fontSize: FontFormat.Body_MD.fontSize,
                width: "150px",
              }}
            />
            <Skeleton variant="rounded" width={"60px"} height={"37px"} />
          </FlexContainer>
          <FlexContainer $gap={"16px"} $width={"100%"}>
            <Skeleton
              variant="text"
              animation="wave"
              sx={{
                fontSize: FontFormat.Body_MD.fontSize,
                width: "20%",
              }}
            />
            {Array.from({ length: 2 }).map((_, index) => {
              return (
                <Skeleton
                  key={index}
                  variant="rounded"
                  width={"40%"}
                  height={"37px"}
                />
              )
            })}
          </FlexContainer>
        </DetailContainer>
        <DateContainer $backgroundColor={`rgba(0, 0, 0, 0.11)`}>
          {Array.from({ length: 3 }).map((_, index) => {
            return (
              <Skeleton
                key={index}
                variant="text"
                animation="wave"
                sx={{ fontSize: FontFormat.Body_MD.fontSize, width: "280px" }}
              />
            )
          })}
          <Skeleton
            variant="rounded"
            width={"112px"}
            height={"41px"}
            sx={{ borderRadius: "8px" }}
          />
        </DateContainer>
      </>
    )
  }
  const SkeletonHeadContainerForMobile = () => {
    return (
      <>
        <FlexContainer
          $flexDirection={"column"}
          $alignItems={"flex-start"}
          $gap={"4px"}
          $width={"100%"}
        >
          <FlexContainer $gap={"4px"}>
            <Skeleton variant="rounded" width={"108px"} height={"37px"} />
            <Skeleton variant="rounded" width={"90px"} height={"37px"} />
          </FlexContainer>
          <FlexContainer
            $flexDirection={"column"}
            $gap={"16px"}
            $width={"100%"}
          >
            <Skeleton
              variant="text"
              animation="wave"
              sx={{ fontSize: FontFormat.Title_XL.fontSize, width: "80%" }}
            />
            <FlexContainer $flexDirection={"column"} $width={"100%"}>
              <FlexContainer $gap={"5px"} $width={"100%"}>
                <Skeleton
                  variant="text"
                  animation="wave"
                  sx={{
                    fontSize: FontFormat.Title_MD.fontSize,
                    width: "20%",
                  }}
                />
                <Skeleton
                  variant="text"
                  animation="wave"
                  sx={{
                    fontSize: FontFormat.Title_MD.fontSize,
                    width: "80%",
                  }}
                />
              </FlexContainer>
              {Array.from({ length: 2 }).map((_, index) => {
                return (
                  <Skeleton
                    key={index}
                    variant="text"
                    animation="wave"
                    sx={{
                      fontSize: FontFormat.Title_MD.fontSize,
                      width: "100%",
                    }}
                  />
                )
              })}
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
      </>
    )
  }
  const checkPermissionInCase = () => {
    if (ticket && ticket.user_ticket && ticket.user_ticket.length > 0) {
      return ticket.user_ticket.find(
        (user) => user.bluezebra_user.id === fetchedUser.id
      )
    }
    return null
  }

  return (
    <Container>
      <FlexContainer $justifyContent={"center"}>
        <MenuContainer
          $justifyContent={"space-between"}
          $width={"1400px"}
          $margin={"0 90px"}
        >
          <BackButton to={"/tickets"}>
            <ArrowLeftIcon
              width={24}
              height={24}
              strokeWidth={2}
              style={{ color: Color.Tertiary }}
            />
          </BackButton>
          {loadingTicket ? (
            <Skeleton
              variant="text"
              animation="wave"
              sx={{
                fontSize: FontFormat.Body_MD.fontSize,
                width: "150px",
                height: "33px",
                marginLeft: "auto",
              }}
            />
          ) : (
            <P $FontFormat={FontFormat.Title_MD} $textAlign={"right"}>
              Case ID {ticket && ticket.ticket_number}
            </P>
          )}
        </MenuContainer>
      </FlexContainer>
      <FlexContainer $justifyContent={"center"}>
        <HeadContainer>
          {loadingTicket ? (
            <>
              {windowSize.width <= 657
                ? SkeletonHeadContainerForMobile()
                : SkeletonHeadContainerForDesktop()}
            </>
          ) : (
            <>
              {windowSize.width <= 657
                ? renderHeadContainerForMobile()
                : renderHeadContainerForDesktop()}
            </>
          )}
        </HeadContainer>
      </FlexContainer>
      {isShowPopupAddSupervisors && PopupAddSupervisors()}
      {isShowPopupManageSupervisors && PopupManageSupervisors()}
      {isShowPopupResolvedAndReopenCase && PopupResolvedAndReopenCase()}
      <FlexContainer $justifyContent={"center"}>
        <BodyContainer>
          {ticket.status === Enum.SELECT_TICKET_STATUS.PENDING && (
            <>
              {fetchedUser.role === Enum.SELECT_ROLE.HEADOF_ENGINEER ? (
                <>{PopupApproveCaseForHeadOfEngineer()}</>
              ) : (
                <>{PopupApproveCaseForEngineer()}</>
              )}
            </>
          )}
          <FlexContainer
            $flexDirection={"column"}
            $justifyContent={"flex-end"}
            style={{
              maxHeight: "1400px",
              filter:
                ticket &&
                ticket.status === Enum.SELECT_TICKET_STATUS.PENDING &&
                "blur(5px)",
              pointerEvents:
                ticket &&
                ticket.status === Enum.SELECT_TICKET_STATUS.PENDING &&
                "none",
            }}
          >
            {loadingTicketMessage ? (
              <>
                <MessageContainer>
                  {Array.from({ length: 4 }).map((_, index) => {
                    return (
                      <MessageCard key={index}>
                        <FlexContainer $alignItems={"center"} $gap={"16px"}>
                          <Skeleton
                            variant="circular"
                            width={"48px"}
                            height={"48px"}
                            animation="wave"
                          />
                          <div>
                            <FlexContainer $alignItems={"center"} $gap={"12px"}>
                              <MessageNameUser>
                                <Skeleton
                                  variant="text"
                                  animation="wave"
                                  sx={{
                                    fontSize: FontFormat.Body_MD.fontSize,
                                    width: "180px",
                                  }}
                                />
                              </MessageNameUser>
                              <Skeleton
                                variant="rounded"
                                width={"60px"}
                                height={"37px"}
                                sx={{
                                  marginLeft: "auto",
                                  borderRadius: "8px",
                                }}
                              />
                            </FlexContainer>
                            <Skeleton
                              variant="text"
                              animation="wave"
                              sx={{
                                fontSize: FontFormat.Label_MD.fontSize,
                                width: "150px",
                              }}
                            />
                          </div>
                        </FlexContainer>
                        <div style={{ width: "100%" }}>
                          {Array.from({ length: 4 }).map((_, index) => (
                            <Skeleton
                              key={index}
                              variant="text"
                              animation="wave"
                              sx={{
                                fontSize: FontFormat.Body_MD.fontSize,
                                width: "80%",
                              }}
                            />
                          ))}
                        </div>
                      </MessageCard>
                    )
                  })}
                </MessageContainer>
                <TextEditorContainer>
                  <Skeleton
                    variant="rounded"
                    height={"40px"}
                    sx={{ borderRadius: "unset" }}
                  />
                  <div style={{ height: "237px" }}>
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{
                        fontSize: FontFormat.Body_MD.fontSize,
                        width: "280px",
                        marginLeft: "16px",
                      }}
                    />
                  </div>
                  <div style={{ margin: "0 32px", marginBottom: "32px" }}>
                    <Skeleton
                      variant="rounded"
                      width={"70px"}
                      height={"41px"}
                      sx={{
                        marginLeft: "auto",
                        borderRadius: "8px",
                      }}
                    />
                    <LineContainer>
                      <Line />
                    </LineContainer>
                    {windowSize.width <= 657 ? (
                      <FlexContainer $justifyContent={"center"}>
                        <Skeleton
                          variant="text"
                          animation="wave"
                          sx={{
                            fontSize: FontFormat.Body_MD.fontSize,
                            width: "280px",
                          }}
                        />
                      </FlexContainer>
                    ) : (
                      <FlexContainer
                        $flexDirection={"column"}
                        $alignItems={"center"}
                      >
                        <Skeleton
                          variant="text"
                          animation="wave"
                          sx={{
                            fontSize: FontFormat.Body_MD.fontSize,
                            width: "180px",
                          }}
                        />
                        <Skeleton
                          variant="text"
                          animation="wave"
                          sx={{
                            fontSize: FontFormat.Body_MD.fontSize,
                            width: "280px",
                          }}
                        />
                      </FlexContainer>
                    )}
                  </div>
                </TextEditorContainer>
              </>
            ) : (
              <>
                <MessageContainer ref={messagerRef}>
                  {ticketMessage.map((item, index) => {
                    const formattedDate = dayjs(item.created_at).format(
                      "D MMM YYYY, h.mm A"
                    )
                    const images = item.message_file.filter((file) =>
                      ["image/png", "image/jpg", "image/jpeg"].includes(
                        file.file_tpye
                      )
                    )

                    const files = item.message_file.filter(
                      (file) =>
                        !["image/png", "image/jpg", "image/jpeg"].includes(
                          file.file_tpye
                        )
                    )
                    return (
                      <MessageCard
                        $backgroundColor={
                          item &&
                          item.bluezebra_user_id !== fetchedUser.id &&
                          Color.Secondary_Hover
                        }
                        key={index}
                      >
                        <FlexContainer $alignItems={"center"} $gap={"16px"}>
                          {item && item.bluezebra_user !== null && (
                            <>
                              <MessageImageUser
                                src={item.bluezebra_user.profile_image}
                                alt={item.bluezebra_user.name}
                              />
                              <div>
                                <FlexContainer
                                  $alignItems={"center"}
                                  $gap={"12px"}
                                >
                                  <MessageNameUser>
                                    {item.bluezebra_user.name}
                                  </MessageNameUser>
                                  {item &&
                                    item.ticket_role &&
                                    item.ticket_role[0]?.name && (
                                      <StatusBox
                                        $textColor={textColor(
                                          item.ticket_role[0].name
                                        )}
                                        $background={background(
                                          item.ticket_role[0].name
                                        )}
                                      >
                                        {item.ticket_role[0].name}
                                      </StatusBox>
                                    )}
                                </FlexContainer>
                                <P $FontFormat={FontFormat.Label_MD}>
                                  {formattedDate}
                                </P>
                              </div>
                            </>
                          )}
                          {item && item.client_user !== null && (
                            <>
                              <MessageImageUser
                                src={item.client_user.logo_image}
                                alt={item.client_user.name}
                              />
                              <div>
                                <FlexContainer
                                  $alignItems={"center"}
                                  $gap={"12px"}
                                >
                                  <MessageNameUser>
                                    {item.client_user.name}
                                  </MessageNameUser>
                                </FlexContainer>
                                <P $FontFormat={FontFormat.Label_MD}>
                                  {formattedDate}
                                </P>
                              </div>
                            </>
                          )}
                        </FlexContainer>
                        {item.message && (
                          <MessageText
                            dangerouslySetInnerHTML={{
                              __html: item.message,
                            }}
                          />
                        )}

                        {item.message_file.length !== 0 && (
                          <SliderContainer>
                            <Carousel
                              className="ticket-message-file"
                              responsive={responsive}
                            >
                              {images.map((img, index) => (
                                <PreviewImageInMassage
                                  key={index}
                                  src={img.file}
                                  alt={img.file_name}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleShowImageClick(images, index)}
                                />
                              ))}
                            </Carousel>
                          </SliderContainer>
                        )}
                        {item.message_file.length !== 0 && (
                          <SliderContainer>
                            <Carousel
                              className="preview-file ticket-message-file"
                              responsive={responsive}
                            >
                              {files.map((item, index) => (
                                <MessageFilesContainer>
                                  <DocumentTextIcon
                                    width={48}
                                    height={48}
                                    color={Color.Primary}
                                  />
                                  <FilesBox
                                    href={item.file}
                                    download={item.file_name}
                                    key={index}
                                  >
                                    {item.file_name}
                                  </FilesBox>
                                </MessageFilesContainer>
                              ))}
                            </Carousel>
                          </SliderContainer>
                        )}
                      </MessageCard>
                    )
                  })}
                  {isShowImage && (
                    <div>
                      {PopupShowImage(selectedImage, selectedImageIndex)}
                    </div>
                  )}
                </MessageContainer>
                {ticket &&
                  ticket.status === Enum.SELECT_TICKET_STATUS.CLOSED && (
                    <P
                      $FontFormat={FontFormat.Title_XL}
                      $textAlign={"center"}
                      style={{ position: "relative", top: "200px" }}
                    >
                      You cannot chat with your client due to closed case.
                    </P>
                  )}
                <TextEditorContainer
                  $filter={
                    (ticket &&
                      ticket.status === Enum.SELECT_TICKET_STATUS.CLOSED) ||
                      checkPermissionInCase() === undefined
                      ? "blur(5px)"
                      : "none"
                  }
                  $pointerEvents={
                    (ticket &&
                      ticket.status === Enum.SELECT_TICKET_STATUS.CLOSED) ||
                      checkPermissionInCase() === undefined
                      ? "none"
                      : "unset"
                  }
                >
                  <ReactQuill
                    placeholder="Type here......"
                    modules={toolbarOptions}
                    value={formatDate.textEditor}
                    onChange={onTextEditorChange}
                  />
                  {(previewFiles && previewFiles[0]?.name === "") ||
                    previewFiles.length === 0 ? (
                    <div style={{ margin: "0 32px", marginBottom: "32px" }}>
                      <Button
                        label={
                          isSendLoading ? (
                            <CircularProgress
                              size={24}
                              style={{
                                color: Color.Secondary,
                                paddingLeft: "10px",
                                paddingRight: "10px",
                              }}
                            />
                          ) : (
                            "Send"
                          )
                        }
                        color={Color.Secondary}
                        background={Color.Primary}
                        backgroundActive={Color.Primary_Pressed}
                        width={"70px"}
                        style={{ marginLeft: "auto" }}
                        onClick={onSendMessage}
                        disabled={
                          formatDate.textEditor
                            .replace(/<[^>]+>/g, "")
                            .trim() === "" &&
                          formatDate.uploadFiles.length === 0
                        }
                      />
                      <LineContainer>
                        <Line />
                      </LineContainer>
                      {windowSize.width <= 657 ? (
                        <DropFile onClick={onClickUploadFile}>
                          Click here to upload images and files
                        </DropFile>
                      ) : (
                        <>
                          {isUpload ? (
                            <div style={{ textAlign: "center" }}>
                              <CircularProgress
                                size={48}
                                style={{
                                  color: Color.Primary,
                                  paddingLeft: "10px",
                                  paddingRight: "10px",
                                }}
                              />
                            </div>
                          ) : (
                            <DropFile
                              onClick={onClickUploadFile}
                              onDrop={handleDrop}
                              onDragOver={handleDragOver}
                            >
                              Drag & Drop <br /> to upload images and files
                            </DropFile>
                          )}
                        </>
                      )}
                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={handleUploadFile}
                      />
                    </div>
                  ) : (
                    <>
                      <Button
                        label={
                          isSendLoading ? (
                            <CircularProgress
                              size={24}
                              style={{
                                color: Color.Secondary,
                                paddingLeft: "10px",
                                paddingRight: "10px",
                              }}
                            />
                          ) : (
                            "Send"
                          )
                        }
                        color={Color.Secondary}
                        background={Color.Primary}
                        backgroundActive={Color.Primary_Pressed}
                        width={"70px"}
                        style={{ marginLeft: "auto", marginRight: "16px" }}
                        onClick={onSendMessage}
                        disabled={
                          formatDate.textEditor
                            .replace(/<[^>]+>/g, "")
                            .trim() === "" &&
                          formatDate.uploadFiles.length === 0
                        }
                      />
                      <LineContainer>
                        <Line />
                      </LineContainer>
                      <FlexContainer
                        $alignItems={"center"}
                        $gap={"16px"}
                        $padding={"0 16px"}
                        $justifyContent={windowSize.width <= 657 && "center"}
                        style={{ marginBottom: "32px" }}
                      >
                        <SliderContainer>
                          <Carousel
                            className="ticket-message-file"
                            responsive={responsive}
                          >
                            {previewFiles.map((item, index) => {
                              return (
                                <div key={index}>
                                  {item.type === "image/jpeg" ||
                                    item.type === "image/png" ||
                                    item.type === "image/jpg" ? (
                                    <div style={{ position: "relative" }}>
                                      <PreviewImageFile
                                        src={item.url}
                                        alt={item.name}
                                      />
                                      <SolidXCircleIcon
                                        width={"16px"}
                                        height={"16px"}
                                        color={Color.Tertiary}
                                        onMouseEnter={() => setIsHovered(true)}
                                        onMouseLeave={() => setIsHovered(false)}
                                        onClick={() => handleDeleteFile(index)}
                                        style={{
                                          cursor: "pointer",
                                          position: "absolute",
                                          top: "0px",
                                          right: "0px",
                                          padding: "2px",
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    <PreviewFilesBox>
                                      <P
                                        $FontFormat={FontFormat.Title_MD}
                                        $textColor={Color.Primary}
                                        style={{
                                          whiteSpace: "nowrap",
                                          width: "180px",
                                          textOverflow: "ellipsis",
                                          overflow: "hidden",
                                        }}
                                      >
                                        {item && item.name}
                                      </P>
                                      <XMarkIcon
                                        width={"16px"}
                                        height={"16px"}
                                        color={
                                          isHovered
                                            ? Color.Primary_Hover
                                            : Color.Primary
                                        }
                                        strokeWidth={3.1}
                                        onMouseEnter={() => setIsHovered(true)}
                                        onMouseLeave={() => setIsHovered(false)}
                                        onClick={() => handleDeleteFile(index)}
                                        style={{
                                          cursor: "pointer",
                                        }}
                                      />
                                    </PreviewFilesBox>
                                  )}
                                </div>
                              )
                            })}
                          </Carousel>
                        </SliderContainer>
                        <FlexContainer
                          $justifyContent={"flex-end"}
                          $padding={"0 16px"}
                        >
                          {formatDate &&
                            formatDate.uploadFiles &&
                            formatDate.uploadFiles.length < 10 && (
                              <AddButton>
                                <PlusIcon
                                  width={"24px"}
                                  height={"24px"}
                                  color={
                                    isHovered
                                      ? Color.Primary_Hover
                                      : Color.Primary
                                  }
                                  strokeWidth={2.5}
                                  onMouseEnter={() => setIsHovered(true)}
                                  onMouseLeave={() => setIsHovered(false)}
                                  onClick={() => onClickUploadFile()}
                                  style={{
                                    cursor: "pointer",
                                  }}
                                />
                                <input
                                  type="file"
                                  ref={fileInputRef}
                                  style={{ display: "none" }}
                                  onChange={handleUploadFile}
                                />
                              </AddButton>
                            )}
                        </FlexContainer>
                      </FlexContainer>
                    </>
                  )}
                </TextEditorContainer>
              </>
            )}
          </FlexContainer>
        </BodyContainer>
      </FlexContainer>
    </Container>
  )
}
