import React from "react"
import { Link } from "react-router-dom"
import { ArrowLeftIcon } from "../../util/icons/Outline"
import { BackgroundContainer } from "./styled"

export const BackgroundGrayContainer = ({
  color,
  windowSize,
  link,
  children,
}) => {
  return (
    <BackgroundContainer>
      <Link to={link} style={{ position: windowSize <= 657 && "absolute" }}>
        <ArrowLeftIcon
          width={24}
          height={24}
          strokeWidth={2}
          style={{ color: color.Tertiary }}
        />
      </Link>
      {children}
    </BackgroundContainer>
  )
}
