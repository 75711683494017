import React, { useRef, useState, useEffect } from "react"
import {
  BackgroundBlueContainer,
  Button,
  Input,
  Select,
  Pagination,
  Popup,
  ProductCard,
  ProductSkeletonCard,
  Menu,
} from "../../components"
import { Color, FontFormat, Validator, Enum, WindowSize } from "../../util"
import { OverlayContainer } from "../../style/main"
import {
  MagnifyingGlassIcon,
  PencilIcon,
  UserIcon,
  CheckCircleIcon,
  XCircleIcon,
} from "../../util/icons/Outline"
import {
  Text,
  CardContainer,
  FilterBarContainer,
  FlexContainer,
  MoreOptionContainer,
  MoreOptionList,
  PopupContainer,
  PopupTitleActive,
  PopupButton,
  PopupProductContainer,
  PopupProductImage,
  Sticker,
} from "./styled"
import {
  getBluezebraUser,
  searchProduct,
  updateIsActiveProductById,
  createSupervisor,
  updateSupervisor,
  getProductCategory,
  getSupervisor,
} from "../../api"
import { userInfo, useUserPermission } from "../../auth"
import { people } from "../../img"
import CircularProgress from "@mui/material/CircularProgress"

export const Products = () => {
  const [fetchedUser, setFetchedUser] = useState([])
  const [userList, setUserList] = useState([])
  const [product, setProduct] = useState(null)
  const [productList, setProductList] = useState([])
  const [chooseProductCategory, setChooseProductCategory] = useState(0)
  const [categoryList, setCategoryList] = useState([])
  const [supervisorList, setSupervisorList] = useState([])
  const [searchQuery, setSearchQuery] = useState("")
  const [supervisorQuery, setSupervisorQuery] = useState([])
  const [selectedSupervisor, setSelectedSupervisor] = useState("All")
  const [selectedSupervisorId, setSelectedSupervisorId] = useState(0)
  const [selectedStatus, setSelectedStatus] = useState(
    Enum.SELECT_STATUS.ACTIVE
  )
  const [bluezebraUserId, setBluezebraUserId] = useState([])
  const [cardId, setCardId] = useState("")
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [isShowPopupActiveAndInActive, setIsShowPopupActiveAndInActive] =
    useState(false)
  const [isShowPopupUpdateSupervisors, setIsShowPopupUpdateSupervisors] =
    useState(false)
  const [isShowMoreOption, setIShowsMoreOption] = useState(false)
  const [isActiveProduct, setIsActiveProduct] = useState(true)
  const [isUploadSupervisor, setIsUploadSupervisor] = useState(false)
  const [isValid, setIsValid] = useState(true)
  const [isUpdate, setIsUpdate] = useState(false)
  const [loading, setLoading] = useState(false)
  const { verifyPermissions, checkPermission } = useUserPermission()
  const windowSize = WindowSize()
  const popupRef = useRef(null)
  const limit = 12

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const user = await userInfo()

        setFetchedUser(user)
        checkPermission(user)
        setLoading(false)
      } catch (e) {
        console.error(e)
        setLoading(false)
      }
    }

    const fetchUserList = async () => {
      try {
        const userList = await getBluezebraUser()
        setUserList(userList)
      } catch (e) {
        console.error(e)
      }
    }
    fetchData()
    fetchUserList()
  }, [])
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setLoading(true)
        if (isUpdate) {
          setIsUpdate(false)
        }
        const [searchResponse] = await Promise.all([
          searchProduct(
            searchQuery,
            selectedStatus,
            chooseProductCategory,
            selectedSupervisorId,
            page,
            limit
          ),
        ])
        setProductList(searchResponse.data)
        setTotal(searchResponse.total)
        
        if (searchResponse.total < limit) {
          setPage(1)
        } else {
          let totalPages = Math.ceil(searchResponse.total / limit)
          if (page > totalPages) {
            setPage(totalPages)
          } else {
            setPage(page)
          }
        }
        setLoading(false)
      } catch (e) {
        setLoading(false)
        console.error(e)
      }
    }
    fetchProducts()
  }, [
    selectedStatus,
    searchQuery,
    chooseProductCategory,
    selectedSupervisorId,
    isUploadSupervisor,
    page,
    limit,
    isUpdate,
  ])
  useEffect(() => {
    const fetchCategoryAndSupervisor = async () => {
      try {
        const [category, supervisor] = await Promise.all([
          getProductCategory(),
          getSupervisor(),
        ])
        setCategoryList(category)
        setSupervisorQuery(supervisor)
      } catch (e) {
        console.error(e)
      }
    }
    fetchCategoryAndSupervisor()
  }, [])
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIShowsMoreOption(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  const statusOption = [
    { value: 1, label: Enum.SELECT_STATUS.ACTIVE },
    { value: 2, label: Enum.SELECT_STATUS.IN_ACTIVE },
  ]
  const supervisorsOption = [
    { value: 1, id: 0, label: "All" },
    { value: 2, id: 1, label: "No supervisor" },
    ...supervisorQuery.map((item, index) => ({
      ...item,
      value: index + 3,
    })),
  ]
  const onPageChange = (e, newPage) => {
    setPage(newPage)
  }
  const onSelectChange = (value) => {
    const status = statusOption.find((option) => option.value === value)
    setSelectedStatus(status.label)
  }
  const onSelectSupervisorChange = (value) => {
    const supervisor = supervisorsOption.find(
      (option) => option.value === value
    )
    setSelectedSupervisor(supervisor.label)
    setSelectedSupervisorId(supervisor.id)
  }
  const onChooseSupervisors = (id) => {
    setBluezebraUserId(id)
  }
  const onSaveSupervisors = () => {
    let validate = true
    if (bluezebraUserId.length === 0) {
      validate = false
    }
    setIsValid(validate)
    if (validate) {
      let data = bluezebraUserId.map((userId) => ({
        bluezebraUserId: userId,
        productId: product.id,
      }))
      for (const newData of data) {
        try {
          setLoading(true)
          setIsUploadSupervisor(true)
          createSupervisor(newData)
            .then(() => {
              setLoading(false)
              setIsUploadSupervisor(false)
              setIsShowPopupUpdateSupervisors(false)
              setBluezebraUserId("")
            })
            .catch((e) => {
              console.log(e)
              setLoading(false)
            })
        } catch (e) {
          console.log(e)
          setLoading(false)
        }
      }
    }
  }
  const onUpdateSupervisors = async () => {
    let validate = true
    if (bluezebraUserId.length === 0) {
      validate = false
    }

    setIsValid(validate)

    if (validate) {
      const bluezebraId = bluezebraUserId.map((item) => item.id)
      let data = {
        bluezebraUserId: bluezebraId,
        productId: product.id,
      }
      try {
        setLoading(true)
        setIsUploadSupervisor(true)
        await updateSupervisor(data)
      } catch (e) {
        console.log(e)
        setLoading(false)
      } finally {
        setLoading(false)
        setIsUploadSupervisor(false)
        setIsUpdate(true)
        setIsShowPopupUpdateSupervisors(false)
        setBluezebraUserId([])
      }
    }
  }
  const onUpdateActiveAndInActiveProduct = async () => {
    const Active = { is_active: !isActiveProduct }
    try {
      setLoading(true)
      await updateIsActiveProductById(product.id, Active)
    } catch (e) {
      console.log(e)
      setLoading(false)
    } finally {
      setIsUpdate(true)
      setLoading(false)
      setIsShowPopupActiveAndInActive(false)
    }
  }
  const onMoreOption = (id) => {
    setIShowsMoreOption(cardId !== id || !isShowMoreOption)
    setCardId(id)
  }
  const PopupActiveAndInActiveProduct = () => {
    return (
      <>
        <OverlayContainer $top={0} $left={0} $isBlur={true} />
        <PopupContainer>
          <PopupTitleActive>
            Do you want to “{!isActiveProduct ? "Active" : "Inactive"}” this
            product?
          </PopupTitleActive>
          <FlexContainer $justifyContent={"center"}>
            <PopupButton
              $lableColor={Color.Primary}
              $borderRadius={"0px 0px 0px 10px"}
              $backgroundColor={Color.Secondary_Hover}
              $backgroundColorHover={Color.Secondary}
              onClick={() => setIsShowPopupActiveAndInActive(false)}
            >
              Cancel
            </PopupButton>
            <PopupButton
              $lableColor={Color.Secondary}
              $borderRadius={"0px 0px 10px 0px"}
              $backgroundColor={Color.Primary}
              $backgroundColorHover={Color.Primary_Hover}
              onClick={() => onUpdateActiveAndInActiveProduct()}
            >
              {loading ? (
                <CircularProgress
                  size={24}
                  style={{
                    color: Color.Secondary,
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  }}
                />
              ) : (
                "Confirm"
              )}
            </PopupButton>
          </FlexContainer>
        </PopupContainer>
      </>
    )
  }
  const PopupMoreOption = (product, index, countProduct) => {
    return (
      <MoreOptionContainer ref={popupRef} $lastItem={index + 1} $countProduct={countProduct}>
        {verifyPermissions.Products.Edit && (
          <MoreOptionList
            onClick={() => {
              setProduct(product)
              setSupervisorList(product.supervisors)
              setIsShowPopupUpdateSupervisors(true)
              setBluezebraUserId(
                product.supervisors.length !== 0 &&
                product.supervisors.map((item) => item.id)
              )
              setIShowsMoreOption(false)
            }}
            $position={
              verifyPermissions.Products.Edit &&
                verifyPermissions.Products.Inactive
                ? "top"
                : "oneOption"
            }
          >
            {product.supervisors.length === 0 ? (
              <>
                <UserIcon width={18} height={18} /> Update supervisors
              </>
            ) : (
              <>
                <PencilIcon width={20} height={20} /> Edit supervisors
              </>
            )}
          </MoreOptionList>
        )}
        {verifyPermissions.Products.Inactive && (
          <MoreOptionList
            onClick={() => {
              setProduct(product)
              setIsActiveProduct(product.is_active)
              setIsShowPopupActiveAndInActive(true)
              setIShowsMoreOption(false)
            }}
            $position={
              verifyPermissions.Products.Edit &&
                verifyPermissions.Products.Inactive
                ? "bottom"
                : "oneOption"
            }
          >
            {!product.is_active ? (
              <>
                <CheckCircleIcon idth={18} height={18} /> Active
              </>
            ) : (
              <>
                <XCircleIcon width={18} height={18} /> Inactive
              </>
            )}
          </MoreOptionList>
        )}
      </MoreOptionContainer>
    )
  }
  const PopupUpdateSupervisors = () => {
    return (
      <Popup
        title={"Update supervisors"}
        isButton={true}
        isLoading={loading}
        saveLabel={"Submit"}
        onClose={() => {
          setIsShowPopupUpdateSupervisors(false)
          setBluezebraUserId([])
          setIsValid(true)
        }}
        onSave={
          supervisorList.length === 0 ? onSaveSupervisors : onUpdateSupervisors
        }
      >
        <PopupProductContainer>
          <div>
            <PopupProductImage
              src={product.thumbnail_image}
              alt={product.name}
            />
          </div>
          <div style={{ width: "100%" }}>
            <Input
              type="text"
              label={"Product name"}
              value={product.name}
              disabled={true}
            />
            <Text
              $fontSize={FontFormat.Body_MD.fontSize}
              $fontWeight={FontFormat.Body_MD.fontWeight}
            >
              Partner: {product.partner_name}
            </Text>
            {windowSize.width >= 768 && <Sticker src={people} alt="people" />}
          </div>
        </PopupProductContainer>
        <FlexContainer $flexDirection={"column"} $gap={"100px"}>
          <div>
            <Select
              onSelectChange={onChooseSupervisors}
              isShowDefaultOption={true}
              optionsData={userList}
              value={supervisorList}
              width={"100%"}
              height={"auto !important"}
              label={"Supervisor"}
              isTag={true}
              multiple={true}
              isValid={isValid}
            />
            {!isValid && bluezebraUserId.length === 0 ? (
              <p style={{ ...Validator.errorStyle, top: 0 }}>
                Please complete this field.
              </p>
            ) : null}
          </div>
        </FlexContainer>
      </Popup>
    )
  }
  return (
    <>
      <Menu />
      <BackgroundBlueContainer
        title={"Products"}
        count={total}
        textCount={"Products"}
      >
        <FilterBarContainer>
          <Input
            placeholder="Search..."
            icon={<MagnifyingGlassIcon width={24} height={24} />}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            width={windowSize.width <= 1200 ? "100%" : "70%"}
          />
          <FlexContainer
            $gap={"12px"}
            $width={windowSize.width <= 1200 ? "100%" : "30%"}
          >
            <Select
              onSelectChange={onSelectChange}
              isShowDefaultOption={true}
              optionsData={statusOption}
              width={"50%"}
              height={"26px !important"}
              label={"Status"}
            />
            <Select
              onSelectChange={onSelectSupervisorChange}
              isShowDefaultOption={true}
              optionsData={supervisorsOption}
              value={selectedSupervisor}
              width={"50%"}
              height={"26px !important"}
              label={"Supervisor"}
            />
          </FlexContainer>
        </FilterBarContainer>
        <FlexContainer
          $justifyContent={windowSize.width <= 1200 ? "flex-start" : "center"}
        >
          <FlexContainer
            $flexWrap={windowSize.width <= 1200 ? "nowrap" : "wrap"}
            $overflowX={"scroll"}
            $justifyContent={windowSize.width <= 1200 ? "flex-start" : "center"}
            $width={"941px"}
          >
            {categoryList.map((item, index) => {
              return (
                <Button
                  key={index}
                  label={item.name}
                  fontWeight={400}
                  margin={"0 12px 12px"}
                  color={
                    chooseProductCategory === item.id
                      ? Color.Secondary
                      : Color.Primary
                  }
                  background={
                    chooseProductCategory === item.id
                      ? Color.Primary
                      : Color.Secondary
                  }
                  isBorder={true}
                  borderColor={Color.Primary}
                  onClick={() => setChooseProductCategory(item.id)}
                />
              )
            })}
          </FlexContainer>
        </FlexContainer>
        {isShowPopupUpdateSupervisors && PopupUpdateSupervisors()}
        {isShowPopupActiveAndInActive && PopupActiveAndInActiveProduct()}
        {loading ? (
          <CardContainer
            $gap={windowSize.width > 1920 && "6px"}
            $justifyContent={"flex-start"}
            style={{ margin: windowSize.width > 1920 && "0 71px" }}
          >
            {Array.from({ length: 12 }).map((_, index) => (
              <ProductSkeletonCard key={index} />
            ))}
          </CardContainer>
        ) : (
          <>
            {productList.length === 0 ? (
              <FlexContainer
                $alignItems={"center"}
                $flexDirection={"column"}
                style={{ marginTop: "40px" }}
              >
                <MagnifyingGlassIcon
                  width={64}
                  height={64}
                  style={{ position: "relative", zIndex: 2 }}
                />
                <Text
                  $fontSize={FontFormat.Title_XL.fontSize}
                  $fontWeight={FontFormat.Title_XL.fontWeight}
                >
                  No Items Found
                </Text>
                <Text
                  $fontSize={FontFormat.Body_MD.fontSize}
                  $fontWeight={FontFormat.Body_MD.fontWeight}
                >
                  We can’t find any item matching your search.
                </Text>
              </FlexContainer>
            ) : (
              <CardContainer
                $gap={windowSize.width > 1920 && "6px"}
                $justifyContent={"flex-start"}
                style={{ margin: windowSize.width > 1920 && "0 71px" }}
              >
                {productList.map((item, index) => {
                  return (
                    <ProductCard
                      key={index}
                      index={index}
                      item={item}
                      productImage={item.thumbnail_image}
                      title={item.name}
                      partner={item.partner_name}
                      supervisors={item.supervisors}
                      isAdmin={fetchedUser && fetchedUser.is_admin}
                      verifyPermissions={verifyPermissions.Products}
                      isShowMoreOption={isShowMoreOption}
                      cardId={cardId}
                      onMoreOption={onMoreOption}
                      PopupMoreOption={PopupMoreOption}
                      countProduct={productList.length}
                    />
                  )
                })}
                <Pagination
                  count={total}
                  page={page}
                  limit={limit}
                  onChange={onPageChange}
                />
              </CardContainer>
            )}
          </>
        )}
      </BackgroundBlueContainer>
    </>
  )
}
