import React from "react"
import { FontFormat } from "../../util"
import { CarContainer, FlexContainer } from "./styled"
import Skeleton from "@mui/material/Skeleton"

export const ProductSkeletonCard = () => {
  return (
    <FlexContainer $justifyContent={"center"} $isWidthPercen={true}>
      <CarContainer>
        <Skeleton
          variant="rounded"
          width={"268px"}
          height={"160px"}
          animation="wave"
        />
        <FlexContainer $flexDirection={"column"} $gap={"4px"}>
          <Skeleton
            variant="text"
            width={"268px"}
            height={"54px"}
            animation="wave"
          />
          <Skeleton
            variant="text"
            animation="wave"
            sx={{ fontSize: FontFormat.Body_MD.fontSize }}
          />
          <FlexContainer
            $alignItems={"center"}
            $gap={"8px"}
            style={{ height: "38px" }}
          >
            <Skeleton
              variant="text"
              width={"100px"}
              animation="wave"
              sx={{ fontSize: FontFormat.Body_MD.fontSize }}
            />
            <FlexContainer
              $alignItems={"center"}
              style={{ position: "relative" }}
            >
              {Array.from({ length: 4 }).map((_, index) => (
                <Skeleton
                  key={index}
                  variant="circular"
                  width={"36px"}
                  height={"36px"}
                  animation="wave"
                />
              ))}
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
      </CarContainer>
    </FlexContainer>
  )
}
