import React, { useState, useEffect } from "react"
import { Color, FontFormat } from "../../util"
import { SolidEyeIcon, SolidEyeSlashIcon } from "../../util/icons/Solid"
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

export const Input = ({
  type,
  width,
  label,
  placeholder,
  icon,
  value,
  onChange,
  onClick,
  isValid,
  isValidEmail,
  onKeyDown,
  readOnly,
  disabled,
}) => {
  const [data, setData] = useState("")
  const [validate, setValidate] = useState(true)
  const [showPassword, setShowPassword] = useState(false)
  useEffect(() => {
    if (isValid !== undefined) {
      setValidate(isValid)
    }
    if (value !== "" || value !== undefined) {
      setData(value)
    }
  }, [value, isValid])

  const textFieldSX = {
    width: "100%",
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: !disabled && Color.Primary_Hover,
    },
    "& .MuiInputBase-root": {
      borderRadius: "8px",
      backgroundColor: Color.Secondary,
    },
    "& .MuiInputBase-input": {
      padding: "10px 12px",
      color: Color.Primary,
      fontFamily: "IBM Plex Sans Thai, sans-serif",
      fontSize: FontFormat.Title_MD.fontSize,
      fontWeight: FontFormat.Title_MD.fontWeight,
    },
    "& .MuiInputBase-input::placeholder": {
      color: Color.Tertiary,
    },
    "& .MuiFormLabel-root": {
      color: Color.Tertiary,
      fontFamily: "IBM Plex Sans Thai, sans-serif",
      fontSize: FontFormat.Title_MD.fontSize,
      fontWeight: FontFormat.Title_MD.fontWeight,
      top: !data ? "-4px" : 0,
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: Color.Tertiary,
      top: 0,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: isValidEmail ? Color.Error : !validate && data === "" ? Color.Error : Color.Disabled,
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: Color.Primary_Pressed,
      borderWidth: 1,
    },
  }

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  return (
    <Box sx={{ minWidth: 120, width: width }}>
      {type === "password" ? (
        <FormControl sx={textFieldSX} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ?
                    <SolidEyeSlashIcon
                      width={24}
                      height={24}
                    /> :
                    <SolidEyeIcon
                      width={24}
                      height={24}
                    />
                  }
                </IconButton>
              </InputAdornment>
            }
            value={value}
            label="Password"
            onKeyDown={onKeyDown}
            onChange={onChange}
            onClick={onClick}
            disabled={disabled}
          />
        </FormControl>
      ) : (
        <TextField
          id="outlined-basic"
          type={type}
          label={label}
          placeholder={placeholder}
          variant="outlined"
          sx={textFieldSX}
          value={value}
          InputProps={{
            startAdornment: icon,
            readOnly: readOnly,
          }}
          onKeyDown={onKeyDown}
          onChange={onChange}
          onClick={onClick}
          disabled={disabled}
        />
      )}
    </Box>
  )
}
