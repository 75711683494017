import axios from "axios"

export const Axios = async (contentType, method, url, token, params, body) => {
  try {
    const response = await axios({
      method: method,
      url: url,
      headers: {
        "Content-Type": contentType,
        Authorization: `Bearer ${token}`,
      },
      params: params,
      data:  body,
    })

    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}
