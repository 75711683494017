import React from "react"
import { Color, FontFormat, WindowSize } from "../../util"
import { FlexContainer, H2, P, Span } from "../../style/main"
import {
  LinkContainer,
  CarContainer,
  StatusBox,
  ClientImage,
  BluezebraImage,
  SubjectText,
  MoreBluezebra,
} from "./styled"
import Avatar from "@mui/material/Avatar"
import dayjs from "dayjs"

export const TicketCard = ({
  item,
  notification,
  clientImage,
  profileImage,
  userId,
}) => {
  const windowSize = WindowSize()

  const textColor = (item) => {
    switch (item) {
      case "Pending":
        return "#F4C900"
      case "In Progress":
        return "#007D64"
      case "Supporter":
      case "Main":
        return Color.Primary
      case "Closed":
        return Color.Error
      default:
        return Color.Primary
    }
  }
  const background = (item) => {
    switch (item) {
      case "Pending":
        return "#FEFBE4"
      case "In Progress":
        return "#E0F3F0"
      case "Supporter":
        return Color.Secondary
      case "Main":
        return Color.Baby_Blue
      case "Closed":
        return "#F9E5EB"
      default:
        return Color.Secondary
    }
  }
  const roleUser = (item) => {
    let rolename = null
    if (Array.isArray(item)) {
      const mainUserRole = item.find(
        (user) => user.bluezebra_user && user.bluezebra_user.id === userId
      )
      if (
        mainUserRole &&
        mainUserRole.ticket_role &&
        mainUserRole.ticket_role.name
      ) {
        rolename = mainUserRole.ticket_role.name
      }
    }
    return rolename
  }
  const formattedDate = dayjs(item.updated_at).format("D MMM YYYY, h.mm A")

  return (
    <LinkContainer to={`/tickets/detail/${item.id}`}>
      <FlexContainer
        $justifyContent={"center"}
        $margin={windowSize.width < 600 ? "0 0 10px 0" : "0 0 8px 0"}
      >
        <CarContainer
          $notification={notification && `8px solid ${Color.Primary}`}
          $paddingLeft={notification && "16px"}
        >
          {windowSize.width <= 1200 ? (
            <>
              <FlexContainer
                $justifyContent={"space-between"}
                $alignItems={"center"}
                $gap={"6px"}
                $width={"100%"}
              >
                <H2
                  $FontFormat={FontFormat.Title_MD}
                  $textColor={Color.Primary}
                  $whiteSpace={"nowrap"}
                  $overFlow={"hidden"}
                  $textOverflow={"ellipsis"}
                  $overflow={"hidden"}
                  $width={"85%"}
                >
                  Subject:{" "}
                  <SubjectText to={`/tickets/detail/${item.id}`}>
                    {item.title}
                  </SubjectText>
                </H2>
                <StatusBox
                  $textColor={textColor(item.status)}
                  $background={background(item.status)}
                >
                  {item && item.status}
                </StatusBox>
              </FlexContainer>
              {roleUser(item.user_ticket) !== null && (
                <FlexContainer $alignItems={"center"} $gap={"12px"}>
                  <P $FontFormat={FontFormat.Title_MD}>Role:</P>
                  <StatusBox
                    $textColor={textColor(roleUser(item.user_ticket))}
                    $background={background(roleUser(item.user_ticket))}
                  >
                    {roleUser(item.user_ticket)}
                  </StatusBox>
                </FlexContainer>
              )}
              <P $FontFormat={FontFormat.Title_MD}>
                Case ID:{" "}
                <Span $FontFormat={FontFormat.Body_MD}>
                  {item.ticket_number}
                </Span>
              </P>
            </>
          ) : (
            <FlexContainer $alignItems={"center"} $gap={"12px"}>
              <FlexContainer
                $alignItems={"center"}
                $gap={"12px"}
                $width={"85%"}
              >
                <H2
                  $FontFormat={FontFormat.Title_MD}
                  $textColor={Color.Primary}
                  $width={windowSize.width > 1200 && "auto"}
                  $whiteSpace={"nowrap"}
                  $overFlow={"hidden"}
                  $textOverflow={"ellipsis"}
                  $overflow={"hidden"}
                >
                  Subject:{" "}
                  <SubjectText to={`/tickets/detail/${item.id}`}>
                    {item.title}
                  </SubjectText>
                </H2>
                <StatusBox
                  $textColor={textColor(item.status)}
                  $background={background(item.status)}
                >
                  {item && item.status}
                </StatusBox>
                {roleUser(item.user_ticket) !== null && (
                  <StatusBox
                    $textColor={textColor(roleUser(item.user_ticket))}
                    $background={background(roleUser(item.user_ticket))}
                  >
                    {roleUser(item.user_ticket)}
                  </StatusBox>
                )}
              </FlexContainer>
              <P
                $FontFormat={FontFormat.Title_MD}
                $textAlign={"right"}
                $width={"15%"}
              >
                Case ID:{" "}
                <Span $FontFormat={FontFormat.Body_MD}>
                  {item.ticket_number}
                </Span>
              </P>
            </FlexContainer>
          )}
          <FlexContainer
            $flexDirection={windowSize.width <= 1200 && "column"}
            $alignItems={windowSize.width <= 1200 ? "flex-start" : "center"}
            $gap={windowSize.width <= 1200 ? "2px" : "12px"}
          >
            <P
              $FontFormat={FontFormat.Title_MD}
              $width={windowSize.width <= 1200 && "100%"}
              $whiteSpace={windowSize.width <= 1200 && "nowrap"}
              $overFlow={windowSize.width <= 1200 && "hidden"}
              $textOverflow={windowSize.width <= 1200 && "ellipsis"}
              $overflow={windowSize.width <= 1200 && "hidden"}
            >
              Product:{" "}
              <Span $FontFormat={FontFormat.Body_MD}>
                {item.product.name ? item.product.name : "-"}
              </Span>
            </P>
            {windowSize.width <= 1200 ? (
              <>
                <FlexContainer $alignItems={"center"} $gap={"12px"}>
                  <P $FontFormat={FontFormat.Title_MD}>Client :</P>
                  {!clientImage ? (
                    <Avatar
                      src={clientImage}
                      alt={item.client_user.name}
                      style={{ width: "36px", height: "36px" }}
                    />
                  ) : (
                    <ClientImage
                      src={clientImage}
                      alt={item.client_user.name}
                    />
                  )}
                </FlexContainer>
                <FlexContainer $alignItems={"center"} $gap={"12px"}>
                  <P $FontFormat={FontFormat.Title_MD}>Bluezebra:</P>
                  <FlexContainer
                    $alignItems={"center"}
                    style={{ position: "relative" }}
                  >
                    <>
                      {profileImage.length !== 0 ? (
                        <>
                          {profileImage.slice(0, 4).map((item, index) => {
                            return (
                              <React.Fragment key={index}>
                                {!item.bluezebra_user.profile_image ? (
                                  <Span $FontFormat={FontFormat.Body_MD}>
                                    -
                                  </Span>
                                ) : (
                                  <BluezebraImage
                                    src={item.bluezebra_user.profile_image}
                                    alt={item.bluezebra_user.name}
                                    $positionRelative={
                                      index !== 0 && "absolute"
                                    }
                                    $left={22 * (index - 1) + 22}
                                    $zIndex={profileImage.length - index}
                                  />
                                )}
                              </React.Fragment>
                            )
                          })}
                          {profileImage.length > 4 && (
                            <MoreBluezebra>
                              +{profileImage.length - 4}
                            </MoreBluezebra>
                          )}
                        </>
                      ) : (
                        <Span $FontFormat={FontFormat.Body_MD}>-</Span>
                      )}
                    </>
                  </FlexContainer>
                </FlexContainer>
              </>
            ) : (
              <>
                <FlexContainer $alignItems={"center"} $gap={"12px"}>
                  <P $FontFormat={FontFormat.Title_MD}>Client :</P>
                  {!clientImage ? (
                    <Avatar
                      src={clientImage}
                      alt={item.client_user.name}
                      style={{ width: "36px", height: "36px" }}
                    />
                  ) : (
                    <ClientImage
                      src={clientImage}
                      alt={item.client_user.name}
                    />
                  )}
                </FlexContainer>
                <P $FontFormat={FontFormat.Title_MD}> | </P>
                <P
                  $FontFormat={FontFormat.Title_MD}
                  $width={windowSize.width <= 1200 && "100%"}
                  $whiteSpace={windowSize.width <= 1200 && "nowrap"}
                  $overFlow={windowSize.width <= 1200 && "hidden"}
                  $textOverflow={windowSize.width <= 1200 && "ellipsis"}
                  $overflow={windowSize.width <= 1200 && "hidden"}
                >
                  Bluezebra:{" "}
                </P>
                <FlexContainer
                  $alignItems={"center"}
                  style={{ position: "relative" }}
                >
                  <>
                    {profileImage.length !== 0 ? (
                      <>
                        {profileImage.slice(0, 4).map((item, index) => {
                          return (
                            <React.Fragment key={index}>
                              {!item.bluezebra_user.profile_image ? (
                                <Span $FontFormat={FontFormat.Body_MD}>-</Span>
                              ) : (
                                <BluezebraImage
                                  src={item.bluezebra_user.profile_image}
                                  alt={item.bluezebra_user.name}
                                  $positionRelative={index !== 0 && "absolute"}
                                  $left={22 * (index - 1) + 22}
                                  $zIndex={profileImage.length - index}
                                />
                              )}
                            </React.Fragment>
                          )
                        })}
                        {profileImage.length > 4 && (
                          <MoreBluezebra>
                            +{profileImage.length - 4}
                          </MoreBluezebra>
                        )}
                      </>
                    ) : (
                      <Span $FontFormat={FontFormat.Body_MD}>-</Span>
                    )}
                  </>
                </FlexContainer>
              </>
            )}
            <P
              $FontFormat={FontFormat.Title_MD}
              $width={windowSize.width <= 1200 && "100%"}
              $whiteSpace={windowSize.width <= 1200 && "nowrap"}
              $overFlow={windowSize.width <= 1200 && "hidden"}
              $textOverflow={windowSize.width <= 1200 && "ellipsis"}
              $overflow={windowSize.width <= 1200 && "hidden"}
              style={{ marginLeft: windowSize.width > 1200 && "auto" }}
            >
              Updated:{" "}
              <Span $FontFormat={FontFormat.Body_MD}>{formattedDate}</Span>
            </P>
          </FlexContainer>
        </CarContainer>
      </FlexContainer>
    </LinkContainer>
  )
}
