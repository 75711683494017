import styled from "styled-components"

export const BackgroundContainer = styled.div`
  display: flex;
  width: 1400px;
  height: auto;
  padding: 40px 54px;
  margin: 0 90px 97px;
  align-items: flex-start;
  gap: 48px;
  border-radius: 16px 16px 0px 0px;
  background-color: #ecf0f3;
  @media screen and (max-width: 768px) {
    margin: 0 16px 97px;
    padding: 24px 32px;
    overflow: hidden;
  }
`