import styled from "styled-components"
import { Color, FontFormat } from "../../util"
import { Link } from "react-router-dom"

export const LinkContainer = styled(Link)`
  color: unset;
  text-decoration: unset;
`
export const CarContainer = styled.div`
  display: flex;
  padding: 24px;
  padding-left: ${(props) => props.$paddingLeft};
  flex-direction: column;
  gap: 8px;
  width: 100%;
  border-radius: 8px;
  background: ${Color.Secondary};
  border-left: ${(props) => props.$notification};
  @media screen and (max-width: 1200px) {
    gap: 2px;
    width: 90%;
  }
  @media screen and (max-width: 600px) {
    width: 100%;
    padding: 16px;
    overflow: hidden;
  }
`
export const StatusBox = styled.div`
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  color: ${(props) => props.$textColor};
  border: 1px solid;
  background: ${(props) => props.$background};
  white-space: nowrap;
`
export const ClientImage = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 36px;
  border: 1px solid ${Color.Secondary};
  object-fit: cover;
  object-position: center;
`
export const BluezebraImage = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 36px;
  border: 1px solid ${Color.Secondary};
  object-fit: cover;
  object-position: center;
  position: ${(props) => props.$positionRelative};
  ${(props) => props.$left !== undefined && `left: ${props.$left}px;`}
  z-index: ${(props) => props.$zIndex};
`
export const SubjectText = styled.span`
  font-size: ${FontFormat.Body_MD.fontSize};
  font-weight: ${FontFormat.Body_MD.fontWeight};
  color: ${Color.Primary};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`
export const MoreBluezebra = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Color.Secondary_Hover};
  width: 36px;
  height: 36px;
  border-radius: 36px;
  border: 1px solid #fafafa;
  position: absolute;
  left: 92px;
  z-index: 1;
`
