import React, { useState, useEffect } from "react"
import { userInfo } from "../../auth"
import { MagnifyingGlassIcon, PlusIcon } from "../../util/icons/Outline"
import {
  Menu,
  BackgroundBlueContainer,
  Button,
  Input,
  Select,
  Pagination,
  TicketCard,
  TicketSkeletonCard,
} from "../../components"
import { Color, Enum, WindowSize, FontFormat } from "../../util"
import { FlexContainer, H1, H2, P } from "../../style/main"
import {
  FilterBarContainer,
  CategoryListContainer,
  CreateButton,
} from "./styled"
import { getProductCategory, getBluezebraUser, searchTicket } from "../../api"

export const Tickets = () => {
  const [fetchedUser, setFetchedUser] = useState([])
  const [ticketList, setTicketList] = useState([])
  const [categoryList, setCategoryList] = useState([])
  const [supervisorLists, setSupervisorLists] = useState([])
  const [chooseProductCategory, setChooseProductCategory] = useState(0)
  const [chooseTap, setChooseTap] = useState("My_cases")
  const [searchQuery, setSearchQuery] = useState("")
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [selectedStatus, setSelectedStatus] = useState(
    Enum.SELECT_TICKET_STATUS.ALL
  )
  const [selectSupervisor, setSelectSupervisor] = useState(
    Enum.SELECT_SUPERVISOR.ALL
  )
  const [typeOfSupport, setTypeOfSupport] = useState(
    Enum.SELECT_ROLE_TICKET.ALL
  )
  const [selectSupervisorId, setSelectSupervisorId] = useState(
    Enum.SELECT_SUPERVISOR.ALL
  )
  const [loading, setLoading] = useState(false)
  const [isLoadingData, setIsLoadingData] = useState(false)
  const windowSize = WindowSize()
  const limit = 10

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const user = await userInfo()
        setFetchedUser(user)
        setLoading(false)
      } catch (e) {
        console.error(e)
        setLoading(false)
      }
    }

    fetchData()
  }, [])
  useEffect(() => {
    const fetchSupervisor = async () => {
      try {
        const supervisor = await getBluezebraUser()
        setSupervisorLists(supervisor)
      } catch (e) {
        console.error(e)
      }
    }
    fetchSupervisor()
  }, [])
  useEffect(() => {
    const fetchCategory = async () => {
      setIsLoadingData(true)
      try {
        const category = await getProductCategory()
        setCategoryList(category)
        const searchResponse = await searchTicket(
          searchQuery,
          selectedStatus,
          typeOfSupport,
          selectSupervisorId,
          chooseTap,
          fetchedUser.role,
          chooseProductCategory,
          fetchedUser.id,
          page,
          limit
        )
        setTicketList(searchResponse.data)
        setTotal(searchResponse.total)

        const totalPages = Math.ceil(searchResponse.total / limit);
        if (searchResponse.total < limit) {
          setPage(1);
        } else if (page > totalPages) {
          setPage(totalPages);
        }
      } catch (e) {
        console.error(e)
      } finally {
        setIsLoadingData(false);
      }
    }
    fetchCategory()
  }, [
    searchQuery,
    selectedStatus,
    typeOfSupport,
    selectSupervisorId,
    chooseTap,
    chooseProductCategory,
    fetchedUser,
    page,
    limit,
  ])
  const clearFilterStatus = () => {
    setSelectedStatus(Enum.SELECT_TICKET_STATUS.ALL)
    setSelectSupervisor(Enum.SELECT_SUPERVISOR.ALL)
    setTypeOfSupport(Enum.SELECT_ROLE_TICKET.ALL)
    setSelectSupervisorId(Enum.SELECT_SUPERVISOR.ALL)
  }
  const statusOption = [
    { value: 1, label: Enum.SELECT_TICKET_STATUS.ALL },
    { value: 2, label: Enum.SELECT_TICKET_STATUS.PENDING },
    { value: 3, label: Enum.SELECT_TICKET_STATUS.IN_PROGRESS },
    { value: 4, label: Enum.SELECT_TICKET_STATUS.CLOSED },
  ]
  const typeOfSupportOption = [
    { value: 1, label: Enum.SELECT_ROLE_TICKET.ALL },
    { value: 2, label: Enum.SELECT_ROLE_TICKET.MAIN },
    { value: 3, label: Enum.SELECT_ROLE_TICKET.SUPPORTER },
  ]
  const supervisorsOption = [
    { value: 1, id: 0, label: Enum.SELECT_SUPERVISOR.ALL },
    ...supervisorLists.map((item, index) => ({
      value: index + 2,
      id: item.id,
      label: item.name,
    })),
  ]
  const onPageChange = (e, newPage) => {
    setPage(newPage)
  }
  const onSelectChange = (value) => {
    const status = statusOption.find((option) => option.value === value)
    setSelectedStatus(status.label)
  }
  const onSelectSupervisorChange = (value) => {
    const status = supervisorsOption.find((option) => option.value === value)
    if (status.label === Enum.SELECT_SUPERVISOR.ALL) {
      setSelectSupervisorId(status.label)
    } else {
      setSelectSupervisorId(status.id)
    }
    setSelectSupervisor(status.label)
  }
  const onSelectTypeOfSupportChange = (value) => {
    const status = typeOfSupportOption.find((option) => option.value === value)
    setTypeOfSupport(status.label)
  }
  const handleFilterStatusOptions = (role) => {
    if (chooseTap === "My_cases" && role === Enum.SELECT_ROLE.ENGINEER) {
      return statusOption.filter(option => option.value !== 4);
    }
    return statusOption
  }
  const filteredStatusOptions = handleFilterStatusOptions(fetchedUser.role)

  return (
    <>
      <Menu />
      <BackgroundBlueContainer>
        <FlexContainer $justifyContent={"center"}>
          <Button
            label="My cases"
            color={chooseTap === "My_cases" ? Color.Secondary : Color.Primary}
            background={
              chooseTap === "My_cases" ? Color.Primary : Color.Secondary
            }
            width={"436px"}
            style={{
              borderRadius: "8px 0px 0px 8px",
            }}
            onClick={() => {
              setChooseTap("My_cases")
              clearFilterStatus()
            }}
          />
          <Button
            label={
              fetchedUser &&
                fetchedUser.role === Enum.SELECT_ROLE.HEADOF_ENGINEER
                ? "All cases"
                : "History"
            }
            color={
              chooseTap === "History" || chooseTap === "All_cases"
                ? Color.Secondary
                : Color.Primary
            }
            background={
              chooseTap === "History" || chooseTap === "All_cases"
                ? Color.Primary
                : Color.Secondary
            }
            width={"436px"}
            style={{
              borderRadius: "0px 8px 8px 0px",
            }}
            onClick={() => {
              setChooseTap(
                fetchedUser &&
                  fetchedUser.role === Enum.SELECT_ROLE.HEADOF_ENGINEER
                  ? "All_cases"
                  : "History"
              )
              clearFilterStatus()
            }}
          />
        </FlexContainer>
        <div style={{ padding: "40px 0" }}>
          <FilterBarContainer>
            <Input
              placeholder="Search..."
              icon={<MagnifyingGlassIcon width={24} height={24} />}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              width={windowSize.width <= 1200 ? "100%" : chooseTap === "History" ? "80%" : "65%"}
            />
            <FlexContainer
              $gap={"12px"}
              $width={windowSize.width <= 1200 ? "100%" : chooseTap === "History" ? "20%" : "35%"}
            >
              {chooseTap !== "History" && (
                <Select
                  onSelectChange={onSelectChange}
                  isShowDefaultOption={true}
                  optionsData={filteredStatusOptions}
                  value={selectedStatus}
                  width={"50%"}
                  height={"26px !important"}
                  label={"Status"}
                />
              )}
              {chooseTap === "My_cases" || chooseTap === "History" ? (
                <Select
                  onSelectChange={onSelectTypeOfSupportChange}
                  isShowDefaultOption={true}
                  optionsData={typeOfSupportOption}
                  value={typeOfSupport}
                  width={chooseTap === "History" ? "100%" : "50%"}
                  height={"26px !important"}
                  label={"Type of support"}
                />
              ) : (
                <Select
                  onSelectChange={onSelectSupervisorChange}
                  isShowDefaultOption={true}
                  optionsData={supervisorsOption}
                  value={selectSupervisor}
                  width={chooseTap === "History" ? "100%" : "50%"}
                  height={"26px !important"}
                  label={"Supervisor"}
                />
              )}
            </FlexContainer>
            <CreateButton to={"/tickets/create"}>
              <PlusIcon width={20} height={20} strokeWidth={2.7} /> Create
            </CreateButton>
          </FilterBarContainer>
          <FlexContainer
            $justifyContent={windowSize.width <= 1200 ? "flex-start" : "center"}
          >
            <CategoryListContainer
              $flexWrap={windowSize.width <= 1200 ? "nowrap" : "wrap"}
              $overflowX={"scroll"}
              $justifyContent={
                windowSize.width <= 1200 ? "flex-start" : "center"
              }
              $width={"941px"}
            >
              {categoryList.map((item, index) => {
                return (
                  <Button
                    key={index}
                    label={item.name}
                    fontWeight={400}
                    margin={"0 12px 12px"}
                    color={
                      chooseProductCategory === item.id
                        ? Color.Secondary
                        : Color.Primary
                    }
                    background={
                      chooseProductCategory === item.id
                        ? Color.Primary
                        : Color.Secondary
                    }
                    isBorder={true}
                    borderColor={Color.Primary}
                    onClick={() => setChooseProductCategory(item.id)}
                  />
                )
              })}
            </CategoryListContainer>
          </FlexContainer>
        </div>
        <H1 $FontFormat={FontFormat.Title_XL}>
          {chooseTap === "History"
            ? "History"
            : chooseTap === "All_cases"
              ? "All cases"
              : "My cases"}
          :
        </H1>
        {loading || isLoadingData ? (
          <>
            {Array.from({ length: 10 }).map((_, index) => (
              <TicketSkeletonCard key={index} />
            ))}
          </>
        ) : (
          <>
            {ticketList.length === 0 && !isLoadingData ? (
              <FlexContainer
                $alignItems={"center"}
                $flexDirection={"column"}
                style={{ marginTop: "40px" }}
              >
                <MagnifyingGlassIcon
                  width={64}
                  height={64}
                  style={{ position: "relative", zIndex: 2 }}
                />
                <H2
                  $fontSize={FontFormat.Title_XL.fontSize}
                  $fontWeight={FontFormat.Title_XL.fontWeight}
                >
                  No Items Found
                </H2>
                <P
                  $fontSize={FontFormat.Body_MD.fontSize}
                  $fontWeight={FontFormat.Body_MD.fontWeight}
                >
                  We can’t find any item matching your search.
                </P>
              </FlexContainer>
            ) : (
              <>
                {ticketList.map((item, index) => {
                  {/* const isRead = item.ticket_message.some(message => !message.is_read) */ }
                  return (
                    <TicketCard
                      key={index}
                      item={item}
                      // notification={isRead}
                      clientImage={item.client_user.logo_image}
                      profileImage={item.user_ticket}
                      userId={fetchedUser && fetchedUser.id}
                    />
                  )
                })}
                <Pagination
                  count={total}
                  page={page}
                  limit={limit}
                  onChange={onPageChange}
                />
              </>
            )}
          </>
        )}
      </BackgroundBlueContainer>
    </>
  )
}
