import { useContext } from "react"
import { MainRoutes } from "./routes/MainRoutes"
import { NavBar } from "./components"
import { ShowNavBarContext } from "./components/Context"
import { BrowserRouter } from "react-router-dom"

function App() {
  const { isShowNavBar } = useContext(ShowNavBarContext)
  return (
    <BrowserRouter>
      {isShowNavBar && (
          <NavBar />
      )}
      <MainRoutes />
    </BrowserRouter>
  )
}

export default App
