import click_bait from "./click_bait.svg"
import login from "./login_image.svg"
import logo_bluezebra_blue from "./logo_bluezebra_blue.svg"
import logo_bluezebra_white from "./logo_bluezebra_white.svg"
import no_product from "./no_product.svg"
import people from "./people.svg"
import ticket_detail from "./ticket_detail.svg"
import background_error from "./background_error.svg"

export {
  click_bait,
  login,
  logo_bluezebra_blue,
  logo_bluezebra_white,
  no_product,
  people,
  ticket_detail,
  background_error,
}
