import { Enum } from "../util"
import { Axios } from "../util/Axios"

const basedURL = process.env.REACT_APP_API
const accessToken = localStorage.getItem("accessToken")

// BluezebraUser
export const getBluezebraUser = async () => {
  try {
    const response = await Axios(
      Enum.CONTENT_TYPE.JSON,
      Enum.METHOD.GET,
      `${basedURL}bluezebraUser`,
      accessToken,
      null,
      null
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}
export const getDefaultImageBluezebraUser = async () => {
  try {
    const response = await Axios(
      Enum.CONTENT_TYPE.JSON,
      Enum.METHOD.GET,
      `${basedURL}bluezebraUser/default-image`,
      accessToken,
      null,
      null
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}
export const getBluezebraUserForSupporters = async () => {
  try {
    const response = await Axios(
      Enum.CONTENT_TYPE.JSON,
      Enum.METHOD.GET,
      `${basedURL}bluezebraUser/supporter`,
      accessToken,
      null,
      null
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}
export const getBluezebraUserId = async (userId) => {
  try {
    const response = await Axios(
      Enum.CONTENT_TYPE.JSON,
      Enum.METHOD.GET,
      `${basedURL}bluezebraUser/${userId}`,
      accessToken,
      null
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}
export const getBluezebraUserByFirebaseId = async (firebaseId) => {
  try {
    const response = await Axios(
      Enum.CONTENT_TYPE.FORM_DATA,
      Enum.METHOD.GET,
      `${basedURL}bluezebraUser/firebase/${firebaseId}`,
      accessToken,
      null
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}
export const searchBluezebraUser = async (
  search,
  selectedStatus,
  page,
  limit
) => {
  try {
    const response = await Axios(
      Enum.CONTENT_TYPE.JSON,
      Enum.METHOD.GET,
      `${basedURL}bluezebraUser/search`,
      accessToken,
      { search: search, status: selectedStatus, page: page, limit: limit },
      null
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}
export const createBluezebraUser = async (body) => {
  try {
    const response = await Axios(
      Enum.CONTENT_TYPE.FORM_DATA,
      Enum.METHOD.POST,
      `${basedURL}bluezebraUser/create`,
      accessToken,
      null,
      body
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}
export const updateBluezebraUserById = async (userId, body) => {
  try {
    const response = await Axios(
      Enum.CONTENT_TYPE.FORM_DATA,
      Enum.METHOD.PUT,
      `${basedURL}bluezebraUser/update/${userId}`,
      accessToken,
      null,
      body
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}
export const updateIsActiveBluezebraUserById = async (userId, body) => {
  try {
    const response = await Axios(
      Enum.CONTENT_TYPE.JSON,
      Enum.METHOD.PUT,
      `${basedURL}bluezebraUser/update/isActive/${userId}`,
      accessToken,
      null,
      body
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

// Product
export const searchProduct = async (
  search,
  selectedStatus,
  chooseProductCategory,
  selectedSupervisorId,
  page,
  limit
) => {
  try {
    const response = await Axios(
      Enum.CONTENT_TYPE.JSON,
      Enum.METHOD.GET,
      `${basedURL}product/search`,
      accessToken,
      {
        search: search,
        status: selectedStatus,
        categoryId: chooseProductCategory,
        supervisorId: selectedSupervisorId,
        page: page,
        limit: limit,
      },
      null
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}
export const getProduct = async () => {
  try {
    const response = await Axios(
      Enum.CONTENT_TYPE.JSON,
      Enum.METHOD.GET,
      `${basedURL}product`,
      accessToken,
      null
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}
export const getProductByClientId = async (clientId) => {
  try {
    const response = await Axios(
      Enum.CONTENT_TYPE.JSON,
      Enum.METHOD.GET,
      `${basedURL}product/client/${clientId}`,
      accessToken,
      null
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}
export const getProductById = async (productId) => {
  try {
    const response = await Axios(
      Enum.CONTENT_TYPE.JSON,
      Enum.METHOD.GET,
      `${basedURL}product/${productId}`,
      accessToken,
      null
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}
export const updateIsActiveProductById = async (productId, body) => {
  try {
    const response = await Axios(
      Enum.CONTENT_TYPE.JSON,
      Enum.METHOD.PUT,
      `${basedURL}product/update/isActive/${productId}`,
      accessToken,
      null,
      body
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

// ProductCategory
export const getProductCategory = async () => {
  try {
    const response = await Axios(
      Enum.CONTENT_TYPE.JSON,
      Enum.METHOD.GET,
      `${basedURL}productCategory`,
      accessToken,
      null,
      null
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

// SupervisorProduct
export const getSupervisor = async () => {
  try {
    const response = await Axios(
      Enum.CONTENT_TYPE.JSON,
      Enum.METHOD.GET,
      `${basedURL}supervisor/product`,
      accessToken,
      null,
      null
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}
export const createSupervisor = async (body) => {
  try {
    const response = await Axios(
      Enum.CONTENT_TYPE.JSON,
      Enum.METHOD.POST,
      `${basedURL}supervisor/product/create`,
      accessToken,
      null,
      body
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}
export const updateSupervisor = async (body) => {
  try {
    const response = await Axios(
      Enum.CONTENT_TYPE.JSON,
      Enum.METHOD.PUT,
      `${basedURL}supervisor/product/update/${body.productId}`,
      accessToken,
      null,
      body
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

// ClientUser
export const getClientUser = async () => {
  try {
    const response = await Axios(
      Enum.CONTENT_TYPE.JSON,
      Enum.METHOD.GET,
      `${basedURL}clientUser`,
      accessToken,
      null,
      null
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}
export const getClientUserById = async (clientId) => {
  try {
    const response = await Axios(
      Enum.CONTENT_TYPE.JSON,
      Enum.METHOD.GET,
      `${basedURL}clientUser/${clientId}`,
      accessToken,
      null
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}
export const searchClientUser = async (search, selectedStatus, page, limit) => {
  try {
    const response = await Axios(
      Enum.CONTENT_TYPE.JSON,
      Enum.METHOD.GET,
      `${basedURL}clientUser/search`,
      accessToken,
      { search: search, status: selectedStatus, page: page, limit: limit },
      null
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}
export const createClientUser = async (body) => {
  try {
    const response = await Axios(
      Enum.CONTENT_TYPE.JSON,
      Enum.METHOD.POST,
      `${basedURL}clientUser/create`,
      accessToken,
      null,
      body
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}
export const updateIsActiveClientById = async (clientId, body) => {
  try {
    const response = await Axios(
      Enum.CONTENT_TYPE.JSON,
      Enum.METHOD.PUT,
      `${basedURL}clientUser/update/isActive/${clientId}`,
      accessToken,
      null,
      body
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

// VerifiedClientUser
export const createVerifiedClientUser = async (body) => {
  try {
    const response = await Axios(
      Enum.CONTENT_TYPE.JSON,
      Enum.METHOD.POST,
      `${basedURL}verifiedClientUser/create`,
      accessToken,
      null,
      body
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

// PurchasedProduct
export const getPurchasedProductByClientId = async (clientId) => {
  try {
    const response = await Axios(
      Enum.CONTENT_TYPE.JSON,
      Enum.METHOD.GET,
      `${basedURL}purchasedProduct/${clientId}`,
      accessToken,
      null
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}
export const createPurchasedProduct = async (body) => {
  try {
    const response = await Axios(
      Enum.CONTENT_TYPE.JSON,
      Enum.METHOD.POST,
      `${basedURL}purchasedProduct/create`,
      accessToken,
      null,
      body
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}
export const updatePurchasedProductById = async (id, body) => {
  try {
    const response = await Axios(
      Enum.CONTENT_TYPE.JSON,
      Enum.METHOD.PUT,
      `${basedURL}purchasedProduct/update/${id}`,
      accessToken,
      null,
      body
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}
export const updateIsActivePurchasedProductById = async (productId, body) => {
  try {
    const response = await Axios(
      Enum.CONTENT_TYPE.JSON,
      Enum.METHOD.PUT,
      `${basedURL}purchasedProduct/update/isActive/${productId}`,
      accessToken,
      null,
      body
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

// Permissions
export const getPermission = async () => {
  try {
    const response = await Axios(
      Enum.CONTENT_TYPE.JSON,
      Enum.METHOD.GET,
      `${basedURL}permission`,
      accessToken,
      null
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}
export const getPermissionById = async (permissionId) => {
  try {
    const response = await Axios(
      Enum.CONTENT_TYPE.JSON,
      Enum.METHOD.GET,
      `${basedURL}permission/permissionIds`,
      accessToken,
      { permissionId: permissionId }
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

// Ticket
export const getTicketReferCaseByClientId = async (clientId) => {
  try {
    const response = await Axios(
      Enum.CONTENT_TYPE.JSON,
      Enum.METHOD.GET,
      `${basedURL}ticket/referCase/${clientId}`,
      accessToken,
      null
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}
export const getTicketById = async (ticketId) => {
  try {
    const response = await Axios(
      Enum.CONTENT_TYPE.JSON,
      Enum.METHOD.GET,
      `${basedURL}ticket/${ticketId}`,
      accessToken,
      null
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}
export const searchTicket = async (
  search,
  selectedStatus,
  typeOfSupport,
  selectSupervisorId,
  chooseTap,
  role,
  chooseProductCategory,
  userId,
  page,
  limit
) => {
  try {
    const response = await Axios(
      Enum.CONTENT_TYPE.JSON,
      Enum.METHOD.GET,
      `${basedURL}ticket`,
      accessToken,
      {
        search: search,
        status: selectedStatus,
        typeOfSupport: typeOfSupport,
        supervisor: selectSupervisorId,
        tap: chooseTap,
        role: role,
        categoryId: chooseProductCategory,
        userId: userId,
        page: page,
        limit: limit,
      },
      null
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}
export const createTicket = async (body) => {
  try {
    const response = await Axios(
      Enum.CONTENT_TYPE.FORM_DATA,
      Enum.METHOD.POST,
      `${basedURL}ticket/create`,
      accessToken,
      null,
      body
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}
export const updateStatusTicket = async (ticketId, body) => {
  try {
    const response = await Axios(
      Enum.CONTENT_TYPE.JSON,
      Enum.METHOD.PUT,
      `${basedURL}ticket/updateStatus/${ticketId}`,
      accessToken,
      null,
      body
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}
export const updateStatusTicketAndAddMain = async (ticketId, body) => {
  try {
    const response = await Axios(
      Enum.CONTENT_TYPE.JSON,
      Enum.METHOD.PUT,
      `${basedURL}ticket/update/${ticketId}`,
      accessToken,
      null,
      body
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}
export const deleteTicketById = async (ticketId) => {
  try {
    const response = await Axios(
      Enum.CONTENT_TYPE.JSON,
      Enum.METHOD.DELETE,
      `${basedURL}ticket/delete/${ticketId}`,
      accessToken,
      null,
      undefined
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

// UserTicket
export const getUserTicketByTicketId = async (ticketId) => {
  try {
    const response = await Axios(
      Enum.CONTENT_TYPE.JSON,
      Enum.METHOD.GET,
      `${basedURL}userTicket/${ticketId}`,
      accessToken,
      null,
      null
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}
export const getOptionUserTicketByTicketId = async (ticketId) => {
  try {
    const response = await Axios(
      Enum.CONTENT_TYPE.JSON,
      Enum.METHOD.GET,
      `${basedURL}userTicket/option/${ticketId}`,
      accessToken,
      null,
      null
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}
export const createUserTicket = async (body) => {
  try {
    const response = await Axios(
      Enum.CONTENT_TYPE.JSON,
      Enum.METHOD.POST,
      `${basedURL}userTicket/create`,
      accessToken,
      null,
      body
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

// TicketMessage
export const getTicketMessageById = async (ticketId) => {
  try {
    const response = await Axios(
      Enum.CONTENT_TYPE.JSON,
      Enum.METHOD.GET,
      `${basedURL}ticketMessage/${ticketId}`,
      accessToken,
      null,
      null
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}
export const createTicketMessage = async (body) => {
  try {
    const response = await Axios(
      Enum.CONTENT_TYPE.FORM_DATA,
      Enum.METHOD.POST,
      `${basedURL}ticketMessage/create`,
      accessToken,
      null,
      body
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}
