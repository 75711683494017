import styled from "styled-components"
import { Link } from "react-router-dom"
import { Color, FontFormat } from "../../util"

export const FilterBarContainer = styled.div`
  display: flex;
  gap: 12px;
  padding-top: 24px;
  padding-bottom: 24px;
  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
`
export const CategoryListContainer = styled.div`
  display: flex;
  flex-wrap: ${(props) => props.$flexWrap};
  flex-direction: ${(props) => props.$flexDirection};
  justify-content: ${(props) => props.$justifyContent};
  align-items: ${(props) => props.$alignItems};
  gap: ${(props) => props.$gap};
  width: ${(props) => props.$width};
  @media screen and (max-width: 1200px) {
    overflow-x: ${(props) => props.$overflowX};
  }
`
export const CreateButton = styled(Link)`
  font-size: ${FontFormat.Title_MD.fontSize};
  font-weight: ${FontFormat.Title_MD.fontWeight};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  border-radius: 8px;
  text-decoration: none;
  gap: 8px;
  color: ${Color.Secondary};
  background-color: ${Color.Primary};
  &:hover {
    background-color: ${Color.Primary_Hover};
  }
  &:active,
  &:focus {
    background-color: ${Color.Primary_Pressed};
  }
  @media only screen and (max-width: 1200px) {
    width: auto;
  }
`
export const NextButton = styled(Link)`
  font-size: ${FontFormat.Title_MD.fontSize};
  font-weight: ${FontFormat.Title_MD.fontWeight};
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  border-radius: 8px;
  text-decoration: none;
  gap: 8px;
  color: ${Color.Secondary};
  border: 1px solid ${Color.Primary};
  background-color: ${Color.Primary};
  &:hover {
    background-color: ${Color.Primary_Hover};
  }
  &:active,
  &:focus {
    background-color: ${Color.Primary_Pressed};
  }
  @media screen and (max-width: 479px) {
    width: 50%;
  }
  @media screen and (max-width: 299px) {
    width: auto;
  }
`
export const PreviousButton = styled(Link)`
  font-size: ${FontFormat.Title_MD.fontSize};
  font-weight: ${FontFormat.Title_MD.fontWeight};
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  border-radius: 8px;
  text-decoration: none;
  gap: 8px;
  border: 1px solid ${Color.Primary};
  color: ${Color.Primary};
  background-color: ${Color.Secondary};
  &:hover {
    background-color: ${Color.Secondary_Hover};
  }
  @media screen and (max-width: 479px) {
    width: 50%;
  }
  @media screen and (max-width: 299px) {
    width: auto;
  }
`
export const Circle = styled.div`
  display: flex;
  width: 47px;
  height: 47px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 47px;
  background-color: ${Color.Secondary};
  @media screen and (max-width: 600px) {
    width: 25px;
    height: 25px;
  }
`
export const Line = styled.div`
  width: 13%;
  height: 1px;
  background-color: #000;
  margin-top: 33px;
  @media screen and (max-width: 600px) {
    margin-top: 22px;
  }
`
export const DescriptionBox = styled.textarea`
  font-size: ${FontFormat.Body_MD.fontSize};
  font-weight: ${FontFormat.Body_MD.fontWeight};
  width: 100%;
  height: 220px;
  padding: 12px;
  border-radius: 6px;
  border: 1px solid ${(props) => props.$borderColor};
  background-color: ${Color.Secondary};
  &:active,
  &:focus {
    border: 1px solid ${Color.Primary_Pressed};
  }
  &:focus-visible {
    outline-color: ${Color.Primary};
  }
`
export const PreviewFilesBox = styled.div`
  display: flex !important;
  align-items: center;
  gap: 12px;
  width: 200px !important;
  padding: 10px 16px;
  border-radius: 8px;
  border: 1px solid ${Color.Primary};
  background-color: ${Color.Secondary};
`
export const Description = styled.p`
  font-size: ${FontFormat.Body_MD.fontSize};
  font-weight: ${FontFormat.Body_MD.fontWeight};
  line-height: 150%;
  max-height: ${(props) => props.$maxHeight};
  overflow: hidden;
  position: relative;
`
export const SeeMoreText = styled.p`
  margin: 0;
  cursor: pointer;
  color: ${Color.Tertiary};
  font-size: ${FontFormat.Title_MD.fontSize};
  font-weight: ${FontFormat.Title_MD.fontWeight};
  &:hover {
    color: ${Color.Tertiary_Hover};
  }
  &:active,
  &:focus {
    color: ${Color.Tertiary_Pressed};
  }
`
export const PopupTitle = styled.p`
  padding: 48px 40px 40px 40px;
  font-size: ${FontFormat.Title_SM.fontSize};
  font-weight: ${FontFormat.Title_SM.fontWeight};
  text-align: center;
`
export const ClosePopupButton = styled(Link)`
  display: flex;
  padding: 10px 0px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  text-decoration: none;
  color: ${Color.Secondary};
  border: none;
  border-radius: 0 0 10px 10px;
  background-color: ${Color.Success};
  cursor: pointer;
  &: hover {
    background-color: ${Color.Success_Hover};
  }
`
export const Image = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 36px;
  border: 1px solid ${Color.Secondary};
  object-fit: cover;
  object-position: center;
`
