import React from "react"
import { ShowNavBarProvider } from "./index"

export const MainContext = ({ children }) => {
  return (
    <ShowNavBarProvider>
     {children}
    </ShowNavBarProvider>
  )
}
