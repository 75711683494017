import styled from "styled-components"
import { Color, FontFormat } from "../../util"
import { Link } from "react-router-dom"

export const Container = styled.div`
  background-color: #ecf0f3;
  padding-top: 54px;
`
export const MenuContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1500px;
  margin: 0 90px;
  overflow: hidden;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin: 0 16px;
    padding-bottom: 12px;
    position: relative;
  }
  @media screen and (max-width: 657px) {
    justify-content: center;
  }
`
export const BackButton = styled(Link)`
  @media screen and (max-width: 657px) {
    position: absolute;
    left: 0;
  }
`
export const HeadContainer = styled.div`
  display: flex;
  gap: 16px;
  width: 1460px;
  height: auto;
  padding: 20px;
  margin: 0 90px 30px;
  border-radius: 16px;
  background-color: #b3dffe;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    margin: 0 32px 30px;
    padding: 30px;
  }
  @media screen and (max-width: 768px) {
    margin: 0 16px 30px;
    padding: 16px;
  }
`
export const BodyContainer = styled.div`
  border-radius: 16px;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.15);
  position: relative;
  width: 1460px;
  max-width: 1460px;
  padding: 20px;
  margin: 0 90px 97px;
  background-color: ${Color.Secondary};
  overflow: hidden;
  @media screen and (max-width: 1024px) {
    margin: 0 32px 30px;
    padding: 16px;
  }
  @media screen and (max-width: 768px) {
    margin: 0 16px 30px;
    padding: 0;
  }
`
export const DateContainer = styled.div`
  display: flex;
  width: 30%;
  height: 167px;
  padding: 16px;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  border-radius: 16px;
  background-color: ${(props) => props.$backgroundColor};
  @media screen and (max-width: 1300px) {
    width: 40%;
    height: 180px;
  }
  @media screen and (max-width: 1024px) {
    width: 90%;
    height: 167px;
    margin: auto;
    align-items: unset;
    margin-top: 12px;
    margin-bottom: 12px;
  }
  @media screen and (max-width: 657px) {
    margin-top: 24px;
    margin-bottom: 24px;
  }
`
export const DetailContainer = styled.div`
  width: 70%;
  @media screen and (max-width: 1300px) {
    width: 60%;
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`
export const StatusBox = styled.div`
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  color: ${(props) => props.$textColor};
  border: 1px solid;
  background: ${(props) => props.$background};
  white-space: nowrap;
`
export const ClientImage = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 36px;
  border: 1px solid ${Color.Secondary};
  object-fit: cover;
  object-position: center;
`
export const ReferCaseText = styled(Link)`
  font-size: ${FontFormat.Body_MD.fontSize};
  font-weight: ${FontFormat.Body_MD.fontWeight};
  color: #000;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    text-decoration-color: ${Color.Tertiary_Hover};
  }
`
export const Description = styled.p`
  font-size: ${FontFormat.Body_MD.fontSize};
  font-weight: ${FontFormat.Body_MD.fontWeight};
  line-height: 150%;
  max-height: ${(props) => props.$maxHeight};
  position: relative;
  width: auto;
  overflow: hidden;
  text-overflow: ${(props) => (props.expanded ? "unset" : "ellipsis")};
`
export const SeeMoreText = styled.p`
  margin: 0;
  cursor: pointer;
  color: ${Color.Tertiary};
  font-size: ${FontFormat.Title_MD.fontSize};
  font-weight: ${FontFormat.Title_MD.fontWeight};
  &:hover {
    color: ${Color.Tertiary_Hover};
  }
  &:active,
  &:focus {
    color: ${Color.Tertiary_Pressed};
  }
`
export const PopupButton = styled.button`
  display: flex;
  padding: 10px 0px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  color: ${(props) => props.$lableColor};
  border: none;
  border-radius: ${(props) => props.$borderRadius};
  background-color: ${(props) => props.$backgroundColor};
  cursor: pointer;
  &: hover {
    background-color: ${(props) => props.$backgroundColorHover};
  }
`
export const SupervisorsBox = styled.div`
  display: flex !important;
  justify-content: center;
  align-items: center;
  gap: 12px;
  width: 250px;
`
export const LineContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 12px;
  padding-bottom: 24px;
`
export const Line = styled.div`
  border-radius: 16px;
  background-color: ${Color.Disabled};
  width: 100%;
  height: 1px;
`
export const DropFile = styled.button`
  background: unset;
  border: unset;
  color: ${Color.Disabled};
  font-size: ${FontFormat.Body_MD.fontSize};
  font-weight: ${FontFormat.Body_MD.fontWeight};
  line-height: 150%;
  cursor: pointer;
  text-align: center;
  width: 100%;
`
export const MessageContainer = styled.div`
  border-radius: 16px;
  max-height: 900px;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
`
export const MessageCard = styled.div`
  padding: 16px;
  background-color: ${(props) => props.$backgroundColor};
  border: 4px solid ${Color.Secondary};
`
export const MessageImageUser = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 48px;
  border: 1px solid ${Color.Secondary};
  object-fit: cover;
  object-position: center;
`
export const MessageNameUser = styled.p`
  font-size: ${FontFormat.Title_MD.fontSize};
  font-weight: ${FontFormat.Title_MD.fontWeight};
  color: ${Color.Tertiary};
  white-space: nowrap;
`
export const MessageText = styled.p`
  font-size: ${FontFormat.Body_MD.fontSize};
  font-weight: ${FontFormat.Body_MD.fontWeight};
  color: ${Color.Tertiary};
  margin-top: 12px;
`
export const ApproveCaseContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  width: 100%;
  position: absolute;
  top: 10%;
  z-index: 1;
`
export const TextEditorContainer = styled.div`
  filter: ${(props) => props.$filter};
  pointer-events: ${(props) => props.$pointerEvents};
`
export const PreviewImageFile = styled.img`
  width: 200px;
  height: 80px;
  border-radius: 8px;
  object-fit: cover;
  object-position: center;
`
export const PreviewFilesBox = styled.div`
  display: flex !important;
  align-items: center;
  gap: 12px;
  width: 200px !important;
  padding: 10px 16px;
  border-radius: 8px;
  border: 1px solid ${Color.Primary};
  background-color: ${Color.Secondary};
`
export const AddButton = styled.div`
  display: flex;
  align-items: center;
  padding: 4px;
  border-radius: 4px;
  background-color: ${Color.Light_Blue};
  cursor: pointer;
`
export const PreviewImageInMassage = styled.img`
  width: 190px;
  height: 190px;
  border-radius: 8px;
  object-fit: cover;
  object-position: center;
`
export const FilesBox = styled.a`
  font-size: ${FontFormat.Title_MD.fontSize};
  font-weight: ${FontFormat.Title_MD.fontWeight};
  color: ${Color.Primary};
  white-space: nowrap;
  width: 180px;
  text-overflow: ellipsis;
  overflow: hidden;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    text-decoration-color: ${Color.Primary};
  }
`
export const MessageFilesContainer = styled.div`
  display: flex !important;
  align-items: center;
  width: 200px !important;
  padding: 10px;
  border-radius: 8px;
  gap: 8px;
  border: 1px solid ${Color.Primary};
  background-color: ${Color.Secondary};
  @media screen and (max-width: 480px ) {
    width: 70% !important;
  }
`
export const FilesContainer = styled.div`
  display: flex !important;
  align-items: center;
  width: 180px !important;
  padding: 10px 16px;
  border-radius: 8px;
  border: 1px solid ${Color.Primary};
  background-color: ${Color.Secondary};
  @media screen and (max-width: 480px ) {
    width: 50% !important;
  }
`
export const PopupShowImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  width: 100%;
  height: 100vh;
  padding: ${(props) => props.$padding};
  border-radius: 16px;
  gap: ${(props) => props.$gap};
  @media screen and (max-width: 600px) {
    width: 70%;
    overflow: hidden;
  }
`
export const SliderBluezebraContainer = styled.div`
  padding: 12px 0;
  position: relative;
  width: 750px;
  max-width: 750px;
  @media screen and (max-width: 1600px) {
    width: 700px;
  }
  @media screen and (max-width: 1500px) and (min-width: 1301px) {
    width: 500px;
  }
  @media screen and (max-width: 1300px) and (min-width: 1025px) {
    width: 250px;
  }
  @media screen and (max-width: 996px) {
    width: 500px;
  }
  @media screen and (max-width: 768px) {
    width: 250px;
  }
`
export const SliderContainer = styled.div`
  padding: 12px 0;
  position: relative;
  width: 1000px;
  @media screen and (max-width: 1400px) {
    width: 750px;
  }
  @media screen and (max-width: 996px) {
    width: 500px;
  }
  @media screen and (max-width: 768px) {
    width: 250px;
  }
  @media screen and (max-width: 480px) {
    width: 150px;
  }
`
export const PreviewImage = styled.img`
  width: 523px;
  height: 523px;
  border-radius: 8px;
  object-fit: cover;
  object-position: center;
  padding: 0 60px;
  @media screen and (max-width: 768px) {
    width: 300px;
    height: auto;
    padding: 0 16px;
  }
`
export const OverlayContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  backdrop-filter: blur(6px);
  background: rgba(0, 0, 0, 0.25);
`
