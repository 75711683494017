import styled from "styled-components"
import { Color, FontFormat } from "../../util"

export const PopupContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  height: auto;
  padding: ${(props) => props.$padding};
  border-radius: 16px;
  background-color: ${Color.Secondary};
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.$gap};
`
export const PopupTitleActive = styled.p`
  padding: 48px 40px 40px 40px;
  font-size: ${FontFormat.Title_SM.fontSize};
  font-weight: ${FontFormat.Title_SM.fontWeight};
`
export const PopupButton = styled.button`
  display: flex;
  padding: 10px 0px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  color: ${(props) => props.$lableColor};
  border: none;
  border-radius: ${(props) => props.$borderRadius};
  background-color: ${(props) => props.$backgroundColor};
  cursor: pointer;
  &: hover {
    background-color: ${(props) => props.$backgroundColorHover};
  }
`