import styled from "styled-components"
import { Color, FontFormat } from "../../util"

const MoreOptionCalculateLeft = (props, maxItems) => {
  return props.$lastItem % maxItems === 0 ? "unset" : "275px";
}
const MoreOptionCalculateRight = (props, maxItems) => {
  return props.$lastItem % maxItems === 0 ? "10px" : "unset";
}
export const Text = styled.p`
  font-size: ${(props) => props.$fontSize};
  font-weight: ${(props) => props.$fontWeight};
`
export const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${(props) => props.$justifyContent};
  gap: ${(props) => props.$gap};
  @media screen and (max-width: 769px) {
    justify-content: center;
  }
`
export const FilterBarContainer = styled.div`
  display: flex;
  gap: 12px;
  padding-top: 24px;
  padding-bottom: 24px;
  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
`
export const FlexContainer = styled.div`
  display: flex;
  flex-wrap: ${(props) => props.$flexWrap};
  flex-direction: ${(props) => props.$flexDirection};
  justify-content: ${(props) => props.$justifyContent};
  align-items: ${(props) => props.$alignItems};
  gap: ${(props) => props.$gap};
  width: ${(props) => props.$width};
  @media screen and (max-width: 1200px) {
    overflow-x: ${(props) => props.$overflowX};
  }
`
export const MoreOptionContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  border: 1px solid ${Color.Disabled};
  background: ${Color.Secondary};
  position: absolute;
  top: ${(props) => {
    if (props.$countProduct >= 9 && [9, 10, 11, 12].includes(props.$lastItem)) {
      return "185px"
    } else if (
      props.$countProduct >= 5 &&
      [5, 6, 7, 8].includes(props.$lastItem)
    ) {
      return "185px"
    } else {
      return "314px"
    }
  }};
  left: ${(props) => MoreOptionCalculateLeft(props, 4)};
  right: ${(props) => MoreOptionCalculateRight(props, 4)};
  z-index: 3;
  @media screen and (max-width: 1444px) {
    top: ${(props) => {
      if (props.$countProduct >= 10 && [10, 11, 12].includes(props.$lastItem)) {
        return "185px"
      } else if (
        props.$countProduct >= 7 &&
        [7, 8, 9].includes(props.$lastItem)
      ) {
        return "185px"
      } else {
        return "314px"
      }
    }};
    left: ${(props) => MoreOptionCalculateLeft(props, 3)};
    right: ${(props) => MoreOptionCalculateRight(props, 3)};
  }
  @media screen and (max-width: 1111px) {
    top: ${(props) => {
      if (props.$countProduct >= 11 && [11, 12].includes(props.$lastItem)) {
        return "185px"
      } else if (props.$countProduct >= 9 &&[9, 10].includes(props.$lastItem)) {
        return "185px"
      } else if (props.$countProduct >= 7 && [7, 8].includes(props.$lastItem)) {
        return "185px"
      } else {
        return "314px"
      }
    }};
    left: ${(props) => MoreOptionCalculateLeft(props, 2)};
    right: ${(props) => MoreOptionCalculateRight(props, 2)};
  }
  @media screen and (max-width: 657px) {
    left: unset;
    right: 0px;
  }
`
export const MoreOptionList = styled.button`
  font-size: ${FontFormat.Body_MD.fontSize};
  font-weight: ${FontFormat.Body_MD.fontWeight};
  display: flex;
  width: 209px;
  padding: 10px 12px;
  align-items: center;
  gap: 8px;
  border-radius: ${(props) =>
    props.$position === "top"
      ? "8px 8px 0px 0px"
      : props.$position === "bottom"
      ? "0px 0px 8px 8px"
      : props.$position === "oneOption"
      ? "8px"
      : "0"};
  background: ${Color.Secondary};
  border: none;
  cursor: pointer;
  &: hover {
    background: ${Color.Secondary_Hover};
  }
`
export const OverlayContainer = styled.div`
  position: fixed;
  top: ${(props) => props.$top && `blur(3px)`};
  left: ${(props) => props.$left && `blur(3px)`};
  width: 100%;
  height: 100%;
  z-index: 999;
  backdrop-filter: ${(props) => props.$isBlur && `blur(3px)`};
  background: ${(props) => props.$isBlur && `rgba(0, 0, 0, 0.25)`};
`
export const PopupContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  height: auto;
  padding: ${(props) => props.$padding};
  border-radius: 16px;
  background-color: ${Color.Secondary};
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.$gap};
  overflow: hidden;
`
export const PopupTitle = styled.p`
  color: #000;
  font-size: ${(props) => props.$fontSize};
  font-weight: ${(props) => props.$fontWeight};
`
export const PopupTitleActive = styled.p`
  padding: 48px 40px 40px 40px;
  font-size: ${FontFormat.Title_SM.fontSize};
  font-weight: ${FontFormat.Title_SM.fontWeight};
`
export const PopupButton = styled.button`
  display: flex;
  padding: 10px 0px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  color: ${(props) => props.$lableColor};
  border: none;
  border-radius: ${(props) => props.$borderRadius};
  background-color: ${(props) => props.$backgroundColor};
  cursor: pointer;
  &: hover {
    background-color: ${(props) => props.$backgroundColorHover};
  }
`
export const PopupProductContainer = styled.div`
  display: flex;
  padding: 12px;
  align-items: flex-start;
  gap: 12px;
  border-radius: 8px;
  background-color: ${Color.Light_Blue};
  @media screen and (max-width: 768px) {
    gap: 12px;
    padding-left: 16px;
    padding-right: 16px;
  }
  @media screen and (max-width: 600px) {
    flex-direction: column;
    overflow: hidden;
  }
`
export const PopupProductImage = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 8px;
  border: 1px dashed ${Color.Disabled};
`
export const Sticker = styled.img`
  position: absolute;
  right: 33px;
  top: 145px;
`
