import React from "react"
import { ChevronLeftIcon, ChevronRightIcon } from "../../util/icons/Outline"
import { Color, WindowSize } from "../../util"
import { FlexContainer } from "../../style/main"
import SliderSlick from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./styled.css"

export const Slider = ({
  className,
  data,
  slidesToShow,
  slidesToScroll,
  children,
  style,
}) => {
  const windowSize = WindowSize()
  const NextArrow = (props) => {
    const { className, onClick } = props
    return (
      <>
        {windowSize.width > 657 && (
          <ChevronRightIcon
            className={className}
            width={24}
            height={24}
            onClick={onClick}
            style={{
              color: Color.Tertiary,
              zIndex: 1,
            }}
          />
        )}
      </>
    )
  }
  const PrevArrow = (props) => {
    const { className, onClick } = props
    return (
      <>
        {windowSize.width > 657 && (
          <ChevronLeftIcon
            className={className}
            width={24}
            height={24}
            onClick={onClick}
            style={{
              color: Color.Tertiary,
              zIndex: 1,
            }}
          />
        )}
      </>
    )
  }
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToScroll,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      className === "slick-create-ticket" && {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      className === "slick-create-ticket" && {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      className === "slick-create-ticket" && {
        breakpoint: 996,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      className === "slick-ticket-detail" && {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      className === "slick-ticket-message-file" ||
        ("slick-ticket-message-image" && {
          breakpoint: 1400,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
          },
        }),
      className === "slick-ticket-message-file" ||
        ("slick-ticket-message-image" && {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        }),
      className === "slick-ticket-message-file" ||
        ("slick-ticket-message-image" && {
          breakpoint: 996,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        }),
      className === "slick-ticket-message-file" ||
        ("slick-ticket-message-image" && {
          breakpoint: 657,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        }),
      className === "slick-ticket-detail" ||
        (className === "slick-ticket-detail-bluezebra" && {
          breakpoint: 1400,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        }),
      className === "slick-ticket-detail" ||
        (className === "slick-ticket-detail-bluezebra" && {
          breakpoint: 1300,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        }),
      className === "slick-ticket-detail" ||
        (className === "slick-ticket-detail-bluezebra" && {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        }),
      className === "slick-ticket-detail" ||
        (className === "slick-ticket-detail-bluezebra" && {
          breakpoint: 996,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        }),
      className === "slick-ticket-detail" ||
        (className === "slick-ticket-detail-bluezebra" && {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        }),
        className === "slick-ticket-detail-bluezebra" && {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
    ],
  }
  if (data && data.length !== 0) {
    const shouldRenderSlider =
      data.length > slidesToShow ||
      (windowSize.width < 1500 && data.length === slidesToShow - 1) ||
      (windowSize.width < 1200 && data.length === slidesToShow - 2) ||
      (windowSize.width < 600 && data.length === 2)

    if (shouldRenderSlider) {
      return (
        <SliderSlick className={className} style={style} {...settings}>
          {children}
        </SliderSlick>
      )
    } else {
      return (
        <FlexContainer $alignItems={"center"} $gap={"16px"}>
          {children}
        </FlexContainer>
      )
    }
  }
}
