import React, { useRef, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { Color, FontFormat } from "../../util"
import {
  EllipsisVerticalIcon,
  XCircleIcon,
  CheckCircleIcon,
} from "../../util/icons/Outline"
import { P } from "../../style/main"
import {
  CardItem,
  Image,
  Text,
  TextLink,
  FlexContainer,
  TextBoxContainer,
  TextBox,
  ButtonEllipsisIcon,
  PopupMoreOptionContainer,
  PopupMoreOptionList,
} from "./styled"
import Avatar from "@mui/material/Avatar"
import { Link } from "react-router-dom"

export const Card = ({
  item,
  index,
  isAdmin,
  verifyPermissions,
  isInactivate,
  imageURL,
  textBox,
  isShowMoreOption,
  cardItemId,
  onMoreOption,
  moreOptionLists,
  isActiveOption,
  isLink,
  close,
  setCardItem,
  setIsShowPopupIsActive,
  openPopupUpdate,
  countItem,
}) => {
  const popupRef = useRef(null)
  const location = useLocation()
  const pathname = location.pathname

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        close(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [close])
  const PopupMoreOption = (id, item, index, count) => {
    return (
      <PopupMoreOptionContainer ref={popupRef} $lastItem={index + 1} $countItem={count}>
        {(isAdmin || verifyPermissions.Edit) &&
          moreOptionLists &&
          moreOptionLists.map((option) => (
            <PopupMoreOptionList
              key={option.id}
              onClick={() => openPopupUpdate(id)}
              $position={
                verifyPermissions.Edit && verifyPermissions.Inactive
                  ? "top"
                  : "oneOption"
              }
            >
              <option.icon width={18} height={18} /> {option.label}
            </PopupMoreOptionList>
          ))}

        {isAdmin ||
          (verifyPermissions.Inactive && isActiveOption && !item.is_admin) ? (
          <PopupMoreOptionList
            onClick={() => {
              setIsShowPopupIsActive(true)
              setCardItem({
                id: item.id,
                isActive: item.is_active,
              })
              close(false)
            }}
            $position={
              verifyPermissions.Edit &&
                verifyPermissions.Inactive &&
                !pathname.includes("/clients")
                ? "bottom"
                : "oneOption"
            }
          >
            {!item.is_active ? (
              <>
                <CheckCircleIcon idth={18} height={18} /> Active
              </>
            ) : (
              <>
                <XCircleIcon width={18} height={18} /> Inactive
              </>
            )}
          </PopupMoreOptionList>
        ) : null}
      </PopupMoreOptionContainer>
    )
  }

  return (
    <FlexContainer $justifyContent={"center"} $isWidthPercen={true}>
      <CardItem>
        {isAdmin ||
          (!item.is_admin &&
            (verifyPermissions.Edit || verifyPermissions.Inactive)) ? (
          <ButtonEllipsisIcon onClick={() => onMoreOption(item.id)}>
            <EllipsisVerticalIcon width={24} height={24} />
          </ButtonEllipsisIcon>
        ) : null}
        {isShowMoreOption && cardItemId === item.id && (
          <div>{PopupMoreOption(item.id, item, index, countItem)}</div>
        )}
        {!imageURL ? (
          <Link to={`detail/${item.id}`}>
            <Avatar
              src={imageURL}
              alt={"broken_image"}
              style={{ marginBottom: 12, width: "120px", height: "120px" }}
            />
          </Link>
        ) : (
          <>
            {isLink ? (
              <Link to={pathname !== "/engineers" && `detail/${item.id}`}>
                <Image
                  src={imageURL}
                  alt={item.name}
                  style={{ marginBottom: 12 }}
                />
              </Link>
            ) : (
              <Image
                src={imageURL}
                alt={item.name}
                style={{ marginBottom: 12 }}
              />
            )}
          </>
        )}
        <FlexContainer
          $flexDirection={"column"}
          $alignItems={"center"}
          $gap={"8px"}
        >
          {isLink ? (
            <TextLink
              to={`detail/${item.id}`}
              $fontSize={FontFormat.Title_XL.fontSize}
              $fontWeight={FontFormat.Title_XL.fontWeight}
              $lableColor={Color.Primary}
            >
              {item.name}
            </TextLink>
          ) : (
            <P
              $FontFormat={FontFormat.Title_XL}
              $textColor={Color.Primary}
              $width={"180px"}
              $height={"80px"}
              $textAlign={"center"}
              $textOverflow={"ellipsis"}
              $overflow={"hidden"}
            >
              {item.name}
            </P>
          )}

          {!item.email ? (
            <Text
              $fontSize={FontFormat.Body_SM.fontSize}
              $fontWeight={FontFormat.Body_SM.fontWeight}
              $lableColor={Color.Tertiary}
            >
              -
            </Text>
          ) : (
            <Text
              $fontSize={FontFormat.Body_SM.fontSize}
              $fontWeight={FontFormat.Body_SM.fontWeight}
              $lableColor={Color.Tertiary}
            >
              {item.email}
            </Text>
          )}
          <TextBoxContainer>
            <TextBox
              $fontSize={FontFormat.Body_SM.fontSize}
              $fontWeight={FontFormat.Body_SM.fontWeight}
              $lableColor={
                isInactivate && item.is_verify !== undefined && !item.is_verify
                  ? "#F4C900"
                  : Color.Primary
              }
              $borderColor={
                isInactivate && item.is_verify !== undefined && !item.is_verify
                  ? "#F4C900"
                  : Color.Primary
              }
              $backgroundColor={
                isInactivate && item.is_verify !== undefined && !item.is_verify
                  ? "#FEFBE4"
                  : ""
              }
            >
              {textBox}
            </TextBox>
            {item.is_admin && (
              <TextBox
                $fontSize={FontFormat.Body_SM.fontSize}
                $fontWeight={FontFormat.Body_SM.fontWeight}
                $lableColor={Color.Primary}
                $backgroundColor={Color.Baby_Blue}
              >
                Admin
              </TextBox>
            )}

          </TextBoxContainer>
        </FlexContainer>
      </CardItem>
    </FlexContainer>
  )
}
