import React from "react"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { DatePicker as DatePickerMui } from "@mui/x-date-pickers/DatePicker"
import { Color, FontFormat } from "../../util"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
dayjs.extend(utc)

export const DatePicker = ({
  label,
  value,
  onChange,
  minDate,
  disabled,
  isValid,
}) => {
  const datePickerSX = {
    width: "100%",
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: !disabled && Color.Primary_Hover,
    },
    "& .MuiInputBase-root": {
      borderRadius: "8px",
      backgroundColor: Color.Secondary,
    },
    "& .MuiInputBase-input": {
      padding: "10px 12px",
      color: Color.Primary,
      fontFamily: "IBM Plex Sans Thai, sans-serif",
      fontSize: FontFormat.Title_MD.fontSize,
      fontWeight: FontFormat.Title_MD.fontWeight,
    },
    "& .MuiInputBase-input::placeholder": {
      color: Color.Tertiary,
    },
    "& .MuiFormLabel-root": {
      color: Color.Tertiary,
      fontFamily: "IBM Plex Sans Thai, sans-serif",
      fontSize: FontFormat.Title_MD.fontSize,
      fontWeight: FontFormat.Title_MD.fontWeight,
      top: !value ? "-4px" : 0,
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: Color.Tertiary,
      top: 0,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: !isValid && !value ? Color.Error : Color.Disabled,
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: Color.Primary_Pressed,
      borderWidth: 1,
    },
  }
  const handleChange = (newValue) => {
    const newValueUTC = dayjs(newValue).utc(true)
    onChange(newValueUTC)
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePickerMui
        label={label}
        value={value}
        onChange={handleChange}
        format="DD-MM-YYYY"
        minDate={minDate}
        disabled={disabled}
        sx={datePickerSX}
      />
    </LocalizationProvider>
  )
}
