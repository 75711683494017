import React, { useState, useEffect, forwardRef } from "react"
import { Color, FontFormat } from "../../util"
import {
  BoxMui,
  FormControlMui,
  LabelMui,
  SelectMui,
  OptionMui,
} from "./styled"
import Box from "@mui/material/Box"
import Chip from "@mui/material/Chip"
import "./styled.css"

export const Select = forwardRef(
  (
    {
      onSelectChange,
      isShowDefaultOption,
      optionsData,
      width,
      height,
      label,
      isValid,
      value,
      isTag,
      multiple,
      disabled,
    },
    ref
  ) => {
    const [optionLabel, setOptionLabel] = useState("")
    const [optionTag, setOptionTag] = useState([])
    const [validate, setValidate] = useState(true)
    const [selectedTagIds, setSelectedTagIds] = useState([])

    const formSX = {
      "& .MuiFormLabel-root.MuiInputLabel-root.Mui-focused": {
        color: Color.Tertiary,
        fontFamily: "IBM Plex Sans Thai, sans-serif",
        fontSize: FontFormat.Title_MD.fontSize,
        fontWeight: FontFormat.Title_MD.fontWeight,
        top: 0,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: isTag
          ? !validate && optionTag.length === 0
            ? Color.Error
            : Color.Disabled
          : !validate && optionLabel === ""
            ? Color.Error
            : Color.Disabled,
        borderWidth: "1px !important",
      },
      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: Color.Primary_Pressed,
      },
    }
    const labelSX = {
      color: Color.Tertiary,
      fontFamily: "IBM Plex Sans Thai, sans-serif",
      fontSize: FontFormat.Title_MD.fontSize,
      fontWeight: FontFormat.Title_MD.fontWeight,
      top: !optionLabel
        ? "-4px"
        : isTag && optionTag && optionTag.length === 0
          ? "-4px"
          : 0,
    }
    const selectSX = {
      fontFamily: "IBM Plex Sans Thai, sans-serif",
      fontSize: FontFormat.Title_MD.fontSize,
      fontWeight: FontFormat.Title_MD.fontWeight,
      borderRadius: "8px",
      backgroundColor: Color.Secondary,
      color: Color.Primary,
      "& .MuiSelect-select": {
        padding: "10px 12px",
        height: height,
      },
      "& .MuiChip-filled": {
        color: Color.Primary,
        backgroundColor: Color.Baby_Blue,
        borderRadius: "4px",
        gap: "8px",
        padding: "2px 8px",
      },
      "&:hover": {
        "&& fieldset": {
          border: "1px solid",
          borderColor: Color.Primary_Hover,
        },
      },
    }
    const optionSX = {
      fontFamily: "IBM Plex Sans Thai, sans-serif",
      fontSize: FontFormat.Title_MD.fontSize,
      fontWeight: 400,
      color: Color.Tertiary,
    }
    const ITEM_HEIGHT = 48
    const ITEM_PADDING_TOP = 8
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    }

    useEffect(() => {
      if (isShowDefaultOption) {
        setOptionLabel(1);
      }
      if (value) {
        if (isTag) {
          const ids = value.map((item) => item.id);
          setOptionTag(ids);
          setSelectedTagIds(ids);
        } else {
          const option = optionsData.find((option) => option.label === value);
          setOptionLabel(option && option.value);
        }
      }

      if (isValid !== undefined) {
        setValidate(isValid);
      }
    }, [isValid, value, isShowDefaultOption, validate]);
    const onChange = (e) => {
      if (isTag) {
        const {
          target: { value },
        } = e;
        const selectedIds = typeof value === "string" ? value.split(",") : value;
        onSelectChange(selectedIds.map((id) => ({ id })));
        setOptionTag(selectedIds);
      } else {
        setOptionLabel(e.target.value);
        onSelectChange(e.target.value);
      }
    };
    const handleOptionClick = (item) => {
      let updatedSelectedTagIds;
      setSelectedTagIds((prevIds) => {
        if (prevIds.includes(item.id)) {
          updatedSelectedTagIds = prevIds.filter((id) => id !== item.id);
          return updatedSelectedTagIds
        } else {
          updatedSelectedTagIds = [...prevIds, item.id]
          return updatedSelectedTagIds
        }
      })
      onSelectChange(updatedSelectedTagIds)
    }

    return (
      <BoxMui sx={{ minWidth: 120, width: width }}>
        <FormControlMui fullWidth sx={formSX}>
          <LabelMui id="demo-simple-select-label" sx={labelSX}>
            {label}
          </LabelMui>
          {isTag ? (
            <SelectMui
              ref={ref}
              value={optionTag}
              label={label}
              multiple={multiple}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((id) => {
                    const option = optionsData.find((opt) => opt.id === id);
                    return <Chip key={id} label={option ? option.name : id} />;
                  })}
                </Box>
              )}
              onChange={onChange}
              sx={selectSX}
              MenuProps={MenuProps}
            >
              {optionsData &&
                optionsData.map((item, index) => (
                  <OptionMui
                    key={index}
                    id={item.id}
                    value={item.id}
                    sx={optionSX}
                    onClick={() => handleOptionClick(item)}
                    disabled={
                      optionTag &&
                      optionTag.length >= 4 &&
                      !optionTag.includes(item.id)
                    }
                  >
                    {item.name}
                  </OptionMui>
                ))}
            </SelectMui>
          ) : (
            <SelectMui
              ref={ref}
              value={optionLabel}
              label={label}
              onChange={onChange}
              sx={selectSX}
              disabled={disabled}
            >
              {optionsData && optionsData.map((item, index) => (
                <OptionMui key={index} value={item.value} sx={optionSX}>
                  {item.label}
                </OptionMui>
              ))}
            </SelectMui>
          )}
        </FormControlMui>
      </BoxMui>
    )
  }
)
