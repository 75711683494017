import React from "react"
import {
  Login,
  ForgetPassword,
  ResetPassword,
  Engineers,
  Error,
  Products,
  ProductsDetail,
  Clients,
  ClientsDetail,
  Tickets,
  TicketCreate,
  TicketsDetail,
} from "../views"
import { Routes, Route, Navigate } from "react-router-dom"
import { PrivateRoute } from "./PrivateRoute"

export const MainRoutes = () => {
  return (
    <Routes>
      <Route path="*" element={<Error />} />
      <Route path="/" element={<Navigate to="/tickets" />} />
      <Route path="/login" element={<Login />} />
      <Route path="/forgetPassword" element={<ForgetPassword />} />
      <Route path="/resetpassword" element={<ResetPassword />} />
      <Route
        path="/engineers"
        element={
          <PrivateRoute>
            <Engineers />
          </PrivateRoute>
        }
      />
      <Route
        path="/products"
        element={
          <PrivateRoute>
            <Products />
          </PrivateRoute>
        }
      />
      <Route
        path="/products/detail/:productId"
        element={
          <PrivateRoute>
            <ProductsDetail />
          </PrivateRoute>
        }
      />
      <Route
        path="/clients"
        element={
          <PrivateRoute>
            <Clients />
          </PrivateRoute>
        }
      />
      <Route
        path="/clients/detail/:clientId"
        element={
          <PrivateRoute>
            <ClientsDetail />
          </PrivateRoute>
        }
      />
      <Route
        path="/tickets"
        element={
          <PrivateRoute>
            <Tickets />
          </PrivateRoute>
        }
      />
      <Route
        path="/tickets/create"
        element={
          <PrivateRoute>
            <TicketCreate />
          </PrivateRoute>
        }
      />
      <Route
        path="/tickets/detail/:ticketId"
        element={
          <PrivateRoute>
            <TicketsDetail />
          </PrivateRoute>
        }
      />
    </Routes>
  )
}
