// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
    max-height: 200px !important;
  }`, "",{"version":3,"sources":["webpack://./src/components/Select/styled.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;EAC9B","sourcesContent":[".css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {\n    max-height: 200px !important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
