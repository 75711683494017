import {
  CheckIcon,
  DevicePhoneMobileIcon,
  MagnifyingGlassIcon,
  ArrowRightIcon,
  ArrowLeftIcon,
  EyeIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ArrowPathIcon,
  ClockIcon,
  MapPinIcon,
  XMarkIcon,
  UserCircleIcon,
  PlusIcon,
  CheckCircleIcon,
  XCircleIcon,
  PhotoIcon,
  UserIcon,
  LockClosedIcon,
  PhoneIcon,
  AdjustmentsVerticalIcon,
  FunnelIcon,
  PencilIcon,
  EllipsisVerticalIcon,
  EnvelopeIcon,
  PencilSquareIcon,
  CalendarIcon,
  PaperClipIcon,
  LinkIcon,
  CloudArrowUpIcon,
  CloudArrowDownIcon,
  ArrowRightStartOnRectangleIcon,
  ExclamationCircleIcon,
  DocumentTextIcon,
} from "@heroicons/react/24/outline";

export {
  CheckIcon,
  DevicePhoneMobileIcon,
  MagnifyingGlassIcon,
  ArrowRightIcon,
  ArrowLeftIcon,
  EyeIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ArrowPathIcon,
  ClockIcon,
  MapPinIcon,
  XMarkIcon,
  UserCircleIcon,
  PlusIcon,
  CheckCircleIcon,
  XCircleIcon,
  PhotoIcon,
  UserIcon,
  LockClosedIcon,
  PhoneIcon,
  AdjustmentsVerticalIcon,
  FunnelIcon,
  PencilIcon,
  EllipsisVerticalIcon,
  EnvelopeIcon,
  PencilSquareIcon,
  CalendarIcon,
  PaperClipIcon,
  LinkIcon,
  CloudArrowUpIcon,
  CloudArrowDownIcon,
  ArrowRightStartOnRectangleIcon,
  ExclamationCircleIcon,
  DocumentTextIcon,
};