import React, { useState, useEffect, useContext } from "react"
import { useNavigate } from "react-router-dom"
import { firebaseConfig } from "../../services/auth/firebaseConfig"
import {
  getAuth,
  verifyPasswordResetCode,
  confirmPasswordReset,
} from "firebase/auth"
import { LockClosedIcon } from "../../util/icons/Outline"
import { login, logo_bluezebra_blue } from "../../img"
import { Button, Input } from "../../components"
import { Error } from "../index"
import { Color, FontFormat, Validator } from "../../util"
import { ShowNavBarContext } from "../../components/Context"
import { FlexContainer, P } from "../../style/main"
import {
  DivFlex,
  Body,
  Image,
  HeadingText,
  DetailText,
  LoingText,
  DivInput,
} from "./styled"
import CircularProgress from "@mui/material/CircularProgress"

export const ResetPassword = () => {
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [isValid, setisValid] = useState(true)
  const [isSend, setIsSend] = useState(false)
  const [loading, setLoading] = useState(false)
  const auth = getAuth(firebaseConfig)
  const urlParams = new URLSearchParams(window.location.search)
  const navigate = useNavigate()
  const mode = urlParams.get("mode")
  const actionCode = urlParams.get("oobCode")
  const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&.])[A-Za-z\d@$!%*?&.]{8,}$/
  const { setIsShowNavBar } = useContext(ShowNavBarContext)


  useEffect(() => {
    setIsShowNavBar(false)
  }, [setIsShowNavBar])

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onConfirmPassword()
    }
  }

  const onConfirmPassword = async () => {
    let validate = true

    if (password === "" && confirmPassword === "") {
      validate = false
    } else if (!regex.test(password)) {
      validate = false
    } else if (password !== confirmPassword) {
      validate = false
    }
    setisValid(validate)
    try {
      if (validate) {
        setLoading(true)
        if (mode === "resetPassword") {
          verifyPasswordResetCode(auth, actionCode).then((email) => {
            const accountEmail = email
            if (accountEmail) {
              confirmPasswordReset(auth, actionCode, password)
                .then(() => {
                  setIsSend(true)
                  setLoading(false)
                })
                .catch(() => setLoading(false))
            }
          })
        }
      }
    } catch (e) {
      console.log(e)
    }
  }
  return (
    <>
      {mode === "resetPassword" ? (
        <DivFlex>
          <Body $isBackground={true}>
            <Image src={login} alt="client" />
            <div>
              <HeadingText>
                Welcome back to
                <br /> Bluezebra support system!
              </HeadingText>
              <DetailText>
                To keep connected with your client
                <br /> please login with your account
              </DetailText>
            </div>
          </Body>
          <Body>
            <DivInput $alignItems={"center"}>
              <Image src={logo_bluezebra_blue} alt="logo_bluezebra" />
              <LoingText>
                {isSend ? "Successful password reset!" : "Reset password"}
              </LoingText>
            </DivInput>
            {isSend ? (
              <FlexContainer
                $flexDirection={"column"}
                $alignItems={"center"}
                $gap={"16px"}
              >
                <LockClosedIcon width={85} height={85} color={Color.Primary} />
                <P
                  $FontFormat={FontFormat.Title_MD}
                  $textColor={Color.Tertiary}
                >
                  You can now use your new password to login to your account
                </P>
                <Button
                  color={Color.Secondary}
                  background={Color.Primary}
                  label={"Back to login"}
                  onClick={() => navigate("/login")}
                />
              </FlexContainer>
            ) : (
              <>
                <DivInput $gap={"16px"}>
                  <P
                    $FontFormat={FontFormat.Title_MD}
                    $textColor={Color.Tertiary}
                  >
                    New password
                  </P>
                  <FlexContainer $flexDirection={"column"} $gap={"4px"}>
                    <Input
                      type="password"
                      label={"Password"}
                      width={"386px"}
                      name="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      isValid={isValid}
                      onKeyDown={handleKeyDown}
                    />
                    {!isValid && !password ? (
                      <P style={{ ...Validator.errorStyle, top: 0 }}>
                        Please enter a valid password address.
                      </P>
                    ) : !isValid && password.length < 8 ? (
                      <P style={{ ...Validator.errorStyle, top: 0 }}>
                        Password must be at least 8 characters long.
                      </P>
                    ) : !isValid && !regex.test(password) ? (
                      <P style={{ ...Validator.errorStyle, top: 0, width: 386 }}>
                        Must be at least 8 characters including A-Z, a-z, 0-9 and a special character
                      </P>
                    ) : (
                      <P
                        $FontFormat={FontFormat.Label_MD}
                        $textColor={Color.Tertiary}
                        style={{ width: 386 }}
                      >
                        Must be at least 8 characters including A-Z, a-z, 0-9 and a special character
                      </P>
                    )}
                  </FlexContainer>
                  <FlexContainer $flexDirection={"column"} $gap={"4px"}>
                    <Input
                      type="password"
                      label={"Confirm Password"}
                      width={"386px"}
                      name="password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      isValid={isValid}
                      onKeyDown={handleKeyDown}
                    />
                    {!isValid && !confirmPassword ? (
                      <P style={{ ...Validator.errorStyle, top: 0 }}>
                        Please enter a valid Confirm password address.
                      </P>
                    ) : !isValid && password !== confirmPassword ? (
                      <P style={{ ...Validator.errorStyle, top: 0 }}>
                        Password and Confirm Password do not match.
                      </P>
                    ) : !isValid && confirmPassword.length < 8 ? (
                      <P style={{ ...Validator.errorStyle, top: 0 }}>
                        Password must be at least 8 characters long.
                      </P>
                    ) : null}
                  </FlexContainer>
                </DivInput>
                <Button
                  color={Color.Secondary}
                  background={Color.Primary}
                  label={
                    loading ? (
                      <CircularProgress
                        size={24}
                        style={{
                          color: Color.Secondary,
                          paddingLeft: "10px",
                          paddingRight: "10px",
                        }}
                      />
                    ) : (
                      "Reset password"
                    )
                  }
                  onClick={onConfirmPassword}
                />
              </>
            )}
          </Body>
        </DivFlex>
      ) : (
        <Error />
      )}
    </>
  )
}
