import React, { useState, useEffect } from "react"
import {
  BackgroundBlueContainer,
  Button,
  Card,
  SkeletonCard,
  Popup,
  Input,
  Menu,
  SearchAndFilter,
  Pagination,
} from "../../components"
import { Color, FontFormat, Enum, Validator, WindowSize } from "../../util"
import {
  searchClientUser,
  createClientUser,
  createVerifiedClientUser,
  updateIsActiveClientById,
} from "../../api"
import { FlexContainer, OverlayContainer, P, H1 } from "../../style/main"
import { userInfo, useUserPermission } from "../../auth"
import { LinkIcon, MagnifyingGlassIcon } from "../../util/icons/Outline"
import { v4 as uuidv4 } from "uuid"
import { PopupContainer, PopupTitleActive, PopupButton } from "./styled"

export const Clients = () => {
  const [fetchedUser, setFetchedUser] = useState([])
  const [fetchedclients, setFetchedclients] = useState([])
  const [searchQuery, setSearchQuery] = useState("")
  const [selectedStatus, setSelectedStatus] = useState(
    Enum.SELECT_STATUS.ACTIVE
  )
  const [isShowMoreOption, setIShowsMoreOption] = useState(false)
  const [isShowPopupAddOrUpdateClient, setIsShowPopupAddOrUpdateClient] =
    useState(false)
  const [isShowPopupCopyLink, setIsShowPopupCopyLink] = useState(false)
  const [isShowPopupIsActive, setIsShowPopupIsActive] = useState(false)
  const [checkAddOrUpdateUser, setCheckAddOrUpdateUser] = useState(
    Enum.ADD_OR_UPDATE.ADD
  )
  const [cardItem, setCardItem] = useState({
    id: "",
    isActive: true,
  })
  const [name, setName] = useState("")
  const [linkActivate, setLinkActivate] = useState("")
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [labelCopyLink, setLabelCopyLink] = useState(false)
  const [isValid, setIsValid] = useState(true)
  const [isSave, setIsSave] = useState(false)
  const [loading, setLoading] = useState(false)
  const { verifyPermissions, checkPermission } = useUserPermission()
  const windowSize = WindowSize()
  const limit = 15
  const basedURL = process.env.REACT_APP_HOST

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const user = await userInfo()
        setFetchedUser(user)
        checkPermission(user)
        setLoading(false)
      } catch (e) {
        console.error(e)
        setLoading(false)
      }
    }

    fetchData()
  }, [])
  useEffect(() => {
    const fetchClients = async () => {
      try {
        setLoading(true)
        if (isSave) {
          setIsSave(false)
        }
        const searchResponse = await searchClientUser(
          searchQuery,
          selectedStatus,
          page,
          limit
        )
        setFetchedclients(searchResponse.data)
        setTotal(searchResponse.total)
        if (searchResponse.total < limit) {
          setPage(1)
        } else {
          let totalPages = Math.ceil(searchResponse.total / limit)
          if (page > totalPages) {
            setPage(totalPages)
          } else {
            setPage(page)
          }
        }
        setLoading(false)
      } catch (e) {
        console.error(e)
        setLoading(false)
      }
    }

    fetchClients()
  }, [searchQuery, selectedStatus, isSave, page, total])
  const statusOption = [
    { value: 1, label: Enum.SELECT_STATUS.ACTIVE },
    { value: 2, label: Enum.SELECT_STATUS.IN_ACTIVE },
  ]
  const generateLinkActivate = () => {
    const verifyId = uuidv4()
    const updatedLink = `${basedURL}clientUser/create/${verifyId}`
    setLinkActivate(updatedLink)
    return verifyId
  }
  const copyToClipboard = (text) => {
    const textArea = document.createElement("textarea")
    textArea.value = text
    document.body.appendChild(textArea)
    textArea.select()
    document.execCommand("copy")
    document.body.removeChild(textArea)
    setLabelCopyLink(true)
    setTimeout(() => setLabelCopyLink(false), 3000)
  }
  const onUpdateIsActive = () => {
    const { id, isActive } = cardItem
    const Active = { is_active: !isActive }
    try {
      updateIsActiveClientById(id, Active)
        .catch((e) => console.log(e))
        .then(() => setIsShowPopupIsActive(false))
    } catch (e) {
      console.log(e)
    }
  }
  const onSearchQueryChange = (value) => {
    setSearchQuery(value)
  }
  const onPageChange = (e, newPage) => {
    setPage(newPage)
  }
  const onSelectChange = (value) => {
    const status = statusOption.find((option) => option.value === value)
    setSelectedStatus(status.label)
  }
  const onMoreOption = (id) => {
    setIShowsMoreOption(cardItem.id !== id || !isShowMoreOption)
    setCardItem({ id: id })
  }
  const openPopupUpdate = () => {
    setIsShowPopupAddOrUpdateClient(true)
    setIShowsMoreOption(false)
    setCheckAddOrUpdateUser(Enum.ADD_OR_UPDATE.UPDATE)
  }
  const onAddClient = (verifyId) => {
    let validate = true
    if (name === "") {
      validate = false
    }

    let data = {
      name,
    }

    setIsValid(validate)

    if (validate) {
      try {
        setLoading(true)
        createClientUser(data)
          .catch((e) => console.log(e))
          .then((res) => {
            let verifyBody = {
              clientId: res.id,
              verifyId: verifyId,
            }
            createVerifiedClientUser(verifyBody)
              .catch((e) => console.log(e))
              .then(() => {
                setLoading(false)
                setIsShowPopupAddOrUpdateClient(false)
                setName("")
                setIsSave(true)
                setIsShowPopupCopyLink(true)
              })
          })
      } catch (e) {
        console.log(e)
        setLoading(false)
      }
    }
  }
  const onUpdateClient = () => {
    let validate = true
    if (name === "") {
      validate = false
    }

    setIsValid(validate)

    if (validate) {
    }
  }
  const PopupAddOrUpdateClient = () => {
    return (
      <Popup
        title={
          checkAddOrUpdateUser === Enum.ADD_OR_UPDATE.UPDATE
            ? "Edit client"
            : "Create new client"
        }
        isButton={true}
        isLoading={loading}
        saveLabel={"Submit"}
        onClose={() => {
          setIsShowPopupAddOrUpdateClient(false)
          setIsValid(true)
          setName("")
        }}
        onSave={
          checkAddOrUpdateUser === Enum.ADD_OR_UPDATE.UPDATE
            ? onUpdateClient
            : () => {
              const verifyId = generateLinkActivate()
              onAddClient(verifyId)
            }
        }
      >
        <Input
          width={"100%"}
          type="text"
          label={"Name"}
          name="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          isValid={isValid}
        />
        {!isValid && name === "" ? (
          <p style={Validator.errorStyle}>Please complete this field.</p>
        ) : null}
      </Popup>
    )
  }
  const PopupCopyLink = () => {
    return (
      <Popup
        width={"511px"}
        title={"Send link to your client to activate account"}
        isButton={true}
        isLoading={loading}
        saveLabel={"Submit"}
        onClose={() => {
          setIsShowPopupCopyLink(false)
          setLinkActivate("")
          setLabelCopyLink(false)
        }}
      >
        <FlexContainer
          $flexDirection={windowSize.width <= 657 ? "column" : "row"}
          $alignItems={"center"}
          $justifyContent={"center"}
          $width={"100%"}
          $gap={"12px"}
        >
          <Input
            width={windowSize.width > 600 ? "70%" : "100%"}
            type="text"
            label={"Copy link to activate"}
            name="Copy link to activate"
            icon={<LinkIcon width={24} height={24} />}
            value={linkActivate}
            onChange={(e) => setLinkActivate(e.target.value)}
            isValid={isValid}
            readOnly={true}
          />
          <Button
            id="copyButton"
            color={Color.Secondary}
            background={Color.Primary}
            backgroundActive={Color.Primary_Pressed}
            label={labelCopyLink ? "Link copied!" : "Copy link"}
            width={"auto"}
            onClick={() => {
              copyToClipboard(linkActivate)
            }}
          />
        </FlexContainer>
      </Popup>
    )
  }
  const PopupIsActive = () => {
    return (
      <>
        <OverlayContainer $top={0} $left={0} $isBlur={true} />
        <PopupContainer>
          <PopupTitleActive>
            Do you want to “{!cardItem.isActive ? "Active" : "Inactive"}” this
            user?
          </PopupTitleActive>
          <FlexContainer $justifyContent={"center"}>
            <PopupButton
              $lableColor={Color.Primary}
              $borderRadius={"0px 0px 0px 10px"}
              $backgroundColor={Color.Secondary_Hover}
              $backgroundColorHover={Color.Secondary}
              onClick={() => setIsShowPopupIsActive(false)}
            >
              Cancel
            </PopupButton>
            <PopupButton
              $lableColor={Color.Secondary}
              $borderRadius={"0px 0px 10px 0px"}
              $backgroundColor={Color.Primary}
              $backgroundColorHover={Color.Primary_Hover}
              onClick={() => onUpdateIsActive()}
            >
              Confirm
            </PopupButton>
          </FlexContainer>
        </PopupContainer>
      </>
    )
  }

  return (
    <>
      <Menu />
      <BackgroundBlueContainer
        title={"Clients"}
        count={fetchedclients && fetchedclients.length}
        textCount={"Clients"}
      >
        <SearchAndFilter
          isAdmin={fetchedUser.is_admin}
          verifyPermissions={verifyPermissions.Clients}
          onSearchQueryChange={onSearchQueryChange}
          onSelectChange={onSelectChange}
          option={statusOption}
          setIsShowPopup={setIsShowPopupAddOrUpdateClient}
          otherFunction={generateLinkActivate}
        />
        {isShowPopupAddOrUpdateClient && PopupAddOrUpdateClient()}
        {isShowPopupCopyLink && PopupCopyLink()}
        {isShowPopupIsActive && PopupIsActive()}
        <FlexContainer
          $flexWrap={"wrap"}
          $justifyContent={windowSize.width <= 768 ? "center" : "flex-start"}
          $margin={"40px 0"}
          $gap={windowSize.width > 1920 && "14px"}
          style={{ overflow: "hidden" }}
        >
          {loading ? (
            <>
              {Array.from({ length: 18 }).map((_, index) => (
                <SkeletonCard key={index} />
              ))}
            </>
          ) : (
            <>
              {fetchedclients.length === 0 ? (
                <FlexContainer
                  $alignItems={"center"}
                  $flexDirection={"column"}
                  $width={"100%"}
                  style={{ marginTop: "40px", textAlign: "center" }}
                >
                  <MagnifyingGlassIcon
                    width={64}
                    height={64}
                    style={{ position: "relative", zIndex: 2 }}
                  />
                  <H1 $FontFormat={FontFormat.Title_XL}>No Items Found</H1>
                  <P $FontFormat={FontFormat.Body_MD}>
                    We can’t find any item matching your search.
                  </P>
                </FlexContainer>
              ) : (
                <>
                  {fetchedclients.map((item, index) => {
                    return (
                      <Card
                        key={index}
                        item={item}
                        index={index}
                        isAdmin={fetchedUser && fetchedUser.is_admin}
                        verifyPermissions={verifyPermissions.Clients}
                        isInactivate={true}
                        imageURL={item.logo_image}
                        textBox={
                          item.is_verify === undefined || item.is_verify
                            ? `${item.purchased_product.length} Product${item.purchased_product.length > 1 ? 's' : ''}`
                            : "Inactivate"
                        }
                        isShowMoreOption={isShowMoreOption}
                        cardItemId={cardItem.id}
                        onMoreOption={onMoreOption}
                        isActiveOption={true}
                        isLink={true}
                        close={setIShowsMoreOption}
                        setCardItem={setCardItem}
                        setIsShowPopupIsActive={setIsShowPopupIsActive}
                        openPopupUpdate={openPopupUpdate}
                        countItem={fetchedclients.length}
                      />
                    )
                  })}
                  <Pagination
                    count={total}
                    page={page}
                    limit={limit}
                    onChange={onPageChange}
                  />
                </>
              )}
            </>
          )}
        </FlexContainer>
      </BackgroundBlueContainer>
    </>
  )
}
