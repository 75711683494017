import styled from "styled-components"
import { Color, FontFormat } from "../../util"
import { Link } from "react-router-dom"

export const CarContainer = styled.div`
  display: flex;
  margin: 5px;
  width: 300px;
  padding: 16px 16px 24px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 16px;
  background-color: ${Color.Secondary};
  position: relative;
  box-sizing: border-box;
  &:hover {
    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.15);
  }
`
export const FlexContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => props.$flexDirection};
  justify-content: ${(props) => props.$justifyContent};
  align-items: ${(props) => props.$alignItems};
  gap: ${(props) => props.$gap};
  width: ${(props) =>
    props.$width && !props.$notSetWidth ? props.$width : ""};
  ${(props) =>
    props.$isWidthPercen && !props.$notSetWidth
      ? `
    @media screen and (max-width: 1921px) {
      width: 25%;
      margin-bottom: 10px;
    }
    @media screen and (max-width: 1444px) {
      width: 33.3333333333%;
    }
    @media screen and (max-width: 1111px) {
      width: 50%;
     
    }
    @media screen and (max-width: 657px) {
      width: 100%;
    }
  `
      : ""}
`
export const ImageLink = styled(Link)`
  width: 100%;
  height: 160px;
  flex-shrink: 0;
  border-radius: 8px;
  object-fit: cover;
  object-position: center;
`
export const Image = styled.img`
  width: 100%;
  height: 160px;
  flex-shrink: 0;
  border-radius: 8px;
  object-fit: cover;
  object-position: center;
`
export const Title = styled(Link)`
  height: 54px;
  white-space: nowrap;
  width: 233px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: ${FontFormat.Title_MD.fontSize};
  font-weight: ${FontFormat.Title_MD.fontWeight};
  color: ${Color.Tertiary};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`
export const SupervisorProfileImage = styled.img`
  width: 36px;
  height: 36px;
  object-fit: cover;
  object-position: center;
  border-radius: 100px;
  border: 1px solid ${Color.Secondary};
  position: ${(props) => props.$positionRelative};
  ${(props) => props.$left !== undefined && `left: ${props.$left}px;`}
  z-index: ${(props) => props.$zIndex};
`
export const ButtonEllipsisIcon = styled.button`
  display: flex;
  cursor: pointer;
  border: none;
  background: none;
  position: absolute;
  right: 0;
  left: auto;
`