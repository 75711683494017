import styled from "styled-components"
import { Color, FontFormat } from "../../util"

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => props.$flexDirection};
  flex-wrap: ${(props) => props.$flexWrap};
  justify-content: ${(props) => props.$justifyContent};
  align-items: ${(props) => props.$alignItems};
  gap: ${(props) => props.$gap};
  width: ${(props) => props.$width};
  margin: ${(props) => props.$margin};
`
export const OverlayContainer = styled.div`
  position: fixed;
  top: ${(props) => props.$top && `blur(3px)`};
  left: ${(props) => props.$left && `blur(3px)`};
  width: 100%;
  height: 100%;
  z-index: 999;
  backdrop-filter: blur(3px);
  background: rgba(0, 0, 0, 0.25);
`
export const PopupContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  width:  ${(props) => props.$width ? props.$width : "400px"};
  height: auto;
  padding: ${(props) => props.$padding};
  border-radius: 16px;
  background-color: ${Color.Secondary};
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.$gap};
  @media screen and (max-width: 600px) {
    width: 80%;
    overflow: hidden;
  }
`
export const PopupTitle = styled.h2`
  color: #000;
  font-size: ${FontFormat.Title_XL.fontSize};
  font-weight: ${FontFormat.Title_XL.fontWeight};
`
