import React, { useState, useEffect } from "react"
import { Link, useParams } from "react-router-dom"
import {
  Button,
  DatePicker,
  Menu,
  Input,
  Select,
  Popup,
  ProductCard,
  ProductSkeletonCard,
} from "../../components"
import { Color, FontFormat, Validator, WindowSize } from "../../util"
import {
  getClientUserById,
  getProductByClientId,
  createPurchasedProduct,
  getPurchasedProductByClientId,
  updatePurchasedProductById,
} from "../../api"
import { click_bait, no_product } from "../../img"
import {
  PlusIcon,
  EnvelopeIcon,
  PhoneIcon,
  MapPinIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  PencilIcon,
  LinkIcon,
  ArrowLeftIcon,
} from "../../util/icons/Outline"
import {
  FlexContainer,
  P,
  Span,
  H2,
} from "../../style/main"
import {
  Container,
  ProductImage,
  Description,
  BackgroundGrayContainer,
  BackgroundBlueContainer,
  SliderContainer,
  PopupProductContainer,
  PopupProductImage,
  Sticker,
  MoreOptionContainer,
  MoreOptionList,
} from "./styled"
import { userInfo, useUserPermission } from "../../auth"
import Avatar from "@mui/material/Avatar"
import Skeleton from "@mui/material/Skeleton"
import dayjs from "dayjs"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

export const ClientsDetail = () => {
  const { clientId } = useParams()
  const [client, setClient] = useState([])
  const [productClientList, setProductClientList] = useState([])
  const [productListForAdd, setProductListForAdd] = useState([])
  const [productListForUpdate, setProductListForUpdate] = useState([])
  const [chooseProduct, setChooseProduct] = useState("")
  const [cardId, setCardId] = useState("")
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [verifyId, setVerifyId] = useState(null)
  const [isShowPopup, setIsShowPopup] = useState(false)
  const [isShowMoreOption, setIShowsMoreOption] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [isValid, setIsValid] = useState(true)
  const [isSave, setIsSave] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingClients, setLoadingClients] = useState(false)
  const [labelCopyLink, setLabelCopyLink] = useState(false)
  const { verifyPermissions, checkPermission } = useUserPermission()
  const windowSize = WindowSize()
  const basedURL = process.env.REACT_APP_HOST
  const url = `${basedURL}clientUser/create/${verifyId}`

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const user = await userInfo()

        checkPermission(user)
        setLoading(false)
      } catch (e) {
        console.error(e)
        setLoading(false)
      }
    }

    fetchData()
  }, [])
  useEffect(() => {
    const fetchClient = async () => {
      try {
        setLoadingClients(true)
        const clients = await getClientUserById(clientId)
        setClient(clients)
        setVerifyId(clients[0].verified_client_user.verify_id)
        setLoadingClients(false)
      } catch (e) {
        console.error(e)
        setLoadingClients(false)
      }
    }
    fetchClient()
  }, [clientId])
  useEffect(() => {
    const fetchProductClient = async () => {
      try {
        setLoading(true)
        if (isSave) {
          setIsSave(false)
        }
        const productClientLists = await getPurchasedProductByClientId(clientId)
        setProductClientList(productClientLists)
        setLoading(false)
      } catch (e) {
        console.error(e)
        setLoading(false)
      }
    }
    fetchProductClient()
  }, [clientId, isSave])
  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const products = await getProductByClientId(clientId)
        const optionProductsForAdd = products.filteredProducts.map((item, index) => ({
          id: item.id,
          value: index,
          label: item.name,
          image: item.thumbnail_image,
          partner_name: item.partner_name,
        }))
        const optionProductsForUpdate = products.allProducts.map((item, index) => ({
          id: item.id,
          value: index,
          label: item.name,
          image: item.thumbnail_image,
          partner_name: item.partner_name,
        }))
        setProductListForAdd(optionProductsForAdd)
        setProductListForUpdate(optionProductsForUpdate)
      } catch (e) {
        console.error(e)
      }
    }
    fetchProduct()
  }, [clientId])

  const copyToClipboard = (text) => {
    const textArea = document.createElement("textarea")
    textArea.value = text
    document.body.appendChild(textArea)
    textArea.select()
    document.execCommand("copy")
    document.body.removeChild(textArea)
    setLabelCopyLink(true)
    setTimeout(() => setLabelCopyLink(false), 3000)
  }
  const ArrowLeft = (props) => {
    const { className, onClick } = props
    return (
      <ChevronLeftIcon
        width={"40px"}
        height={"40px"}
        strokeWidth={2}
        className={className}
        onClick={onClick}
        style={{ color: Color.Tertiary }}
      />
    )
  }
  const ArrowRight = (props) => {
    const { className, onClick } = props
    return (
      <ChevronRightIcon
        width={"40px"}
        height={"40px"}
        strokeWidth={2}
        className={className}
        onClick={onClick}
        style={{ color: Color.Tertiary }}
      />
    )
  }
  const settings = {
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    nextArrow: <ArrowRight />,
    prevArrow: <ArrowLeft />,
    responsive: [
      {
        breakpoint: 1111,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 996,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  const calculateNumberOfCards = () => {
    if (windowSize.width < 600) {
      return 1
    } else if (windowSize.width < 996) {
      return 2
    } else {
      return 3
    }
  }
  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "short",
      day: "2-digit",
      timeZone: "UTC",
    }
    const date = new Date(dateString)
    const formattedDate = date.toLocaleDateString("en-US", options)
    const [month, day, year] = formattedDate.split(" ")
    return `${day} ${month} ${year}`
  }
  const handleStartDateChange = (newValue) => {
    setStartDate(newValue)
  }
  const handleEndDateChange = (newValue) => {
    setEndDate(newValue)
  }
  const onMoreOption = (id) => {
    setIShowsMoreOption(cardId !== id || !isShowMoreOption)
    setCardId(id)
  }
  const onSaveProductClient = () => {
    let validate = true

    if (!chooseProduct) {
      validate = false
    } else if (startDate === null || endDate === null) {
      validate = false
    }

    setIsValid(validate)
    const data = {
      clientId,
      productId: chooseProduct.id,
      startDate: startDate,
      endDate: endDate,
    }

    if (validate) {
      try {
        setLoading(true)
        createPurchasedProduct(data)
          .catch((e) => console.log(e))
          .then(() => {
            setLoading(false)
            setIsSave(true)
            setIsShowPopup(false)
            setIsValid(true)
            setChooseProduct("")
            setStartDate(null)
            setEndDate(null)
          })
      } catch (e) {
        console.log(e)
        setLoading(false)
      }
    }
  }
  const onUpdateProductClient = () => {
    let validate = true

    if (endDate === null) {
      validate = false
    }

    setIsValid(validate)
    const data = {
      startDate: startDate,
      endDate: endDate,
    }

    if (validate) {
      try {
        setLoading(true)
        updatePurchasedProductById(cardId, data)
          .catch((e) => console.log(e))
          .then(() => {
            setLoading(false)
            setIsSave(true)
            setIsShowPopup(false)
            setIsValid(true)
            setChooseProduct("")
            setStartDate(null)
            setEndDate(null)
          })
      } catch (e) {
        console.log(e)
        setLoading(false)
      }
    }
  }
  const onSelectChange = (value) => {
    const chooseProduct = productListForAdd.find((option) => option.value === value)
    setChooseProduct(chooseProduct)
  }
  const openPopupUpdate = (product) => {
    const findProduct = productListForUpdate.find(
      (option) => option.id === product.product_id
    )
    setChooseProduct(findProduct)
    setStartDate(dayjs(product.start_warranty))
    setEndDate(dayjs(product.end_warranty))
    setIsShowPopup(true)
  }
  const PopupAddOrUpdateProduct = () => {
    return (
      <Popup
        title={!isUpdate ? "Add product" : "Edit product"}
        isButton={true}
        isLoading={loading}
        saveLabel={"Submit"}
        onClose={() => {
          setIsShowPopup(false)
          setChooseProduct("")
          setStartDate(null)
          setEndDate(null)
          setIsValid(true)
          setIsUpdate(false)
        }}
        onSave={!isUpdate ? onSaveProductClient : onUpdateProductClient}
      >
        <PopupProductContainer>
          <div>
            {!chooseProduct ? (
              <PopupProductImage src={no_product} alt="please_select_product" />
            ) : (
              <PopupProductImage
                src={chooseProduct.image}
                alt={chooseProduct.label}
              />
            )}
          </div>
          <div style={{ width: "100%" }}>
            <Select
              onSelectChange={onSelectChange}
              optionsData={isUpdate ? productListForUpdate : productListForAdd}
              width={"100%"}
              height={"auto !important"}
              value={chooseProduct.label}
              label={"Product Name"}
              isValid={isValid}
              disabled={isUpdate}
            />
            {!isValid && !chooseProduct ? (
              <p style={{ ...Validator.errorStyle, top: 0 }}>
                Please complete this field.
              </p>
            ) : null}
            <P $FontFormat={FontFormat.Body_MD} $margin={"4px 0 0 0"}>
              Partner: {!chooseProduct ? "-" : chooseProduct.partner_name}
            </P>
            {windowSize.width >= 768 && (
              <Sticker src={click_bait} alt="click_bait" />
            )}
          </div>
        </PopupProductContainer>
        <H2 $FontFormat={FontFormat.Title_MD}>Warranty date</H2>
        <FlexContainer
          $flexDirection={"column"}
          $justifyContent={"center"}
          $gap={"100px"}
        >
          <div>
            <FlexContainer
              $flexDirection={windowSize.width > 600 ? "row" : "column"}
              $justifyContent={"center"}
              $gap={"18px"}
            >
              <DatePicker
                label={"Start date"}
                value={startDate}
                onChange={handleStartDateChange}
                isValid={isValid}
              />
              <DatePicker
                label={"End date"}
                value={endDate}
                onChange={handleEndDateChange}
                minDate={startDate}
                isValid={isValid}
              />
            </FlexContainer>
            {!isValid && (startDate === null || endDate === null) ? (
              <p
                style={{
                  ...Validator.errorStyle,
                  top: 0,
                  margin: "4px 0",
                }}
              >
                Please complete this field.
              </p>
            ) : null}
          </div>
        </FlexContainer>
      </Popup>
    )
  }
  const PopupMoreOption = (product, index) => {
    return (
      <MoreOptionContainer $lastItem={index + 1} >
        {verifyPermissions.Clients.Edit && (
          <MoreOptionList
            onClick={() => {
              openPopupUpdate(product)
              setIsUpdate(true)
              setIShowsMoreOption(false)
            }}
            $position={"oneOption"}
          >
            <>
              <PencilIcon width={20} height={20} /> Edit warranty date
            </>
          </MoreOptionList>
        )}
      </MoreOptionContainer>
    )
  }

  const numberOfSkeletons = calculateNumberOfCards()
  return (
    <>
      <Menu />
      <div style={{ margin: windowSize.width <= 1440 && "0 16px" }}>
        <FlexContainer $justifyContent={"center"}>
          <BackgroundGrayContainer
          >
            <Link
              to={"/clients"}
              style={{ position: windowSize.width <= 657 && "absolute" }}
            >
              <ArrowLeftIcon
                width={24}
                height={24}
                strokeWidth={2}
                style={{ color: Color.Tertiary }}
              />
            </Link>
            {loadingClients ? (
              <Container $width={"100%"}>
                <FlexContainer
                  $flexDirection={"column"}
                  $alignItems={"center"}
                  style={{ textAlign: "center" }}
                >
                  <Skeleton
                    variant="circular"
                    width={"203px"}
                    height={"203px"}
                    animation="wave"
                  />
                </FlexContainer>
                <FlexContainer
                  $flexDirection={"column"}
                  $gap={"35px"}
                  $width={"100%"}
                >
                  <FlexContainer $flexDirection={"column"}>
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{
                        fontSize: FontFormat.Title_XL.fontSize,
                        width: "30%",
                      }}
                    />
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{
                        fontSize: FontFormat.Title_XL.fontSize,
                        width: "50%",
                      }}
                    />
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{
                        fontSize: FontFormat.Title_XL.fontSize,
                        width: "30%",
                      }}
                    />
                    <Skeleton
                      variant="rounded"
                      width={"100%"}
                      height={"150px"}
                      animation="wave"
                    />
                  </FlexContainer>
                  <FlexContainer
                    $flexDirection={"column"}
                    $width={windowSize.width <= 657 ? "100%" : "50%"}
                  >
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{
                        fontSize: FontFormat.Body_MD.fontSize,
                      }}
                    />
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{
                        fontSize: FontFormat.Body_MD.fontSize,
                      }}
                    />
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{
                        fontSize: FontFormat.Body_MD.fontSize,
                      }}
                    />
                  </FlexContainer>
                </FlexContainer>
              </Container>
            ) : (
              <>
                {client.map((item, index) => {
                  return (
                    <Container key={index} $width={"100%"}>
                      <FlexContainer
                        $flexDirection={"column"}
                        $alignItems={"center"}
                        style={{ textAlign: "center" }}
                      >
                        {!item.logo_image ? (
                          <Avatar
                            src={item.logo_image}
                            alt={"broken_image"}
                            style={{
                              width: "203px",
                              height: "203px",
                            }}
                          />
                        ) : (
                          <ProductImage src={item.logo_image} alt={item.name} />
                        )}
                      </FlexContainer>
                      {item &&
                        item.verified_client_user &&
                        item.verified_client_user.is_verify ? (
                        <FlexContainer $flexDirection={"column"} $gap={"12px"}>
                          <FlexContainer $flexDirection={"column"} $gap={"4px"}>
                            <H2
                              $FontFormat={FontFormat.Title_MD}
                              $textColor={Color.Tertiary}
                            >
                              Client name:
                            </H2>
                            <Description>{item && item.name}</Description>
                          </FlexContainer>
                          <FlexContainer $flexDirection={"column"} $gap={"4px"}>
                            <H2
                              $FontFormat={FontFormat.Title_MD}
                              $textColor={Color.Tertiary}
                            >
                              Description:
                            </H2>
                            <Description>
                              {item.description ? item.description : "-"}
                            </Description>
                          </FlexContainer>
                          <FlexContainer
                            $flexDirection={"column"}
                            $gap={"16px"}
                          >
                            <FlexContainer $gap={"16px"}>
                              <EnvelopeIcon width={24} height={24} />
                              <P
                                $FontFormat={FontFormat.Body_MD}
                                $width={"90%"}
                              >
                                {item.email ? item.email : "-"}
                              </P>
                            </FlexContainer>

                            <FlexContainer $gap={"16px"}>
                              <PhoneIcon width={24} height={24} />
                              <P
                                $FontFormat={FontFormat.Body_MD}
                                $width={"90%"}
                              >
                                {item.phone_number ? item.phone_number : "-"}
                              </P>
                            </FlexContainer>

                            <FlexContainer $gap={"16px"}>
                              <MapPinIcon width={24} height={24} />
                              <P
                                $FontFormat={FontFormat.Body_MD}
                                $width={"90%"}
                              >
                                {item.location ? item.location : "-"}
                              </P>
                            </FlexContainer>
                          </FlexContainer>
                        </FlexContainer>
                      ) : (
                        <FlexContainer
                          $flexDirection={"column"}
                          $alignItems={"flex-start"}
                          $justifyContent={"flex-start"}
                          $width={"100%"}
                          $gap={"12px"}
                        >
                          <div>
                            <H2
                              $FontFormat={FontFormat.Title_MD}
                              $textColor={Color.Tertiary}
                            >
                              Client name:
                            </H2>
                            <Description>{item.name}</Description>
                          </div>
                          <FlexContainer
                            $flexDirection={
                              windowSize.width <= 657 ? "column" : "row"
                            }
                            $gap={"12px"}
                            $width={"100%"}
                          >
                            <Input
                              type="text"
                              icon={<LinkIcon width={24} height={24} />}
                              width={windowSize.width <= 657 ? "90%" : "45%"}
                              label={"Copy link to activate"}
                              value={url}
                              readOnly={true}
                            />
                            <Button
                              color={Color.Secondary}
                              background={Color.Primary}
                              backgroundActive={Color.Primary_Pressed}
                              label={
                                labelCopyLink ? "Link copied!" : "Copy link"
                              }
                              onClick={() => {
                                copyToClipboard(url)
                              }}
                            />
                          </FlexContainer>
                        </FlexContainer>
                      )}
                    </Container>
                  )
                })}
              </>
            )}
          </BackgroundGrayContainer>
        </FlexContainer>
        <FlexContainer $justifyContent={"center"}>
          <BackgroundBlueContainer>
            <FlexContainer
              $flexDirection={"column"}
              $alignItems={"center"}
              $gap={"12px"}
            >
              <FlexContainer
                $flexDirection={"column"}
                $alignItems={"center"}
                $gap={"4px"}
              >
                <H2 $FontFormat={FontFormat.Title_XL}>Products</H2>
                <P $FontFormat={FontFormat.Body_MD}>
                  The total number of products:{" "}
                  <Span $FontFormat={FontFormat.Title_MD}>
                    {productClientList.length} products
                  </Span>
                </P>
              </FlexContainer>
              <Button
                color={Color.Secondary}
                background={Color.Primary}
                backgroundActive={Color.Primary_Pressed}
                label={
                  <>
                    <PlusIcon width={20} height={20} strokeWidth={2.7} /> Add
                  </>
                }
                onClick={() => setIsShowPopup(true)}
              />
            </FlexContainer>
            {isShowPopup && PopupAddOrUpdateProduct()}
            {loading ? (
              <FlexContainer $alignItems={"center"} $justifyContent={"center"}>
                {Array.from({ length: numberOfSkeletons }).map((_, index) => (
                  <ProductSkeletonCard key={index} />
                ))}
              </FlexContainer>
            ) : (
              <SliderContainer
                $width={
                  windowSize.width <= 996 && windowSize.width > 657
                    ? "90%"
                    : windowSize.width < 400
                      ? "95%"
                      : "80%"
                }
              >
                {productClientList.length >= 3 ||
                  (productClientList.length > 1 && windowSize.width < 996) ? (
                  <Slider {...settings}>
                    {productClientList.map((item, index) => (
                      <ProductCard
                        key={index}
                        item={item}
                        index={index}
                        productImage={item.thumbnail_image}
                        title={item.product_name}
                        formatDate={formatDate}
                        warrantyDate={true}
                        supervisors={item.supervisors}
                        isShowMoreOption={isShowMoreOption}
                        verifyPermissions={verifyPermissions.Clients}
                        notSetWidth={true}
                        cardId={cardId}
                        onMoreOption={onMoreOption}
                        PopupMoreOption={PopupMoreOption}
                      />
                    ))}
                  </Slider>
                ) : (
                  <FlexContainer
                    $flexDirection={"row"}
                    $justifyContent={"center"}
                    $gap="48px"
                  >
                    {productClientList.map((item, index) => (
                      <ProductCard
                        key={index}
                        item={item}
                        index={index}
                        productImage={item.thumbnail_image}
                        title={item.product_name}
                        formatDate={formatDate}
                        warrantyDate={formatDate(item.end_warranty)}
                        supervisors={item.supervisors}
                        isShowMoreOption={isShowMoreOption}
                        isAdmin={true}
                        verifyPermissions={verifyPermissions.Clients}
                        notSetWidth={true}
                        cardId={cardId}
                        onMoreOption={onMoreOption}
                        PopupMoreOption={PopupMoreOption}
                      />
                    ))}
                  </FlexContainer>
                )}
              </SliderContainer>
            )}
          </BackgroundBlueContainer>
        </FlexContainer>
      </div>
    </>
  )
}
