import styled from "styled-components"
import { Link } from "react-router-dom"
import { Color, FontFormat } from "../../util"

export const DivFlex = styled.div`
  display: flex;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`
export const Body = styled.div`
  width: 50%;
  height: 100vh;
  gap: 32px;
  background-color: ${(props) =>
    props.$isBackground ? `${Color.Baby_Blue}` : ""};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 992px) {
    padding: 16px;
  }
  @media only screen and (max-width: 768px) {
    width: auto;
  }
`
export const Image = styled.img`
  @media only screen and (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`
export const HeadingText = styled.h1`
  font-size: ${FontFormat.Heading_XL.fontSize};
  font-weight: ${FontFormat.Heading_XL.fontWeight};
  text-align: center;
  margin-bottom: 8px;
  line-height: 150%;
`
export const DetailText = styled.p`
  font-size: ${FontFormat.Title_XL.fontSize};
  font-weight: ${FontFormat.Title_XL.fontWeight};
  text-align: center;
  line-height: 150%;
`
export const LoingText = styled.h2`
  font-size: ${FontFormat.Heading_MD.fontSize};
  font-weight: ${FontFormat.Heading_MD.fontWeight};
  line-height: 150%;
`
export const DivInput = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => props.$alignItems};
  gap: ${(props) => props.$gap};
`
export const TextResetPassword = styled(Link)`
  font-size: ${FontFormat.Body_SM_Underline.fontSize};
  font-weight: ${FontFormat.Body_SM_Underline.fontWeight};
  color: #000;
  line-height: 150%;
`