import {
    HomeIcon,
    PlusCircleIcon,
    XCircleIcon as SolidXCircleIcon,
    PencilIcon as SolidPencilIcon,
    EyeIcon as SolidEyeIcon,
    EyeSlashIcon as SolidEyeSlashIcon,
} from "@heroicons/react/24/solid"

export {
    HomeIcon,
    PlusCircleIcon,
    SolidXCircleIcon,
    SolidPencilIcon,
    SolidEyeIcon,
    SolidEyeSlashIcon,
}