import styled from "styled-components"
import { Color, FontFormat } from "../../util"

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => props.$flexDirection};
  justify-content: ${(props) => props.$justifyContent};
  align-items: ${(props) => props.$alignItems};
  gap: ${(props) => props.$gap};
  width: ${(props) => props.$width};
`
export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
  width: ${(props) => props.$width};
  @media screen and (max-width: 657px) {
    flex-direction: column;
  }
`
export const ProductImage = styled.img`
  width: 203px;
  height: 203px;
  object-fit: cover;
  object-position: center;
  border-radius: 203px;
  @media screen and (max-width: 996px) {
    width: 175px;
    height: 175px;
    border-radius: 175px;
  }
  @media screen and (max-width: 768px) {
    width: 155px;
    height: 155px;
    border-radius: 155px;
  }
  @media screen and (max-width: 657px) {
    width: 223px;
    height: 223px;
    border-radius: 223px;
  }
`
export const Description = styled.p`
  font-size: ${FontFormat.Body_MD.fontSize};
  font-weight: ${FontFormat.Body_MD.fontWeight};
  line-height: 150%;
  overflow: hidden;
  position: relative;
  text-align: justify;
`
export const BackgroundGrayContainer = styled.div`
  width: 1400px;
  height: auto;
  padding: 40px 54px;
  margin: 0 90px;
  border-radius: 16px 16px 0 0;
  background-color: #ecf0f3;
  overflow: hidden;
  @media screen and (max-width: 768px) {
    margin: 0;
    padding: 24px 32px;
  }
`
export const BackgroundBlueContainer = styled.div`
  width: 1400px;
  height: auto;
  padding: 40px 54px;
  margin: 0 90px 97px;
  border-radius: 0 0 16px 16px;
  background-color: ${Color.Baby_Blue};
  overflow: hidden;
  @media screen and (max-width: 768px) {
    margin: 0;
    margin-bottom: 16px;
    padding: 24px 32px;
  }
`
export const SliderContainer = styled.div`
  width: ${(props) => props.$width};
  margin: 0 auto;
`
export const PopupProductContainer = styled.div`
  display: flex;
  padding: 12px;
  align-items: flex-start;
  gap: 12px;
  border-radius: 8px;
  background-color: ${Color.Light_Blue};
  @media screen and (max-width: 768px) {
    gap: 12px;
    padding-left: 16px;
    padding-right: 16px;
  }
  @media screen and (max-width: 600px) {
    flex-direction: column;
    overflow: hidden;
  }
`
export const PopupProductImage = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 8px;
  border: 1px dashed ${Color.Disabled};
`
export const Sticker = styled.img`
  position: absolute;
  right: 33px;
  top: 145px;
`
export const MoreOptionContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  border: 1px solid ${Color.Disabled};
  background: ${Color.Secondary};
  position: absolute;
  top: 238px;
  right: 10px;
  z-index: 3;
`
export const MoreOptionList = styled.button`
  font-size: ${FontFormat.Body_MD.fontSize};
  font-weight: ${FontFormat.Body_MD.fontWeight};
  display: flex;
  width: 209px;
  padding: 10px 12px;
  align-items: center;
  gap: 8px;
  border-radius: ${(props) =>
    props.$position === "top"
      ? "8px 8px 0px 0px"
      : props.$position === "bottom"
      ? "0px 0px 8px 8px"
      : props.$position === "oneOption"
      ? "8px"
      : "0"};
  background: ${Color.Secondary};
  border: none;
  cursor: pointer;
  &: hover {
    background: ${Color.Secondary_Hover};
  }
`
export const PopupButton = styled.button`
  display: flex;
  padding: 10px 0px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  color: ${(props) => props.$lableColor};
  border: none;
  border-radius: ${(props) => props.$borderRadius};
  background-color: ${(props) => props.$backgroundColor};
  cursor: pointer;
  &: hover {
    background-color: ${(props) => props.$backgroundColorHover};
  }
`
