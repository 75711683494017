import React from "react"
import { useLocation } from "react-router-dom"
import { FlexContainer, MenuLink } from "./styled"

export const Menu = () => {
  const location = useLocation()
  const pathname = location.pathname

  return (
    <FlexContainer>
      <MenuLink
        to="/tickets"
        $textDecoration={pathname !== "/tickets" && pathname !== "/"  ? "none" : "underline"}
      >
        All tickets
      </MenuLink>
      <MenuLink
        to="/clients"
        $textDecoration={
          pathname !== "/clients" && !pathname.includes("/clients/detail/")
            ? "none"
            : "underline"
        }
      >
        Clients
      </MenuLink>
      <MenuLink
        to="/products"
        $textDecoration={
          pathname !== "/products" && !pathname.includes("/products/detail/")
            ? "none"
            : "underline"
        }
      >
        Products
      </MenuLink>
      <MenuLink
        to="/engineers"
        $textDecoration={pathname !== "/engineers" ? "none" : "underline"}
      >
        Users
      </MenuLink>
    </FlexContainer>
  )
}
