import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { getProductById } from "../../api"
import { Color, FontFormat, WindowSize } from "../../util"
import { BackgroundGrayContainer, Menu } from "../../components"
import {
  FlexContainer,
  Container,
  ProductImage,
  SupTite,
  Text,
  SupervisorProfileImage,
  Description,
  SeeMoreText,
} from "./styled"
import Skeleton from "@mui/material/Skeleton"

export const ProductsDetail = () => {
  const { productId } = useParams()
  const [product, setProduct] = useState([])
  const [expanded, setExpanded] = useState(false)
  const [loadingProductsDetail, setLoadingProductsDetail] = useState(false)
  const windowSize = WindowSize()

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        setLoadingProductsDetail(true)
        const product = await getProductById(productId)
        setProduct(product)
        setLoadingProductsDetail(false)
      } catch (e) {
        console.error(e)
        setLoadingProductsDetail(false)
      }
    }
    fetchProduct()
  }, [productId])

  const toggleExpanded = () => {
    setExpanded(!expanded)
  }

  return (
    <>
      <Menu />
      <div style={{ margin: windowSize.width < 1440 && "0 16px" }}>
        <FlexContainer $justifyContent={"center"}>
          <BackgroundGrayContainer
            color={Color}
            windowSize={windowSize.width}
            link={"/products"}
          >
            {loadingProductsDetail ? (
              <Container $width={"100%"}>
                <FlexContainer
                  $flexDirection={"column"}
                  $alignItems={"center"}
                  style={{ textAlign: "center" }}
                >
                  <Skeleton
                    variant="circular"
                    width={"203px"}
                    height={"203px"}
                    animation="wave"
                  />
                </FlexContainer>
                <FlexContainer
                  $flexDirection={"column"}
                  $gap={"12px"}
                  $width={"100%"}
                >
                  <div>
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{
                        fontSize: FontFormat.Title_XL.fontSize,
                        width: "30%",
                      }}
                    />
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{
                        fontSize: FontFormat.Title_XL.fontSize,
                        width: "50%",
                      }}
                    />
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{
                        fontSize: FontFormat.Title_XL.fontSize,
                        width: "30%",
                      }}
                    />
                    <Skeleton
                      variant="rounded"
                      width={"100%"}
                      height={"150px"}
                      animation="wave"
                    />
                  </div>
                  <FlexContainer
                    $gap={windowSize.width > 996 ? "272px" : "40px"}
                  >
                    <div style={{ width: "50%" }}>
                      <Skeleton
                        variant="text"
                        animation="wave"
                        sx={{
                          fontSize: FontFormat.Title_XL.fontSize,
                          width: "50%",
                        }}
                      />
                      <Skeleton
                        variant="text"
                        animation="wave"
                        sx={{
                          fontSize: FontFormat.Body_MD.fontSize,
                          width: "100%",
                        }}
                      />
                    </div>
                    <div style={{ width: "50%" }}>
                      <Skeleton
                        variant="text"
                        animation="wave"
                        sx={{
                          fontSize: FontFormat.Title_XL.fontSize,
                          width: "50%",
                        }}
                      />
                      <Skeleton
                        variant="text"
                        animation="wave"
                        sx={{
                          fontSize: FontFormat.Body_MD.fontSize,
                          width: "100%",
                        }}
                      />
                    </div>
                  </FlexContainer>
                  <div>
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{
                        fontSize: FontFormat.Title_XL.fontSize,
                        width: "25%",
                      }}
                    />
                    <FlexContainer $gap={"24px"}>
                      {Array.from({ length: 4 }).map((_, index) => (
                        <React.Fragment key={index}>
                          <Skeleton
                            variant="circular"
                            width={"36px"}
                            height={"36px"}
                            animation="wave"
                            sx={{ borderRadius: "100px" }}
                          />
                          {windowSize.width > 657 && (
                            <Skeleton
                              variant="text"
                              animation="wave"
                              sx={{
                                fontSize: FontFormat.Body_MD.fontSize,
                                width: "10%",
                              }}
                            />
                          )}
                        </React.Fragment>
                      ))}
                    </FlexContainer>
                  </div>
                </FlexContainer>
              </Container>
            ) : (
              <>
                {product.map((item, index) => {
                  return (
                    <Container key={index}>
                      <FlexContainer
                        $flexDirection={"column"}
                        $alignItems={"center"}
                        style={{ textAlign: "center" }}
                      >
                          <ProductImage
                            src={item.thumbnail_image}
                            alt={item.name}
                          />
                      </FlexContainer>
                      <FlexContainer $flexDirection={"column"} $gap={"12px"}>
                        <div style={{ gap: "4px" }}>
                          <SupTite>Product’s name:</SupTite>
                          <Text>{item.name}</Text>
                        </div>
                        <div style={{ gap: "4px" }}>
                          <SupTite>Description:</SupTite>
                          <div>
                            <Description
                              dangerouslySetInnerHTML={{
                                __html: item.description,
                              }}
                              $maxHeight={expanded ? "none" : "7em"}
                            />
                            <SeeMoreText onClick={toggleExpanded}>
                              {expanded ? "See Less" : "See More"}
                            </SeeMoreText>
                          </div>
                        </div>
                        <FlexContainer
                          $gap={windowSize.width > 996 ? "272px" : "40px"}
                        >
                          <div>
                            <SupTite>Category:</SupTite>
                            <Text>{item.category_name}</Text>
                          </div>
                          <div>
                            <SupTite>Partner:</SupTite>
                            <Text>{item.partner_name}</Text>
                          </div>
                        </FlexContainer>
                        <div>
                          <SupTite>Supervisors:</SupTite>
                          <FlexContainer $gap={"24px"}>
                            {item.supervisors.map(
                              (supervisor, supervisorIndex) => {
                                return (
                                  <FlexContainer
                                    $alignItems={"center"}
                                    $gap={"8px"}
                                    key={supervisorIndex}
                                  >
                                    <SupervisorProfileImage
                                      src={supervisor.profile_image}
                                      alt={supervisor.name}
                                    />
                                    {windowSize.width > 657 && (
                                      <Text>{supervisor.name.split(" ")[0]}</Text>
                                    )}
                                  </FlexContainer>
                                )
                              }
                            )}
                          </FlexContainer>
                        </div>
                      </FlexContainer>
                    </Container>
                  )
                })}
              </>
            )}
          </BackgroundGrayContainer>
        </FlexContainer>
      </div>
    </>
  )
}
