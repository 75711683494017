import styled from "styled-components"

export const FilterBarContainer = styled.div`
  display: flex;
  gap: 12px;
  padding-top: 24px;
  @media screen and (max-width: 1200px) {
    flex-direction: column;
    overflow: hidden;
  }
`
export const FlexContainer = styled.div`
  display: flex;
  gap: 12px;
  width: ${(props) => props.$width};
`
