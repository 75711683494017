import styled from "styled-components"

export const ImageBackground = styled.img`
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 2;
  overflow: hidden;
  object-fit: cover;
  object-position: center;
  @media screen and (max-width: 768px) {
    margin: 0;
    height: 275px;
  }
`
export const TextBackground = styled.p`
  color: rgba(0, 0, 0, 0.05);
  font-size: 800px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  position: absolute;
  z-index: 1;
  top: 0;
`
export const TextErrorContainer = styled.div`
  text-align: center;
  margin-top: -32px;
  @media screen and (max-width: 768px) {
    margin: 0;
  }
`
