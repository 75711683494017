import styled from "styled-components"
import { Link } from "react-router-dom"
import { Color, FontFormat } from "../../util"

const MoreOptionCalculateLeft = (props, maxItems) => {
  return props.$lastItem % maxItems === 0 ? "unset" : "185px"
}
const MoreOptionCalculateRight = (props, maxItems) => {
  return props.$lastItem % maxItems === 0 ? "10px" : "unset"
}
export const CardItem = styled.div`
  display: flex;
  margin: 5px;
  width: 211px;
  padding: 24px 12px;
  flex-direction: column;
  align-items: center;
  border-radius: 16px;
  background-color: ${Color.Secondary};
  position: relative;
  box-sizing: border-box;
  margin-bottom: 32px;
`
export const Image = styled.img`
  width: 112px;
  height: 112px;
  border-radius: 56px;
  background-color: ${Color.Secondary};
  object-fit: cover;
  object-position: center;
`
export const Text = styled.p`
  font-size: ${(props) => props.$fontSize};
  font-weight: ${(props) => props.$fontWeight};
  color: ${(props) => props.$lableColor};
  width: 180px;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
`
export const TextLink = styled(Link)`
  font-size: ${(props) => props.$fontSize};
  font-weight: ${(props) => props.$fontWeight};
  color: ${(props) => props.$lableColor};
  width: 190px;
  height: 80px;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`
export const FlexContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => props.$flexDirection};
  justify-content: ${(props) => props.$justifyContent};
  align-items: ${(props) => props.$alignItems};
  gap: ${(props) => props.$gap};
  width: ${(props) => props.$width};
  ${(props) =>
    props.$isWidthPercen &&
    `
    @media screen and (max-width: 1920px) {
      width: 16.6666666667%
    }
    @media screen and (max-width: 1684px) {
      width: 20%
    }
    @media screen and (max-width: 1400px) {
      width: 25%
    }
    @media screen and (max-width: 1200px) {
      width: 33.3333333333%
    }
    @media screen and (max-width: 996px) {
      width: 50%
    }
    @media screen and (max-width: 657px) {
      width: 100%
    }
  `}
`
export const TextBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 72px;
  gap: 8px;
`
export const TextBox = styled.div`
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font-size: ${(props) => props.$fontSize};
  font-weight: ${(props) => props.$fontWeight};
  text-align: center;
  color: ${(props) => props.$lableColor};
  border: 1px solid ${(props) => props.$borderColor};
  border-radius: 8px;
  background-color: ${(props) => props.$backgroundColor};
`
export const ButtonEllipsisIcon = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  padding-top: 9px;
  padding-right: 9px;
  cursor: pointer;
  border: none;
  background: none;
`
export const PopupMoreOptionContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  border: 1px solid ${Color.Disabled};
  background: ${Color.Secondary};
  position: absolute;
  top: 30px;
  left: ${(props) => MoreOptionCalculateLeft(props, 5)};
  right: ${(props) => MoreOptionCalculateRight(props, 5)};
  z-index: 1;
  @media screen and (max-width: 1400px) {
    left: ${(props) => MoreOptionCalculateLeft(props, 4)};
    right: ${(props) => MoreOptionCalculateRight(props, 4)};
  }
  @media screen and (max-width: 1200px) {
    left: ${(props) => MoreOptionCalculateLeft(props, 3)};
    right: ${(props) => MoreOptionCalculateRight(props, 3)};
  }
  @media screen and (max-width: 996px) {
    left: ${(props) => MoreOptionCalculateLeft(props, 2)};
    right: ${(props) => MoreOptionCalculateRight(props, 2)};
  }
  @media screen and (max-width: 657px) {
    left: ${(props) => MoreOptionCalculateLeft(props, 1)};
    right: ${(props) => MoreOptionCalculateRight(props, 1)};
  }
`
export const PopupMoreOptionList = styled.button`
  font-size: ${FontFormat.Body_MD.fontSize};
  font-weight: ${FontFormat.Body_MD.fontWeight};
  display: flex;
  width: 100%;
  padding: 10px 12px;
  align-items: center;
  gap: 8px;
  border-radius: ${(props) =>
    props.$position === "top"
      ? "8px 8px 0px 0px"
      : props.$position === "bottom"
      ? "0px 0px 8px 8px"
      : props.$position === "oneOption"
      ? "8px"
      : "0"};
  background: ${Color.Secondary};
  border: none;
  cursor: pointer;
  &: hover {
    background: ${Color.Secondary_Hover};
  }
`
