import styled from "styled-components"
import { Link } from "react-router-dom"
import { Color, FontFormat } from "../../util"

export const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 80px;
  overflow: hidden;
  @media screen and (max-width: 768px) {
    gap: 50px;
  }
  @media screen and (max-width: 480px) {
    gap: 12px;
    padding-left: 16px;
    padding-right: 16px;
  }
`
export const MenuLink = styled(Link)`
  font-size: ${FontFormat.Title_XL.fontSize};
  font-weight: ${FontFormat.Title_XL.fontWeight};
  color: ${Color.Tertiary};
  text-decoration: ${(props) => props.$textDecoration};
  text-decoration-color: ${Color.Primary};
  text-decoration-thickness: 5px;
  &:hover {
    text-decoration: underline;
    text-decoration-color: ${Color.Primary};
    text-decoration-thickness: 5px;
  }
  @media screen and (max-width: 600px) {
    font-size: ${FontFormat.Title_MD.fontSize};
    font-weight: ${FontFormat.Title_MD.fontWeight};
  }
  @media screen and (max-width: 480px) {
    font-size: ${FontFormat.Title_SM.fontSize};
    font-weight: ${FontFormat.Title_SM.fontWeight};
  }
`
