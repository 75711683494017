import React from "react"
import { Button } from "../"
import { Color, FontFormat, WindowSize } from "../../util"
import {
  FlexContainer,
  OverlayContainer,
  PopupContainer,
  PopupTitle,
} from "./styled"
import CircularProgress from "@mui/material/CircularProgress"

export const Popup = ({
  width,
  title,
  isButton,
  isLoading,
  saveLabel,
  onClose,
  onSave,
  children,
}) => {
  const windowSize = WindowSize()

  return (
    <>
      <OverlayContainer $top={0} $left={0} />
      <PopupContainer $padding={"24px"} $gap={"50px"} $width={width}>
        <FlexContainer $flexDirection={"column"} $gap={"20px"}>
          <PopupTitle
            $fontSize={FontFormat.Title_XS.fontSize}
            $fontWeight={FontFormat.Title_XS.fontWeight}
          >
            {title}
          </PopupTitle>
          {children}
        </FlexContainer>
        {isButton && (
          <FlexContainer
            $flexDirection={windowSize.width > 300 ? "row" : "column"}
            $justifyContent={"center"}
            $gap={"18px"}
          >
            {onClose && (
              <Button
                color={Color.Primary}
                background={Color.Secondary}
                isBorder={true}
                borderColor={Color.Primary}
                label="Cancel"
                onClick={onClose}
              />
            )}
            {onSave && (
              <Button
                color={Color.Secondary}
                background={Color.Primary}
                backgroundActive={Color.Primary_Pressed}
                label={
                  isLoading ? (
                    <CircularProgress
                      size={24}
                      style={{
                        color: Color.Secondary,
                        paddingLeft: "10px",
                        paddingRight: "10px",
                      }}
                    />
                  ) : (
                    saveLabel
                  )
                }
                onClick={onSave}
              />
            )}
          </FlexContainer>
        )}
      </PopupContainer>
    </>
  )
}
