import styled from "styled-components"
import { Color, FontFormat } from "../../util"

export const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
`
export const BackgroundBlue = styled.div`
  width: 1400px;
  height: auto;
  padding: 40px 54px;
  margin: 0 90px 97px;
  border-radius: 16px;
  background-color: ${Color.Baby_Blue};
  overflow: hidden;
  @media screen and (max-width: 768px) {
    margin: 0 16px 97px;
    padding: 24px 32px;
   
  }
`
export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`
export const Title = styled.h1`
  font-size: ${FontFormat.Heading_MD.fontSize};
  font-weight: ${FontFormat.Heading_MD.fontWeight};
`
export const TextCount = styled.p`
  font-size: ${FontFormat.Body_MD.fontSize};
  font-weight: ${FontFormat.Body_MD.fontWeight};
`
