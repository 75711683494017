import styled from "styled-components"
import { Color, FontFormat } from "../../util"

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => props.$flexDirection};
  justify-content: ${(props) => props.$justifyContent};
  align-items: ${(props) => props.$alignItems};
  gap: ${(props) => props.$gap};
  width: ${(props) => props.$width};
`
export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
  width: ${(props) => props.$width};
  @media screen and (max-width: 657px) {
    flex-direction: column;
  }
`
export const ProductImage = styled.img`
  width: 203px;
  height: 203px;
  object-fit: cover;
  object-position: center;
  border-radius: 203px;
  @media screen and (max-width: 996px) {
    width: 175px;
    height: 175px;
    border-radius: 175px;
  }
  @media screen and (max-width: 768px) {
    width: 155px;
    height: 155px;
    border-radius: 155px;
  }
  @media screen and (max-width: 657px) {
    width: 223px;
    height: 223px;
    border-radius: 223px;
  }
`
export const Title = styled.h1`
  color: ${Color.Tertiary};
  font-size: ${FontFormat.Heading_MD.fontSize};
  font-weight: ${FontFormat.Heading_MD.fontWeight};
`
export const SupTite = styled.h3`
  color: ${Color.Tertiary};
  font-size: ${FontFormat.Title_MD.fontSize};
  font-weight: ${FontFormat.Title_MD.fontWeight};
`
export const Text = styled.p`
  color: ${Color.Tertiary};
  font-size: ${FontFormat.Body_MD.fontSize};
  font-weight: ${FontFormat.Body_MD.fontWeight};
`
export const SupervisorProfileImage = styled.img`
  width: 36px;
  height: 36px;
  object-fit: cover;
  object-position: center;
  border-radius: 100px;
`
export const Description = styled.p`
  font-size: ${FontFormat.Body_MD.fontSize};
  font-weight: ${FontFormat.Body_MD.fontWeight};
  line-height: 150%;
  max-height: ${(props) => props.$maxHeight};
  overflow: hidden;
  position: relative;
  text-align: justify;
`
export const SeeMoreText = styled.p`
  margin: 0;
  cursor: pointer;
  color: ${Color.Tertiary};
  font-size: ${FontFormat.Title_MD.fontSize};
  font-weight: ${FontFormat.Title_MD.fontWeight};
  &:hover {
    color: ${Color.Tertiary_Hover};
  }
  &:active,
  &:focus {
    color: ${Color.Tertiary_Pressed};
  }
`
