import React, { useState, useEffect, useContext } from "react"
import { firebaseConfig } from "../../services/auth/firebaseConfig"
import { getAuth, sendPasswordResetEmail } from "firebase/auth"
import { EnvelopeIcon } from "../../util/icons/Outline"
import { login, logo_bluezebra_blue } from "../../img"
import { Button, Input } from "../../components"
import { Color, FontFormat, Validator } from "../../util"
import { ShowNavBarContext } from "../../components/Context"
import { FlexContainer, P } from "../../style/main"
import {
  DivFlex,
  Body,
  Image,
  HeadingText,
  DetailText,
  LoingText,
  DivInput,
} from "./styled"
import CircularProgress from "@mui/material/CircularProgress"

export const ForgetPassword = () => {
  const [email, setEmail] = useState("")
  const [isValid, setisValid] = useState(true)
  const [isSend, setIsSend] = useState(false)
  const [loading, setLoading] = useState(false)
  const auth = getAuth(firebaseConfig)
  const { setIsShowNavBar } = useContext(ShowNavBarContext)

  useEffect(() => {
    setIsShowNavBar(false)
  }, [setIsShowNavBar])

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onSendForgetPassword()
    }
  }

  const onSendForgetPassword = async () => {
    let validate = true

    if (email === "") {
      validate = false
    }
    setisValid(validate)
    try {
      if (validate) {
        setLoading(true)
        sendPasswordResetEmail(auth, email)
          .then(() => {
            setIsSend(true)
            setLoading(false)
          })
          .catch(() => setLoading(false))
      }
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <DivFlex>
      <Body $isBackground={true}>
        <Image src={login} alt="client" />
        <div>
          <HeadingText>
            Welcome back to
            <br /> Bluezebra support system!
          </HeadingText>
          <DetailText>
            To keep connected with your client
            <br /> please login with your account
          </DetailText>
        </div>
      </Body>
      <Body>
        <DivInput $alignItems={"center"}>
          <Image src={logo_bluezebra_blue} alt="logo_bluezebra" />
          <LoingText>
            {isSend ? "Please check your email!" : "Forget password"}
          </LoingText>
        </DivInput>
        {isSend ? (
          <FlexContainer
            $flexDirection={"column"}
            $alignItems={"center"}
            $gap={"16px"}
          >
            <EnvelopeIcon width={85} height={85} color={Color.Primary} />
            <P $FontFormat={FontFormat.Title_MD} $textColor={Color.Tertiary}>
              We have sent reset password link to your email.
            </P>
          </FlexContainer>
        ) : (
          <>
            <DivInput $gap={"16px"}>
              <Input
                type="text"
                label={"Email"}
                width={"386px"}
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                isValid={isValid}
                onKeyDown={handleKeyDown}
              />
              {!isValid && email === "" ? (
                <p style={Validator.errorStyle}>
                  Please enter a valid email address.
                </p>
              ) : null}
            </DivInput>
            <Button
              color={Color.Secondary}
              background={Color.Primary}
              label={
                loading ? (
                  <CircularProgress
                    size={24}
                    style={{
                      color: Color.Secondary,
                      paddingLeft: "10px",
                      paddingRight: "10px",
                    }}
                  />
                ) : (
                  "Send"
                )
              }
              onClick={onSendForgetPassword}
            />
          </>
        )}
      </Body>
    </DivFlex>
  )
}
