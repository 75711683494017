import React, { useState } from "react"
import { Input, Select, Button } from "../index"
import { MagnifyingGlassIcon, PlusIcon } from "../../util/icons/Outline"
import { Color, WindowSize } from "../../util"
import { FilterBarContainer, FlexContainer } from "./styled"

export const SearchAndFilter = ({
  isAdmin,
  verifyPermissions,
  option,
  onSearchQueryChange,
  onSelectChange,
  setIsShowPopup,
  otherFunction,
}) => {
  const [searchQuery, setSearchQuery] = useState("")
  const windowSize = WindowSize()

  const onChange = (value) => {
    setSearchQuery(value)
    onSearchQueryChange(value)
  }
  return (
    <FilterBarContainer>
      <Input
        placeholder="Search..."
        icon={<MagnifyingGlassIcon width={24} height={24} />}
        value={searchQuery}
        onChange={(e) => onChange(e.target.value)}
        width={windowSize.width <= 1200 ? "100%" : isAdmin|| verifyPermissions.Add ? "70%" : "75%"}
      />
      <FlexContainer
        $width={windowSize.width <= 1200 ? "100%" : isAdmin|| verifyPermissions.Add ? "30%" : "25%"}
      >
        <Select
          onSelectChange={onSelectChange}
          isShowDefaultOption={true}
          optionsData={option}
          width={windowSize.width <= 1200 && isAdmin ? "50%" : "100%"}
          height={"26px !important"}
          label={"Status"}
        />
        {isAdmin || (verifyPermissions && verifyPermissions?.Add) ? (
          <Button
            color={Color.Secondary}
            background={Color.Primary}
            backgroundActive={Color.Primary_Pressed}
            width={windowSize.width <= 1200 ? "50%" : isAdmin || verifyPermissions.Add ? "50%" : "15%"}
            label={
              <>
                <PlusIcon width={20} height={20} strokeWidth={2.7} /> Add
              </>
            }
            onClick={() => {
              setIsShowPopup(true)
              if (typeof otherFunction === "function") {
                otherFunction()
              }
            }}
          />
        ) : null}
      </FlexContainer>
    </FilterBarContainer>
  )
}
