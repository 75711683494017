export { Clients } from "./clients/Clients"
export { ClientsDetail } from "./clientsDetail/ClientsDetail"
export { Engineers } from "./engineers/Engineers"
export { Error } from "./error/error"
export { Login } from "./login/login"
export { ForgetPassword } from "./login/forgetPassword"
export { ResetPassword } from "./login/resetPassword"
export { Products } from "./products/Products"
export { ProductsDetail } from "./productsDetail/ProductsDetail"
export { Tickets } from "./tickets/Tickets"
export { TicketCreate } from "./tickets/TicketCreate"
export { TicketsDetail } from "./ticketsDetail/TicketsDetail"