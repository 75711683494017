import styled from "styled-components"
import { Color, FontFormat } from "../../util"

export const RadioAndCheckBoxLabel = styled.label`
  display: flex;
  align-items: ${(props) => props.$alignItems ? props.$alignItems : "center"};
  gap: 12px;
  font-size: ${FontFormat.Body_SM.fontSize};
  font-weight: ${FontFormat.Body_SM.fontWeight};
  color: ${Color.Tertiary};
  padding: ${(props) => props.$padding};
`
export const PopupContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  height: auto;
  padding: ${(props) => props.$padding};
  border-radius: 16px;
  background-color: ${Color.Secondary};
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.$gap};
`
export const PopupTitle = styled.p`
  color: #000;
  font-size: ${(props) => props.$fontSize};
  font-weight: ${(props) => props.$fontWeight};
`
export const PopupUserImage = styled.img`
  width: 112px;
  height: 112px;
  border-radius: 8px;
  border: 1px solid ${Color.Disabled};
  object-fit: cover;
  object-position: center;
`
export const PopupTitleActive = styled.p`
  padding: 48px 40px 40px 40px;
  font-size: ${FontFormat.Title_SM.fontSize};
  font-weight: ${FontFormat.Title_SM.fontWeight};
`
export const PopupButton = styled.button`
  display: flex;
  padding: 10px 0px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  color: ${(props) => props.$lableColor};
  border: none;
  border-radius: ${(props) => props.$borderRadius};
  background-color: ${(props) => props.$backgroundColor};
  cursor: pointer;
  &: hover {
    background-color: ${(props) => props.$backgroundColorHover};
  }
`
export const MenuUpdateUserContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  border: 1px solid ${Color.Disabled};
  background: ${Color.Secondary};
  position: absolute;
  top: 30px;
  left: 185px;
  z-index: 1;
`
export const MenuUpdateUserList = styled.button`
  font-size: ${FontFormat.Body_MD.fontSize};
  font-weight: ${FontFormat.Body_MD.fontWeight};
  display: flex;
  width: 108px;
  padding: 10px 12px;
  align-items: center;
  gap: 8px;
  border-radius: ${(props) =>
    props.$position === "top"
      ? "8px 8px 0px 0px"
      : props.$position === "bottom"
      ? "0px 0px 8px 8px"
      : "0"};
  background: ${Color.Secondary};
  border: none;
  cursor: pointer;
  &: hover {
    background: ${Color.Secondary_Hover};
  }
`
