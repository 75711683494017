import styled from "styled-components"
import { Color, FontFormat } from "../../util"

export const P = styled.p`
  font-style: normal;
  line-height: 150%;
  margin: 0;
  color: ${Color.Secondary};
`
export const DivFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 87px;
  background-color: ${Color.Primary};
  height: auto;
  margin-bottom: ${(props) => props.$isMarginBottom ? "unset" : "54px"};
  @media screen and (max-width: 768px) {
    padding: 0px 16px;
  }
`
export const Position = styled(P)`
  font-size: ${FontFormat.Title_XS.fontSize};
  font-weight: ${FontFormat.Title_XS.fontWeight};
  padding-right: 10px;
`
export const DivDataFlex = styled.div`
  display: flex;
  flex-direction: ${(props) => props.$flexDirection};
  align-items: ${(props) => props.$alignItems};
  gap: ${(props) => props.$gap};
`
export const ButtonName = styled.button`
  font-size: ${FontFormat.Label_MD.fontSize};
  font-weight: ${FontFormat.Label_MD.fontWeight};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  text-decoration: none;
  background: none;
  border: none;
  color: ${Color.Secondary};
  &:hover {
    color: ${Color.Tertiary};
    text-decoration: underline;
    text-decoration-color: ${Color.Tertiary};
  }
`
export const UserImage = styled.img`
  width: 56px;
  height: 56px;
  border-radius: 56px;
  background-color: ${Color.Secondary};
  object-fit: cover;
  object-position: center;
  @media screen and (max-width: 310px) {
    width: 35px;
    height: 35px;
  }
`
export const ButtonLogout = styled.button`
  display: flex;
  align-items: center;
  width: 126px;
  padding: 6px 8px;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid ${Color.Disabled};
  background-color: ${Color.Secondary};
  cursor: pointer;
  position: absolute;
  top: ${(props) => props.$top};
  right: ${(props) => props.$right};
  overflow: hidden;
`
export const Logo = styled.img`
  @media screen and (max-width: 657px) {
    width: 160px;
  }
`