import React, { useState, useEffect, useContext } from "react"
import { firebaseConfig } from "../../services/auth/firebaseConfig"
import {
  getAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
} from "firebase/auth"
import {
  DivFlex,
  Body,
  Image,
  HeadingText,
  DetailText,
  LoingText,
  DivInput,
  TextResetPassword,
} from "./styled"
import { login, logo_bluezebra_blue } from "../../img"
import { Button, Input } from "../../components"
import { Color, Validator } from "../../util"
import { useNavigate } from "react-router-dom"
import { ShowNavBarContext } from "../../components/Context"

export const Login = () => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [isValid, setisValid] = useState(true)
  const [isValidEmailAndPassword, setIsValidEmailAndPassword] = useState(true)
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  const auth = getAuth(firebaseConfig)
  const navigate = useNavigate()
  const { setIsShowNavBar } = useContext(ShowNavBarContext)
  const loginWithEmailAndPassword = async () => {
    let validate = true

    if (email === "") {
      validate = false
    } else if (password === "") {
      validate = false
    } else if (!emailRegex.test(email)) {
      validate = false
    }
    setisValid(validate)
    try {
      if (validate) {
        signInWithEmailAndPassword(auth, email, password)
          .then((res) => {
            const accessToken = res.user.accessToken
            localStorage.setItem("accessToken", accessToken)
          })
          .catch((e) => {
            console.log("loginWithEmailAndPassword:", e)
            setisValid(false)
            setIsValidEmailAndPassword(false)
          })
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    const checkAuth = () => {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          navigate("/tickets")
          setIsShowNavBar(true)
        }
      })
    }

    const unsubscribe = checkAuth()
    setIsShowNavBar(false)

    return () => {
      if (unsubscribe) {
        unsubscribe()
      }
    }
  }, [auth, navigate, setIsShowNavBar])

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      loginWithEmailAndPassword()
    }
  }

  return (
    <DivFlex>
      <Body $isBackground={true}>
        <Image src={login} alt="client" />
        <div>
          <HeadingText>
            Welcome back to
            <br /> Bluezebra support system!
          </HeadingText>
          <DetailText>
            To keep connected with your client
            <br /> please login with your account
          </DetailText>
        </div>
      </Body>
      <Body>
        <DivInput $alignItems={"center"}>
          <Image src={logo_bluezebra_blue} alt="logo_bluezebra" />
          <LoingText>Login</LoingText>
        </DivInput>
        <DivInput $gap={"16px"}>
          <Input
            type="text"
            label={"Email"}
            width={"386px"}
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            isValid={isValid}
          />
          {(!isValid && email === "") ||
          (!isValid && !emailRegex.test(email)) ? (
            <p style={Validator.errorStyle}>
              Please enter a valid email address.
            </p>
          ) : null}
          <Input
            type="password"
            label={"Password"}
            width={"386px"}
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            isValid={isValid}
            onKeyDown={handleKeyDown}
          />
          {!isValidEmailAndPassword ? (
            <p style={Validator.errorStyle}>
              Incorrect email or password. Please try again!
            </p>
          ) : !isValid && password === "" ? (
            <p style={Validator.errorStyle}>Please enter a valid password.</p>
          ) : null}
          <div style={{ textAlign: "right" }}>
            <TextResetPassword to="/forgetPassword">Forget password</TextResetPassword>
          </div>
        </DivInput>
        <Button
          color={Color.Secondary}
          background={Color.Primary}
          label="Login"
          onClick={loginWithEmailAndPassword}
        />
      </Body>
    </DivFlex>
  )
}
