import { useState } from "react"
import { firebaseConfig } from "../services/auth/firebaseConfig"
import { getAuth, signOut, onAuthStateChanged } from "firebase/auth"
import { getBluezebraUserByFirebaseId, getPermissionById } from "../api"
import { Enum } from "../util"

const auth = getAuth(firebaseConfig)

export const userInfo = async () => {
  return new Promise((resolve, reject) => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const newToken = await user.getIdToken()
          localStorage.setItem("accessToken", newToken)
          const bluezebraUser = await getBluezebraUserByFirebaseId(user.uid)
          let userInfo = bluezebraUser.length > 0 && bluezebraUser[0]
          if (!userInfo.is_active) {
            signOut(auth)
          }

          if (userInfo.user_permission && userInfo.user_permission.length > 0) {
            const permissions = await getPermissionById(
              userInfo.user_permission
            )
            userInfo = { ...userInfo, user_permission: permissions }
          }
          resolve(userInfo)
        } catch (error) {
          reject(error)
        }
      } else {
        resolve(false)
      }
    })
  })
}

export const useUserPermission = () => {
  const [verifyPermissions, setVerifyPermissions] = useState({
    Users: { Add: false, Edit: false, Inactive: false },
    Products: { Edit: false, Inactive: false },
    Clients: { Add: false, Edit: false, Inactive: false },
  })

  const checkPermission = (user) => {
    if (!user || !user.user_permission) return

    const updatePermissions = { ...verifyPermissions }

    const filterPermissionsByCategory = (category) =>
      user.user_permission.filter((item) => item.category === category)
    const newPermissions = (category, permission) => {
      const categoryPermissions = filterPermissionsByCategory(category)
      categoryPermissions.forEach((item) => {
        if (permission.includes(item.name)) {
          updatePermissions[category][item.name] = true
        }
      })
    }

    const userPermissions = ["Add", "Edit", "Inactive"]
    const productPermissions = ["Edit", "Inactive"]
    const clientPermissions = ["Add", "Edit", "Inactive"]

    newPermissions(Enum.PERMISSION_CATEGORY.USERS, userPermissions)
    newPermissions(Enum.PERMISSION_CATEGORY.PRODUCTS, productPermissions)
    newPermissions(Enum.PERMISSION_CATEGORY.CLIENTS, clientPermissions)

    setVerifyPermissions(updatePermissions)
  }
  return { verifyPermissions, checkPermission }
}
