const SELECT_STATUS = {
  ACTIVE: "Active",
  IN_ACTIVE: "Inactive",
}
const SELECT_TICKET_STATUS = {
  ALL: "All",
  PENDING: "Pending",
  IN_PROGRESS: "In Progress",
  CLOSED: "Closed",
}
const SELECT_SUPERVISOR = {
  ALL: "All",
}
const SELECT_ROLE_TICKET = {
  ALL: "All",
  MAIN: "Main",
  SUPPORTER: "Supporter",
}
const SELECT_ROLE = {
  ENGINEER: "Engineer",
  HEADOF_ENGINEER: "Head of engineer",
}
const PERMISSION_CATEGORY = {
  USERS: "Users",
  PRODUCTS: "Products",
  CLIENTS: "Clients",
}
const ADD_OR_UPDATE = {
  ADD: "Add",
  UPDATE: "Update",
}
const METHOD = {
  GET: "GET",
  POST: "POST",
  PATCH: "PATCH",
  PUT: "PUT",
  DELETE: "DELETE",
}
const CONTENT_TYPE = {
  JSON: "application/json",
  FORM_DATA: "multipart/form-data",
}

export const Enum = {
  SELECT_STATUS,
  SELECT_TICKET_STATUS,
  SELECT_SUPERVISOR,
  SELECT_ROLE,
  SELECT_ROLE_TICKET,
  PERMISSION_CATEGORY,
  ADD_OR_UPDATE,
  METHOD,
  CONTENT_TYPE,
}
