import React from "react"
import {
  EllipsisVerticalIcon,
  ExclamationCircleIcon,
} from "../../util/icons/Outline"
import { Color, FontFormat } from "../../util"
import { P } from "../../style/main"
import {
  FlexContainer,
  CarContainer,
  ImageLink,
  Image,
  Title,
  SupervisorProfileImage,
  ButtonEllipsisIcon,
} from "./styled"
import dayjs from "dayjs"

export const ProductCard = ({
  item,
  index,
  productImage,
  title,
  partner,
  formatDate,
  warrantyDate,
  verifyPermissions,
  supervisors,
  isShowMoreOption,
  cardId,
  onMoreOption,
  PopupMoreOption,
  countProduct,
  notSetWidth,
}) => {
  const WarrantyDate = () => {
    const endWarrantyDate = dayjs(item.end_warranty)
    const currentDate = dayjs()
    if (currentDate.isAfter(endWarrantyDate)) {
      return (
        <P
          $FontFormat={FontFormat.Body_MD}
          $textColor={Color.Error}
          style={{ display: "flex", alignItems: "center", gap: "4px" }}
        >
          <ExclamationCircleIcon width={"24px"} height={"24px"} />
          Warranty date: {formatDate && formatDate(item.end_warranty)}
        </P>
      )
    } else {
      return (
        <P $FontFormat={FontFormat.Body_MD} $textColor={Color.Tertiary}>
          Warranty date: {formatDate && formatDate(item.end_warranty)}
        </P>
      )
    }
  }
  return (
    <FlexContainer
      $justifyContent={"center"}
      $isWidthPercen={true}
      $notSetWidth={notSetWidth}
    >
      <CarContainer>
        <ImageLink to={`/products/detail/${item.product_id}`}>
          <Image src={productImage} alt={title} />
        </ImageLink>
        <FlexContainer $flexDirection={"column"} $gap={"4px"}>
          <Title to={`/products/detail/${item.product_id}`}>{title}</Title>
          {partner && (
            <P $FontFormat={FontFormat.Body_MD} $textColor={Color.Tertiary}>
              Partner: {partner}
            </P>
          )}
          {warrantyDate && <>{WarrantyDate()}</>}
          <FlexContainer
            $alignItems={"center"}
            $gap={"8px"}
            style={{ height: "38px" }}
          >
            <P $FontFormat={FontFormat.Body_MD} $textColor={Color.Tertiary}>
              Supervisors:
            </P>
            <FlexContainer
              $alignItems={"center"}
              style={{ position: "relative" }}
            >
              {supervisors === undefined || supervisors.length === 0
                ? "-"
                : supervisors.map((item, index) => {
                    return (
                      <SupervisorProfileImage
                        key={index}
                        src={item.profile_image}
                        $positionRelative={index !== 0 && "absolute"}
                        $left={22 * (index - 1) + 22}
                        $zIndex={supervisors.length - index}
                        alt={item.name}
                      />
                    )
                  })}
            </FlexContainer>
            {verifyPermissions.Edit || verifyPermissions.Inactive ? (
              <ButtonEllipsisIcon onClick={() => onMoreOption(item.id)}>
                <EllipsisVerticalIcon width={24} height={24} />
              </ButtonEllipsisIcon>
            ) : null}
            {isShowMoreOption && cardId === item.id && (
              <div>{PopupMoreOption(item, index, countProduct)}</div>
            )}
          </FlexContainer>
        </FlexContainer>
      </CarContainer>
    </FlexContainer>
  )
}
