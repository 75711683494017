import React from "react"
import { FontFormat, WindowSize } from "../../util"
import { FlexContainer } from "../../style/main"
import { CarContainer } from "./styled"
import Skeleton from "@mui/material/Skeleton"

export const TicketSkeletonCard = () => {
  const windowSize = WindowSize()

  return (
    <FlexContainer
      $justifyContent={"center"}
      $margin={windowSize.width < 600 ? "0 0 10px 0" : "0 0 8px 0"}
    >
      <CarContainer>
        {windowSize.width <= 1200 ? (
          <>
            <FlexContainer
              $alignItems={"center"}
              $gap={"6px"}
              $justifyContent={"space-between"}
            >
              <Skeleton
                variant="text"
                animation="wave"
                sx={{ fontSize: FontFormat.Body_MD.fontSize, width: "75%" }}
              />
              <Skeleton
                variant="text"
                animation="wave"
                sx={{ fontSize: FontFormat.Body_MD.fontSize, width: "15%" }}
              />
            </FlexContainer>
            <Skeleton
              variant="text"
              animation="wave"
              sx={{ fontSize: FontFormat.Body_MD.fontSize, width: "30%" }}
            />
          </>
        ) : (
          <FlexContainer $alignItems={"center"} $gap={"12px"}>
            <FlexContainer $alignItems={"center"} $gap={"12px"} $width={"85%"}>
              <Skeleton
                variant="text"
                animation="wave"
                sx={{ fontSize: FontFormat.Body_MD.fontSize, width: "75%" }}
              />
              <Skeleton
                variant="text"
                animation="wave"
                sx={{ fontSize: FontFormat.Body_MD.fontSize, width: "10%" }}
              />
            </FlexContainer>
            <Skeleton
              variant="text"
              animation="wave"
              sx={{ fontSize: FontFormat.Body_MD.fontSize, width: "15%" }}
            />
          </FlexContainer>
        )}
        <FlexContainer
          $flexDirection={windowSize.width <= 1200 && "column"}
          $alignItems={windowSize.width <= 1200 ? "flex-start" : "center"}
          $gap={windowSize.width <= 1200 ? "2px" : "12px"}
        >
          {windowSize.width <= 1200 ? (
            <FlexContainer
              $flexDirection={"column"}
              $alignItems={"flex-start"}
              $gap={"12px"}
              $width={"85%"}
            >
              <FlexContainer
                $alignItems={"center"}
                $gap={"12px"}
                $width={"50%"}
              >
                <Skeleton
                  variant="text"
                  animation="wave"
                  sx={{ fontSize: FontFormat.Body_MD.fontSize, width: "30%" }}
                />
                <Skeleton
                  variant="circular"
                  width={"36px"}
                  height={"36px"}
                  animation="wave"
                />
                   <Skeleton
                  variant="text"
                  animation="wave"
                  sx={{ fontSize: FontFormat.Body_MD.fontSize, width: "35%" }}
                />
                <Skeleton
                  variant="circular"
                  width={"36px"}
                  height={"36px"}
                  animation="wave"
                />
              </FlexContainer>
            </FlexContainer>
          ) : (
            <FlexContainer $alignItems={"center"} $gap={"12px"} $width={"85%"}>
              <Skeleton
                variant="text"
                animation="wave"
                sx={{ fontSize: FontFormat.Body_MD.fontSize, width: "15%" }}
              />
              <Skeleton
                variant="circular"
                width={"36px"}
                height={"36px"}
                animation="wave"
              />
              <Skeleton
                variant="text"
                animation="wave"
                sx={{ fontSize: FontFormat.Body_MD.fontSize, width: "15%" }}
              />
              <Skeleton
                variant="circular"
                width={"36px"}
                height={"36px"}
                animation="wave"
              />
            </FlexContainer>
          )}
          <Skeleton
            variant="text"
            animation="wave"
            sx={{
              fontSize: FontFormat.Body_MD.fontSize,
              width: windowSize.width <= 1200 ? "45%" : "15%",
            }}
          />
        </FlexContainer>
      </CarContainer>
    </FlexContainer>
  )
}
