import React, { useRef, useState, useEffect } from "react"
import { v4 as uuidv4 } from "uuid"
import {
  BackgroundBlueContainer,
  Input,
  Button,
  Card,
  SkeletonCard,
  Popup,
  Select,
  Menu,
  SearchAndFilter,
  Pagination,
} from "../../components"
import { Color, Enum, FontFormat, Validator, WindowSize } from "../../util"
import {
  CloudArrowUpIcon,
  PencilIcon,
  MagnifyingGlassIcon,
} from "../../util/icons/Outline"
import { SolidXCircleIcon } from "../../util/icons/Solid"
import {
  FlexContainer,
  OverlayContainer,
  P,
  Span,
  H1,
  Radio,
  Checkbox,
} from "../../style/main"
import {
  RadioAndCheckBoxLabel,
  PopupContainer,
  PopupTitle,
  PopupTitleActive,
  PopupUserImage,
  PopupButton,
} from "./styled"
import {
  getBluezebraUserId,
  getDefaultImageBluezebraUser,
  createBluezebraUser,
  searchBluezebraUser,
  updateBluezebraUserById,
  updateIsActiveBluezebraUserById,
  getPermission,
} from "../../api"
import { userInfo, useUserPermission } from "../../auth"

export const Engineers = () => {
  const [fetchedUser, setFetchedUser] = useState([])
  const [users, setUsers] = useState([])
  const [userId, setUserId] = useState(null)
  const [profileImage, setProfileImage] = useState("")
  const [PreviewProfileImage, setPreviewProfileImage] = useState("")
  const [image, setImage] = useState({})
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [isAdmin, setIsAdmin] = useState(false)
  const [role, setRole] = useState("")
  const [idFirebase, setIdFirebase] = useState("")
  const [searchQuery, setSearchQuery] = useState("")
  const [isShowPopupAddOrUpdateUser, setIsShowPopupAddOrUpdateUser] =
    useState(false)
  const [isShowMoreOption, setIShowsMoreOption] = useState(false)
  const [isShowPopupIsActive, setIsShowPopupIsActive] = useState(false)
  const [checkAddOrUpdateUser, setCheckAddOrUpdateUser] = useState(
    Enum.ADD_OR_UPDATE.ADD
  )
  const [cardItem, setCardItem] = useState({
    id: "",
    isActive: true,
  })
  const [permission, setPermission] = useState([])
  const [checkedPermissions, setCheckedPermissions] = useState([])
  const [selectedStatus, setSelectedStatus] = useState(
    Enum.SELECT_STATUS.ACTIVE
  )
  const [isValid, setIsValid] = useState(true)
  const [isSave, setIsSave] = useState(false)
  const [isValidEmail, setIsValidEmail] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const { verifyPermissions, checkPermission } = useUserPermission()
  const fileInputRef = useRef()
  const uploadRef = useRef(null)
  const selectRef = useRef(null)
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  const windowSize = WindowSize()
  const limit = 15

  const statusOption = [
    { value: 1, label: Enum.SELECT_STATUS.ACTIVE },
    { value: 2, label: Enum.SELECT_STATUS.IN_ACTIVE },
  ]
  const roleOption = [
    { value: 1, label: Enum.SELECT_ROLE.ENGINEER },
    { value: 2, label: Enum.SELECT_ROLE.HEADOF_ENGINEER },
  ]
  const moreOptionLists = [{ id: 1, label: "Edit", icon: PencilIcon }]
  const scrollToUploadImage = () => {
    if (uploadRef.current !== null) {
      uploadRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      })
      uploadRef.current.focus()
    }
  }
  const scrollToSelectRole = () => {
    if (selectRef.current !== null) {
      selectRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      })
      selectRef.current.focus()
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        if (isUpdate) {
          setIsUpdate(false)
        }
        const user = await userInfo()
        setFetchedUser(user)
        checkPermission(user)
        setLoading(false)
      } catch (e) {
        console.error(e)
        setLoading(false)
      }
    }

    fetchData()
  }, [isUpdate])
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isSave) {
          setIsSave(false)
        }
        const [defaultImage, searchResponse] = await Promise.all([
          getDefaultImageBluezebraUser(),
          searchBluezebraUser(searchQuery, selectedStatus, page, limit)
        ])

        setPreviewProfileImage(defaultImage.image)
        setUsers(searchResponse.data)
        setTotal(searchResponse.total)

        if (searchResponse.total < limit) {
          setPage(1)
        } else {
          let totalPages = Math.ceil(searchResponse.total / limit)
          if (page > totalPages) {
            setPage(totalPages)
          } else {
            setPage(page)
          }
        }
      } catch (e) {
        console.error(e)
      }
    }
    fetchData()
  }, [selectedStatus, searchQuery, isSave, page, limit])
  useEffect(() => {
    if (
      !isValid &&
      image.name !== undefined &&
      name !== "" &&
      email !== "" &&
      role === ""
    ) {
      scrollToSelectRole()
    } else if (
      !isValid &&
      image.name === undefined &&
      name !== "" &&
      email !== "" &&
      role !== ""
    ) {
      scrollToUploadImage()
    }
  }, [isValid, profileImage, image, name, email, role])
  useEffect(() => {
    const fetchPermissionData = async () => {
      try {
        const permissions = await getPermission()
        setPermission(permissions)
      } catch (e) {
        console.error(e)
      }
    }

    fetchPermissionData()
  }, [])
  const onPageChange = (e, newPage) => {
    setPage(newPage)
  }
  const onSearchQueryChange = (value) => {
    setSearchQuery(value)
  }
  const onSelectChange = (value) => {
    const status = statusOption.find((option) => option.value === value)
    setSelectedStatus(status.label)
  }
  const onSelectRoleChange = (value) => {
    const role = roleOption.find((option) => option.value === value)
    setRole(role.label)
  }
  const onCheckPermissionChange = (e, id) => {
    const isChecked = e.target.checked
    if (isChecked) {
      setCheckedPermissions((prevPermissions) => [...prevPermissions, id])
    } else {
      setCheckedPermissions((prevPermissions) =>
        prevPermissions.filter((item) => item !== id)
      )
    }
  }
  const onAllCheckPermissionChange = (e, category) => {
    const isChecked = e.target.checked
    const permissionList = permission
      .filter((item) => item.category === category)
      .map((item) => item.id)

    const updatedPermissionsId = isChecked
      ? [...checkedPermissions, ...permissionList]
      : checkedPermissions.filter((id) => !permissionList.includes(id))

    setCheckedPermissions(updatedPermissionsId)
  }
  const onCheckPermissionAdmin = () => {
    const permissionList = permission.map((item) => item.id)
    setCheckedPermissions(permissionList)
  }
  const onMoreOption = (id) => {
    setIShowsMoreOption(cardItem.id !== id || !isShowMoreOption)
    setCardItem({ id: id })
  }
  const onClearForm = () => {
    setCheckAddOrUpdateUser(Enum.ADD_OR_UPDATE.ADD)
    setIsShowPopupAddOrUpdateUser(false)
    setIsValid(true)
    setProfileImage("")
    setPreviewProfileImage("")
    setImage({})
    setName("")
    setEmail("")
    setIsAdmin(false)
    setRole("")
    setIdFirebase("")
    setCheckedPermissions([])
    setIsValidEmail(false)
  }
  const onAddUser = async () => {
    const password = uuidv4()
    let validate = true
    if (
      PreviewProfileImage === "" ||
      name === "" ||
      email === "" ||
      role === ""
    ) {
      validate = false
    } else if (!emailRegex.test(email)) {
      validate = false
    }

    setIsValid(validate)

    if (validate) {
      try {
        setLoading(true)
        let data = {
          profileImage: profileImage,
          name: name,
          email: email,
          password: password,
          isAdmin: isAdmin,
          role: role,
          permission: checkedPermissions,
        }
        const formdata = new FormData()
        formdata.append("data", JSON.stringify(data))
        formdata.append("image", image)
        await createBluezebraUser(formdata)
        setIsSave(true)
        onClearForm()
      } catch (e) {
        console.log(e)
        if (e.response.data === "email-already") {
          setIsValidEmail(true)
        } else {
          console.log('error: ', e);
        }
        setLoading(false)
      } finally {
        setLoading(false);
      }
    }
  }
  const onUpdateUser = () => {
    let validate = true

    if (name === "" || email === "" || role === "") {
      validate = false
    } else if (!emailRegex.test(email)) {
      validate = false
    } else if (profileImage === "" && image.name === undefined) {
      validate = false
    }

    let data = {
      profileImage: profileImage,
      name: name,
      email: email,
      isAdmin: isAdmin,
      role: role,
      id_firebase: idFirebase,
      permission: checkedPermissions,
    }

    const formdata = new FormData()
    formdata.append("data", JSON.stringify(data))
    formdata.append("image", image)

    setIsValid(validate)
    if (validate) {
      try {
        setLoading(true)
        updateBluezebraUserById(userId, formdata)
          .then(() => {
            setLoading(false)
            setIsSave(true)
            setIsUpdate(true)
            onClearForm()
          })
          .catch((e) => {
            console.log(e)
            setLoading(false)
          })
      } catch (e) {
        console.log(e)
        setLoading(false)
        setIsValidEmail(false)
      }
    }
  }
  const onUpdateIsActive = () => {
    const { id, isActive } = cardItem
    const Active = { is_active: !isActive }
    try {
      updateIsActiveBluezebraUserById(id, Active)
        .catch((e) => console.log(e))
        .then(() => {
          setIsShowPopupIsActive(false)
          setIsSave(true)
        })
    } catch (e) {
      console.log(e)
    }
  }
  const openPopupUpdate = (userId) => {
    getBluezebraUserId(userId).then((res) => {
      const result = res[0]
      setUserId(userId)
      setProfileImage(result.profile_image)
      setName(result.name)
      setEmail(result.email)
      setIsAdmin(result.is_admin)
      setRole(result.role)
      setIdFirebase(result.id_firebase)
      setCheckedPermissions(result.permissionId)
      if (result.is_admin) {
        onCheckPermissionAdmin()
      }
      setCheckAddOrUpdateUser(Enum.ADD_OR_UPDATE.UPDATE)
      setIShowsMoreOption(false)
      setIsShowPopupAddOrUpdateUser(true)
    })
  }
  const handleButtonClickUploadImage = () => {
    fileInputRef.current.click()
  }
  const handleUploadeImage = (e) => {
    const selectedFile = e.target.files[0]
    if (selectedFile) {
      setImage(selectedFile)
      const reader = new FileReader()

      reader.onloadend = () => {
        setPreviewProfileImage(reader.result)
      }

      reader.readAsDataURL(selectedFile)
    }
  }
  const onDeleteProfileImage = () => {
    setImage({})
    setProfileImage("")
    setPreviewProfileImage("")
  }
  const PopupAddOrUpdateUser = () => {
    return (
      <Popup
        title={
          checkAddOrUpdateUser === Enum.ADD_OR_UPDATE.UPDATE
            ? "Edit engineer’s profile"
            : "Add new engineer’s profile"
        }
        isButton={true}
        isLoading={loading}
        saveLabel={"Save"}
        onClose={() => {
          onClearForm()
          setCheckAddOrUpdateUser(Enum.ADD_OR_UPDATE.ADD)
        }}
        onSave={
          checkAddOrUpdateUser === Enum.ADD_OR_UPDATE.UPDATE
            ? onUpdateUser
            : onAddUser
        }
      >
        <FlexContainer
          $flexDirection={"column"}
          $gap={"20px"}
          $height={windowSize.width < 600 ? "400px" : "600px"}
          $overflowX={"hidden"}
          $overflowY={"scroll"}
        >
          <div>
            <PopupTitle
              $fontSize={FontFormat.Title_MD.fontSize}
              $fontWeight={FontFormat.Title_MD.fontWeight}
              style={{ marginBottom: "4px" }}
            >
              Image
            </PopupTitle>
            {profileImage || PreviewProfileImage ? (
              <div style={{ position: "relative" }}>
                <SolidXCircleIcon
                  width={16}
                  height={16}
                  onClick={onDeleteProfileImage}
                  style={{
                    position: "absolute",
                    top: "5px",
                    left: "93px",
                    cursor: "pointer",
                  }}
                />
                <PopupUserImage
                  src={profileImage !== "" ? profileImage : PreviewProfileImage}
                  alt={name}
                />
              </div>
            ) : (
              <>
                <Button
                  ref={uploadRef}
                  width={windowSize.width < 480 && "100%"}
                  color={Color.Secondary}
                  background={Color.Primary}
                  backgroundActive={Color.Primary_Pressed}
                  label={
                    <>
                      <CloudArrowUpIcon
                        width={20}
                        height={20}
                        strokeWidth={1.7}
                      />{" "}
                      Upload Image
                    </>
                  }
                  isValid={isValid}
                  onClick={handleButtonClickUploadImage}
                />
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleUploadeImage}
                />
              </>
            )}
          </div>
          <Input
            width={windowSize.width > 600 ? "386px" : "100%"}
            type="text"
            label={"Name"}
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            isValid={isValid}
          />
          {!isValid && name === "" ? (
            <p style={Validator.errorStyle}>Please complete this field.</p>
          ) : null}
          <Input
            width={windowSize.width > 600 ? "386px" : "100%"}
            type="text"
            label={"E-mail"}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            isValid={isValid}
            isValidEmail={isValidEmail}
            disabled={
              checkAddOrUpdateUser === Enum.ADD_OR_UPDATE.UPDATE && true
            }
          />
          {!isValid && email === "" ? (
            <p style={Validator.errorStyle}>Please complete this field.</p>
          ) : isValidEmail ? (
            <p style={Validator.errorStyle}>Email is Already.</p>
          ) : null}
          <FlexContainer $flexDirection={"column"} $gap={"8px"}>
            <PopupTitle
              $fontSize={FontFormat.Title_MD.fontSize}
              $fontWeight={FontFormat.Title_MD.fontWeight}
            >
              Action permission
            </PopupTitle>
            <FlexContainer $gap={"24px"}>
              <RadioAndCheckBoxLabel>
                <Radio
                  type="radio"
                  value="yes"
                  checked={isAdmin}
                  onChange={(e) => {
                    setIsAdmin(e.target.value === "yes")
                    onCheckPermissionAdmin()
                  }}
                />
                Admin
              </RadioAndCheckBoxLabel>
              <RadioAndCheckBoxLabel>
                <Radio
                  type="radio"
                  value="no"
                  checked={!isAdmin}
                  onChange={(e) => {
                    setIsAdmin(e.target.value === "yes")
                    setCheckedPermissions([])
                  }}
                />
                Customize
              </RadioAndCheckBoxLabel>
            </FlexContainer>
          </FlexContainer>
          <FlexContainer
            $flexDirection={"column"}
            $gap={"8px"}
            $padding={"0px 24px"}
          >
            <PopupTitle
              $fontSize={FontFormat.Title_MD.fontSize}
              $fontWeight={FontFormat.Title_MD.fontWeight}
            >
              Action list
            </PopupTitle>
            <RadioAndCheckBoxLabel>
              <Checkbox
                type="checkbox"
                checked={
                  permission &&
                  permission.filter(
                    (item) => item.category === Enum.PERMISSION_CATEGORY.USERS
                  ).length ===
                  checkedPermissions.filter(
                    (id) =>
                      permission.find((item) => item.id === id)?.category ===
                      Enum.PERMISSION_CATEGORY.USERS
                  ).length
                }
                onChange={(e) => {
                  onAllCheckPermissionChange(e, Enum.PERMISSION_CATEGORY.USERS)
                }}
                disabled={isAdmin}
              />
              <Span>Users</Span>
            </RadioAndCheckBoxLabel>
            {permission &&
              permission
                .filter(
                  (item) => item.category === Enum.PERMISSION_CATEGORY.USERS
                )
                .map((item, index) => (
                  <FlexContainer
                    key={index}
                    $flexDirection={"column"}
                    $gap={"8px"}
                  >
                    <RadioAndCheckBoxLabel $padding={"0px 0px 0px 32px"}>
                      <Checkbox
                        type="checkbox"
                        id={item.id}
                        value={item.id}
                        checked={checkedPermissions.includes(item.id)}
                        onChange={(e) => onCheckPermissionChange(e, item.id)}
                        disabled={isAdmin}
                      />
                      <Span>{item.displayname}</Span>
                    </RadioAndCheckBoxLabel>
                  </FlexContainer>
                ))}
            <RadioAndCheckBoxLabel>
              <Checkbox
                type="checkbox"
                checked={
                  permission &&
                  permission.filter(
                    (item) =>
                      item.category === Enum.PERMISSION_CATEGORY.PRODUCTS
                  ).length ===
                  checkedPermissions.filter(
                    (id) =>
                      permission.find((item) => item.id === id)?.category ===
                      Enum.PERMISSION_CATEGORY.PRODUCTS
                  ).length
                }
                onChange={(e) => {
                  onAllCheckPermissionChange(
                    e,
                    Enum.PERMISSION_CATEGORY.PRODUCTS
                  )
                }}
                disabled={isAdmin}
              />
              <Span>Products</Span>
            </RadioAndCheckBoxLabel>
            {permission &&
              permission
                .filter(
                  (item) => item.category === Enum.PERMISSION_CATEGORY.PRODUCTS
                )
                .map((item, index) => (
                  <FlexContainer
                    key={index}
                    $flexDirection={"column"}
                    $gap={"8px"}
                  >
                    <RadioAndCheckBoxLabel $padding={"0px 0px 0px 32px"}>
                      <Checkbox
                        type="checkbox"
                        id={item.id}
                        value={item.id}
                        checked={checkedPermissions.includes(item.id)}
                        onChange={(e) => onCheckPermissionChange(e, item.id)}
                        disabled={isAdmin}
                      />
                      <Span $width={"250px"}>{item.displayname}</Span>
                    </RadioAndCheckBoxLabel>
                  </FlexContainer>
                ))}
            <RadioAndCheckBoxLabel>
              <Checkbox
                type="checkbox"
                checked={
                  permission &&
                  permission.filter(
                    (item) => item.category === Enum.PERMISSION_CATEGORY.CLIENTS
                  ).length ===
                  checkedPermissions.filter(
                    (id) =>
                      permission.find((item) => item.id === id)?.category ===
                      Enum.PERMISSION_CATEGORY.CLIENTS
                  ).length
                }
                onChange={(e) => {
                  onAllCheckPermissionChange(
                    e,
                    Enum.PERMISSION_CATEGORY.CLIENTS
                  )
                }}
                disabled={isAdmin}
              />
              <Span>Clients</Span>
            </RadioAndCheckBoxLabel>
            {permission &&
              permission
                .filter(
                  (item) => item.category === Enum.PERMISSION_CATEGORY.CLIENTS
                )
                .map((item, index) => (
                  <FlexContainer
                    key={index}
                    $flexDirection={"column"}
                    $gap={"8px"}
                  >
                    <RadioAndCheckBoxLabel $padding={"0px 0px 0px 32px"}>
                      <Checkbox
                        type="checkbox"
                        id={item.id}
                        value={item.id}
                        checked={checkedPermissions.includes(item.id)}
                        onChange={(e) => onCheckPermissionChange(e, item.id)}
                        disabled={isAdmin}
                      />

                      <Span>{item.displayname}</Span>
                    </RadioAndCheckBoxLabel>
                  </FlexContainer>
                ))}
          </FlexContainer>
          <Select
            ref={selectRef}
            onSelectChange={onSelectRoleChange}
            optionsData={roleOption}
            value={role}
            width={"100%"}
            label={"Role"}
            isValid={isValid}
          />
          {!isValid && role === "" ? (
            <p style={Validator.errorStyle}>Please complete this field.</p>
          ) : null}
        </FlexContainer>
      </Popup>
    )
  }
  const PopupIsActive = () => {
    return (
      <>
        <OverlayContainer $top={0} $left={0} $isBlur={true} />
        <PopupContainer>
          <PopupTitleActive>
            Do you want to “{!cardItem.isActive ? "Active" : "Inactive"}” this
            user?
          </PopupTitleActive>
          <FlexContainer $justifyContent={"center"}>
            <PopupButton
              $lableColor={Color.Primary}
              $borderRadius={"0px 0px 0px 10px"}
              $backgroundColor={Color.Secondary_Hover}
              $backgroundColorHover={Color.Secondary}
              onClick={() => setIsShowPopupIsActive(false)}
            >
              Cancel
            </PopupButton>
            <PopupButton
              $lableColor={Color.Secondary}
              $borderRadius={"0px 0px 10px 0px"}
              $backgroundColor={Color.Primary}
              $backgroundColorHover={Color.Primary_Hover}
              onClick={() => onUpdateIsActive()}
            >
              Confirm
            </PopupButton>
          </FlexContainer>
        </PopupContainer>
      </>
    )
  }

  return (
    <>
      <Menu />
      <BackgroundBlueContainer
        title={"Users"}
        count={users.length}
        textCount={"users"}
      >
        <SearchAndFilter
          isAdmin={fetchedUser.is_admin}
          verifyPermissions={verifyPermissions.Users}
          onSearchQueryChange={onSearchQueryChange}
          onSelectChange={onSelectChange}
          option={statusOption}
          setIsShowPopup={setIsShowPopupAddOrUpdateUser}
        />
        {isShowPopupAddOrUpdateUser && PopupAddOrUpdateUser()}
        {isShowPopupIsActive && PopupIsActive()}
        <FlexContainer
          $flexWrap={"wrap"}
          $justifyContent={windowSize.width <= 768 ? "center" : "flex-start"}
          $margin={"40px 0"}
          $gap={windowSize.width > 1920 && "14px"}
        >
          {loading ? (
            <>
              {Array.from({ length: 18 }).map((_, index) => (
                <SkeletonCard key={index} />
              ))}
            </>
          ) : (
            <>
              {users.length === 0 ? (
                <FlexContainer
                  $alignItems={"center"}
                  $flexDirection={"column"}
                  $width={"100%"}
                  style={{ marginTop: "40px", textAlign: "center" }}
                >
                  <MagnifyingGlassIcon
                    width={64}
                    height={64}
                    style={{ position: "relative", zIndex: 2 }}
                  />
                  <H1 $FontFormat={FontFormat.Title_XL}>No Items Found</H1>
                  <P $FontFormat={FontFormat.Body_MD}>
                    We can’t find any item matching your search.
                  </P>
                </FlexContainer>
              ) : (
                <>
                  {users.map((item, index) => {
                    return (
                      <Card
                        key={index}
                        item={item}
                        index={index}
                        isAdmin={fetchedUser.is_admin}
                        verifyPermissions={verifyPermissions.Users}
                        imageURL={item.profile_image}
                        textBox={item.role}
                        isShowMoreOption={isShowMoreOption}
                        cardItemId={cardItem.id}
                        onMoreOption={onMoreOption}
                        moreOptionLists={moreOptionLists}
                        isActiveOption={true}
                        close={setIShowsMoreOption}
                        setCardItem={setCardItem}
                        setIsShowPopupIsActive={setIsShowPopupIsActive}
                        openPopupUpdate={openPopupUpdate}
                        countItem={users.length}
                      />
                    )
                  })}
                  <Pagination
                    count={total}
                    page={page}
                    limit={limit}
                    onChange={onPageChange}
                  />
                </>
              )}
            </>
          )}
        </FlexContainer>
      </BackgroundBlueContainer>
    </>
  )
}
