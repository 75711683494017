import React, { useRef, useState, useEffect } from "react"
import {
  DivFlex,
  Position,
  DivDataFlex,
  ButtonName,
  UserImage,
  ButtonLogout,
  Logo,
} from "./styled"
import { getAuth, signOut } from "firebase/auth"
import { firebaseConfig } from "../../services/auth/firebaseConfig"
import { logo_bluezebra_white } from "../../img"
import {
  ChevronDownIcon,
  ChevronUpIcon,
  ArrowRightStartOnRectangleIcon,
} from "../../util/icons/Outline"
import { userInfo } from "../../auth"
import { WindowSize } from "../../util"
import { useLocation, Link } from "react-router-dom"
import Skeleton from "@mui/material/Skeleton"

export const NavBar = () => {
  const [isShowLogout, setIsShowLogout] = useState(false)
  const [fetchedUser, setFetchedUser] = useState([])
  const auth = getAuth(firebaseConfig)
  const location = useLocation()
  const pathname = location.pathname
  const popupRef = useRef(null)
  const windowSize = WindowSize()

  const onLogout = () => {
    signOut(auth)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = await userInfo()
        setFetchedUser(user)
      } catch (e) {
        console.error(e)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsShowLogout(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  return (
    <DivFlex $isMarginBottom={pathname.includes("/tickets/detail/")} style={{ overflow: "hidden" }}>
      <Link to={"/tickets"}>
        <Logo src={logo_bluezebra_white} alt="logo_bluezebra" />
      </Link>
      <DivDataFlex $alignItems={"center"} $gap={"16px"}>
        {windowSize.width <= 480 ? (
          <>
            {isShowLogout && (
              <>
                <ButtonLogout ref={popupRef} onClick={onLogout} $top={"73px"} $right={"15px"}>
                  <ArrowRightStartOnRectangleIcon
                    width={24}
                    height={24}
                    strokeWidth={2}
                  />{" "}
                  LOGOUT
                </ButtonLogout>
              </>
            )}
            {fetchedUser.profile_image === undefined ? (
              <Skeleton
                variant="circular"
                width={35}
                height={35}
                animation="wave"
              />
            ) : (
              <>
                <UserImage
                  src={fetchedUser.profile_image}
                  alt={fetchedUser.name}
                />
                <ButtonName onClick={() => setIsShowLogout(!isShowLogout)}>
                  {isShowLogout ? (
                    <ChevronUpIcon width={15} height={15} strokeWidth={3} />
                  ) : (
                    <ChevronDownIcon width={15} height={15} strokeWidth={3} />
                  )}
                </ButtonName>
              </>
            )}
          </>
        ) : (
          <>
            <DivDataFlex $flexDirection={"column"} $alignItems={"flex-end"}>
              {fetchedUser.role === undefined ? (
                <Skeleton
                  variant="text"
                  sx={{ width: "140px" }}
                  animation="wave"
                />
              ) : (
                <Position>
                  {fetchedUser.role}
                  {fetchedUser.is_admin && " | Admin"}
                </Position>
              )}

              {fetchedUser.name === undefined ? (
                <Skeleton
                  variant="text"
                  sx={{ width: "140px" }}
                  animation="wave"
                />
              ) : (
                <ButtonName onClick={() => setIsShowLogout(!isShowLogout)}>
                  {fetchedUser.name}
                  {isShowLogout ? (
                    <ChevronUpIcon width={15} height={15} strokeWidth={3} />
                  ) : (
                    <ChevronDownIcon width={15} height={15} strokeWidth={3} />
                  )}
                </ButtonName>
              )}
              {isShowLogout && (
                <>
                  <ButtonLogout
                    ref={popupRef}
                    onClick={onLogout}
                    $top={"73px"}
                    $right={"43px"}
                  >
                    <ArrowRightStartOnRectangleIcon
                      width={24}
                      height={24}
                      strokeWidth={2}
                    />{" "}
                    LOGOUT
                  </ButtonLogout>
                </>
              )}
            </DivDataFlex>
            {fetchedUser.profile_image === undefined ? (
              <Skeleton
                variant="circular"
                width={56}
                height={56}
                animation="wave"
              />
            ) : (
              <UserImage
                src={fetchedUser.profile_image}
                alt={fetchedUser.name}
              />
            )}
          </>
        )}
      </DivDataFlex>
    </DivFlex>
  )
}
